import { LightTooltip, useLocales } from "rentzz"
import { RoleName, useUserDataQueryFn } from "../queries/userData"
import { Button } from "@mui/material"
import React from "react"
import ChangePlanTooltipComponent from "./ChangePlanTooltipComponent"

interface Props {
    onClick: () => void
    isDisabled?: boolean
    buttonText: string
    blockedRoles: RoleName[]
}
export default function RoleLockedButton({ onClick, isDisabled, buttonText, blockedRoles }: Props) {
    const { translate } = useLocales()
    const { data: user } = useUserDataQueryFn()

    if (user == null) return null
    return (
        <LightTooltip arrow title={blockedRoles.includes(user?.roles.roleName) ? <ChangePlanTooltipComponent /> : ""}>
            <span>
                <Button
                    disabled={blockedRoles.includes(user?.roles.roleName) || isDisabled}
                    variant='contained'
                    color='primary'
                    onClick={onClick}
                    sx={{ textTransform: "none" }}
                >
                    {translate(buttonText)}
                </Button>
            </span>
        </LightTooltip>
    )
}
