import { Box, Button, Card, CardHeader, Divider, Typography } from "@mui/material"
import { CustomDataGrid, Iconify, Label, LightTooltip, useIsMobile, useLocales } from "rentzz"
import DeleteIcon from "@mui/icons-material/Delete"
import { ConfiguredExpenseProvider, ProviderStatus, ProviderType } from "../../../../redux/slices/AddProperty"
import { useDispatch, useSelector } from "../../../../redux/store"
import { alpha, useTheme } from "@mui/material/styles"
import React, { Fragment, useMemo } from "react"
import { GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid-pro"
import ActionWithTooltip from "../../../../utils/ActionWithTooltip"
import { DESKTOP_ICON_SIZE } from "../../../../utils/helpers"
import { PermissionType, usePermissions } from "../../../../hooks/usePermissions"
import { useConfiguredExpenseProviders } from "../../../../queries"
import InfoIcon from "@mui/icons-material/Info"
import MobileProvidersList from "./MobileProvidersList"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import NoDataPage from "../../../../components/NoDataPage"
import ProviderTableSubheader from "./ProviderTableSubheader"
import { UserFlags } from "../../../../queries/userData"
import { useFeatureIsOn, useFeatureValue } from "@growthbook/growthbook-react"
import { DateTime } from "luxon"
import { getAssigneeLabelColor } from "../../../expenses/utils"
import ProviderSendAutomaticallyForm from "./ProviderSendAutomaticallyForm"
import { getProviderStatusColor } from "../../../nomenclature/providers/utilis"

export default function ProvidersTable() {
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { canWrite, canDelete } = usePermissions(PermissionType.Properties, currentPropertyId)
    const { data: providers, isLoading: areProvidersLoading } = useConfiguredExpenseProviders()
    const configuredExpenseProviderPrice = useFeatureValue(UserFlags.ConfiguredExpenseProviderPrice.toString(), 0)
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel as string)
    const isAutoSendEnabled = useFeatureIsOn(UserFlags.SendAutomaticallyExpenseToProvider as string)
    const customPaidBy = useFeatureValue<string | null>(UserFlags.CustomPaidBy, null)

    const providersColumns: GridColDef<ConfiguredExpenseProvider>[] = useMemo(() => {
        const columns: GridColDef<ConfiguredExpenseProvider>[] = [
            {
                field: "providerName",
                minWidth: 100,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("name"),
                renderCell: (v) => {
                    return (
                        <Box display={"flex"} gap={1}>
                            <Typography variant={"body2"}>{v.value}</Typography>
                        </Box>
                    )
                },
            },
            {
                field: "assignee",
                type: "number",
                minWidth: 120,
                headerAlign: "center",
                align: "center",
                headerName: translate("expense_provider_assignee"),
                renderCell: (v) => {
                    return (
                        <Label color={getAssigneeLabelColor(v.value)} style={{ textTransform: "none" }}>
                            {translate(`expenses.assignee-${v.value as number}${customPaidBy ? "-" + customPaidBy : ""}`)}
                        </Label>
                    )
                },
            },
            {
                field: "lastExpenseCreationDate",
                type: "string",
                minWidth: 150,
                flex: 0.5,
                headerAlign: "center",
                align: "center",
                headerName: translate("lastExpenseDate"),
                filterable: true,
                renderCell: (v) => {
                    return (
                        <Typography variant={"body2"}>
                            {(v.value as DateTime).year === 1
                                ? translate("no_expense_added")
                                : (v.value as DateTime).toLocaleString(DateTime.DATE_SHORT)}
                        </Typography>
                    )
                },
            },
            {
                field: "status",
                minWidth: 100,
                flex: 0.3,
                headerAlign: "center",
                align: "center",
                headerName: translate("status"),
                renderCell: (v) => {
                    const reason = v.api.getRow(v!.id).rejectReason != undefined ? v.api.getRow(v!.id).rejectReason : ""
                    return (
                        <LightTooltip
                            title={v.value === ProviderStatus.Active ? "" : translate(`rejected_provider_tooltip-${v.value}${reason}`)}
                            arrow
                        >
                            <Label color={getProviderStatusColor(v.value)} style={{ textTransform: "none" }}>
                                {translate(`rejected_provider_label-${v.value}`)}
                            </Label>
                        </LightTooltip>
                    )
                },
            },
        ]
        if (isAutoSendEnabled) {
            columns.push({
                field: "sendAutomaticallyToTenant",
                minWidth: 100,
                flex: 0.3,
                headerAlign: "center",
                align: "center",
                headerName: translate("sendAutomaticallyToTenant"),
                renderCell: (v) => {
                    return <ProviderSendAutomaticallyForm providerId={v!.id.toString()} />
                },
            })
        }
        return columns
    }, [translate, isAutoSendEnabled])

    const actionsColumn = useMemo(
        (): GridColDef => ({
            field: "actions",
            resizable: false,
            flex: 0.2,
            minWidth: 100,
            type: "actions",
            headerName: translate("actions"),
            getActions: (params: GridRowParams) => {
                return [
                    ActionWithTooltip({
                        element: (
                            <GridActionsCellItem
                                color={"error"}
                                icon={<DeleteIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                                onClick={() => {
                                    dispatch(setModalOpen(AppModals.DeleteProvider))
                                    dispatch(setEditingItem({ id: params.row.id, name: params.row.providerName, providerType: ProviderType.Expense }))
                                }}
                                label={translate("delete")}
                                sx={{ p: 1 }}
                            />
                        ),
                        tooltipText: translate("delete"),
                        key: "delete",
                        hidden: !canDelete,
                    }),
                    ActionWithTooltip({
                        element: <Iconify icon={"mdi:account-lock"} color={theme.palette.error.main} />,
                        tooltipText: translate("only_read_no_write"),
                        key: "unavailable",
                        hidden: canDelete,
                    }),
                ]
            },
        }),
        [translate, dispatch],
    )

    const infoColumn = useMemo(
        (): GridColDef => ({
            field: "info",
            type: "actions",
            minWidth: 50,
            sortable: false,
            headerName: "",
            getActions: (params: GridRowParams<ConfiguredExpenseProvider>) => [
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"info"}
                            icon={<InfoIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                            }}
                            label={translate("info")}
                            sx={{ p: 1, cursor: "initial" }}
                        />
                    ),
                    tooltipText: (
                        <Box display='flex' flexDirection='column'>
                            {params?.row.fields.map((f) => {
                                if (f.name === "assignee") return
                                return (
                                    <Fragment key={f.name}>
                                        <Typography variant={"body2"} fontWeight='bold'>
                                            {translate(f.label)}
                                        </Typography>
                                        <Typography variant={"body2"} sx={{ maxWidth: 230 }}>
                                            {f.value}
                                        </Typography>
                                    </Fragment>
                                )
                            })}
                        </Box>
                    ),
                    key: "info",
                }),
            ],
        }),
        [translate],
    )

    return (
        <Card>
            <CardHeader
                title={
                    <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                        <Typography variant='subtitle1'>{translate("providers")}</Typography>
                        {!isWhitelabel && (
                            <LightTooltip title={translate("cost_of_adding_a_provider", { cost: configuredExpenseProviderPrice })} arrow>
                                <InfoIcon color='primary' fontSize='small' />
                            </LightTooltip>
                        )}
                    </Box>
                }
                subheader={isWhitelabel ? undefined : <ProviderTableSubheader />}
                sx={{
                    p: 2,
                    color: theme.palette.grey.A700,
                    ".MuiCardHeader-action ": { alignSelf: "center" },
                }}
                action={
                    canWrite && (
                        <Button
                            sx={{
                                display: "flex",
                                gap: 1,
                                borderRadius: 1,
                                justifyContent: "center",
                                ":hover": { borderRadius: 1, backgroundColor: alpha(theme.palette.primary.main, 0.1) },
                            }}
                            variant={"text"}
                            color={"primary"}
                            onClick={() => {
                                dispatch(setEditingItem({ providerType: ProviderType.Expense }))
                                dispatch(setModalOpen(AppModals.AddExpenseProvider))
                            }}
                        >
                            <Iconify icon={"mdi:add"} />
                            <Typography>{translate("add_provider")}</Typography>
                        </Button>
                    )
                }
            />
            <Divider />
            {providers?.length === 0 && isMobile && <NoDataPage icon={""} noDataText={"no_providers"} paddingTop={5} />}

            {isMobile ? (
                <MobileProvidersList />
            ) : (
                <CustomDataGrid
                    sortingMode='server'
                    columns={providersColumns}
                    idKey={"id"}
                    getRowId={(row) => row.id}
                    actionsColumn={[actionsColumn]}
                    rows={providers ?? []}
                    infoColumn={[infoColumn]}
                    gridHeight={300}
                    isLoading={areProvidersLoading}
                    components={{
                        noRowsOverlay: () => <NoDataPage icon={""} noDataText={"no_providers"} paddingTop={10} />,
                    }}
                />
            )}
        </Card>
    )
}
