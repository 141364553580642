import { Box, Container } from "@mui/material"
import { styled } from "@mui/material/styles"
import { LoadingIcon, Page, useLocales } from "rentzz"
import React, { ReactNode } from "react"
import PropertyMetersList from "../sections/propertyDetails/Meters/PropertyMetersList"
import Tenants from "../sections/propertyDetails/Tenants/Tenants"
import Insurance from "../sections/insurance/Insurance"
import Income from "../sections/income/IncomePage"
import Notes from "../sections/propertyDetails/General/details/notes/Notes"
import ExpensesPage from "../sections/expenses/ExpensesPage"
import GeneralPage from "../sections/propertyDetails/General/GeneralPage"
import PropertySections from "../sections/files/PropertySections"
import { useAtomValue } from "jotai"
import { propertyDetailsPage, PropertyPages } from "../utils/atom"
import PropertyDetailsPageTabs from "../sections/propertyDetails/PropertyDetailsPageTabs"
import Revision from "../sections/propertyDetails/revision/Revision"
import Configurations from "../sections/propertyDetails/configurations/Configurations"
import { AccountType, UserFlags, useUserDataQueryFn } from "../queries/userData"
import { useFeatureIsOn } from "@growthbook/growthbook-react"

export const ContentStyle = styled("div")(() => ({
    margin: "auto",
    display: "flex",
    flexDirection: "column",
}))

export interface TabPanelProps {
    children?: ReactNode
    index: number
    value: number
}

export function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    }
}

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box>{children}</Box>}
        </div>
    )
}

const PropertyDetails = () => {
    const value = useAtomValue(propertyDetailsPage)
    const { translate } = useLocales()
    const { data: userData } = useUserDataQueryFn()
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel.toString())

    if (value === undefined) {
        return <LoadingIcon />
    }

    return (
        <Page title={translate("details")}>
            <Container maxWidth={false}>
                <ContentStyle>
                    <Box sx={{ width: "100%" }}>
                        <Box sx={{ borderColor: "divider" }}>
                            <PropertyDetailsPageTabs />
                        </Box>
                        <TabPanel value={value} index={PropertyPages.GENERAL}>
                            <GeneralPage />
                        </TabPanel>
                        <TabPanel value={value} index={PropertyPages.NOTES}>
                            <Notes />
                        </TabPanel>
                        <TabPanel value={value} index={PropertyPages.EXPENSES}>
                            <ExpensesPage />
                        </TabPanel>
                        <TabPanel index={PropertyPages.INCOME} value={value}>
                            <Income />
                        </TabPanel>
                        <TabPanel value={value} index={PropertyPages.METERS}>
                            <PropertyMetersList />
                        </TabPanel>
                        <TabPanel index={PropertyPages.TENANTS} value={value}>
                            <Tenants />
                        </TabPanel>
                        <TabPanel index={PropertyPages.INSURANCE} value={value}>
                            <Insurance />
                        </TabPanel>
                        <TabPanel index={PropertyPages.DOCUMENTS} value={value}>
                            <PropertySections />
                        </TabPanel>
                        <TabPanel index={PropertyPages.REVISION} value={value}>
                            <Revision />
                        </TabPanel>
                        {(!isWhitelabel || (isWhitelabel && userData?.accountType === AccountType.WHITELABEL_ADMIN_USER)) && (
                            <TabPanel index={PropertyPages.CONFIGURATIONS} value={value}>
                                <Configurations />
                            </TabPanel>
                        )}
                    </Box>
                </ContentStyle>
            </Container>
        </Page>
    )
}

export default PropertyDetails
