import { number, object, string } from "yup"
import { DateTime } from "luxon"

export const ExportSchema = () =>
    object().shape({
        from: string()
            .required("date_required")
            .test((date: string | undefined) => {
                if (date == null) return false
                const newDate = DateTime.fromISO(date)
                return newDate.isValid && DateTime.now().startOf("day") >= newDate.startOf("day")
            }),
        to: string()
            .required("date_required")
            .test((date: string | undefined) => {
                if (date == null) return false

                const newDate = DateTime.fromISO(date)
                return newDate.isValid && DateTime.now().startOf("day") >= newDate.startOf("day")
            }),
    })

export const ExportBalanceSchema = () =>
    ExportSchema().shape({
        rentingPeriodId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required(),
    })

export const ExportExpenseSchema = () =>
    ExportSchema().shape({
        labelId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required(),
        propertyId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required("property_required"),
        rentingPeriodId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .test("rentingPeriodRequired", "renting_period_required", function (rentingPeriodId, context) {
                const propertyId = context.parent.propertyId

                if (propertyId !== undefined && propertyId !== -1) {
                    return rentingPeriodId !== undefined && rentingPeriodId !== null
                }

                return true
            }),
    })

export const ExportIncomeSchema = () =>
    ExportSchema().shape({
        propertyId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required("property_required"),
        rentingPeriodId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .test("rentingPeriodRequired", "renting_period_required", function (rentingPeriodId, context) {
                const propertyId = context.parent.propertyId

                if (propertyId !== undefined && propertyId !== -1) {
                    return rentingPeriodId !== undefined && rentingPeriodId !== null
                }

                return true
            }),
        incomeType: number().required(),
    })

export const ExportReadingSchema = () =>
    ExportSchema().shape({
        propertyId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required("property_required"),
        rentingPeriodId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .test("rentingPeriodRequired", "renting_period_required", function (rentingPeriodId, context) {
                const propertyId = context.parent.propertyId

                if (propertyId !== undefined && propertyId !== -1) {
                    return rentingPeriodId !== undefined && rentingPeriodId !== null
                }

                return true
            }),
        meterId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required(),
    })
