import { Box, CircularProgress, Typography } from "@mui/material"
import { AppContext, closeSnackbar, CustomFile, DownloadFileCard, enqueueSnackbar, FormProvider, RHFUpload, useLocales } from "rentzz"
import React, { useCallback, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "../../redux/store"
import { useForm } from "react-hook-form"
import { IncomeStatus, PropertyFileType } from "../../redux/slices/AddProperty"
import { getEntityFilesQuery } from "../../queries/files"
import { deleteEntityFileMutation, updateEntityFileNameMutation, uploadEntityFileMutation } from "../../mutations/entityFile"
import { DateTime } from "luxon"
import { useUserDataQueryFn } from "../../queries/userData"
import Api from "../../api/Api"
import { AppModals, setEditingItem, setModalOpen } from "../../redux/slices/App"
import { downloadFile } from "../../utils/actions"

export interface AddFileToEntityRequest extends FileRequest {
    uploadType: PropertyFileType
    referenceId: string
}

interface FileRequest {
    // TODO: review this???
    files: (CustomFile | undefined)[]
}

export default function EntityFileManagerForm() {
    const dispatch = useDispatch()
    const { context, editingItem } = useSelector((state) => state.appState)
    const getItemId = useCallback(() => {
        if (editingItem?.isFromAnIncome) return editingItem?.expenseId
        if (editingItem?.isFromAnExpense) return context === AppContext.Owner ? editingItem?.incomeId : editingItem?.paymentId
        if (editingItem?.type === PropertyFileType.MeterValue) return editingItem?.readingId
        if (editingItem?.type === PropertyFileType.TaskFile) return editingItem?.taskId
        if (editingItem?.type === PropertyFileType.RevisionObservation) return editingItem?.observationId
        return editingItem?.id
    }, [editingItem])
    const { data: files, isFetching } = getEntityFilesQuery(getItemId(), editingItem?.type)
    const { mutateAsync: changeFileName, isPending: isChangeNameLoading } = updateEntityFileNameMutation()
    const { mutateAsync: deleteFile } = deleteEntityFileMutation()
    const { mutate: uploadFile, isPending: isLoading } = uploadEntityFileMutation()
    const { translate } = useLocales()
    const { data: user } = useUserDataQueryFn()

    const methods = useForm<FileRequest>({
        mode: "onChange",
        defaultValues: {
            files: [],
        },
    })

    const { watch, setValue } = methods

    const handleOnFileNameChange = useCallback(
        async (fileId: string, newFileName: string) => {
            if (files == null) return
            await changeFileName({ fileId, newFileName })
        },
        [files, changeFileName],
    )

    const handleDeleteEntityFile = useCallback(
        async (fileId: string) => {
            if (files == null) return
            await deleteFile({ fileId })
        },
        [deleteFile, files],
    )

    useEffect(() => {
        const subscription = watch((v, { name }) => {
            if (name === "files" && v.files != null) {
                if (getItemId() == null || v.files.length === 0 || v.files.some((f) => f == null)) return

                v.files.forEach((f) => {
                    uploadFile({
                        data: {
                            uploadType: editingItem?.type,
                            referenceId: getItemId().toString(),
                            files: [f],
                        },
                    })
                })
                setValue("files", [])
            }
        })
        return () => subscription.unsubscribe()
    }, [watch, editingItem, getItemId])

    const handleDownload = useCallback(
        async (id: string) => {
            const fileToDownload = files?.find((f) => f.id === id)
            if (fileToDownload == null) return
            const key = enqueueSnackbar(translate("downloading"), { variant: "info", key: DateTime.now().toMillis() })
            const imageExtensions = [
                "apng",
                "avif",
                "gif",
                "jpg",
                "jpeg",
                "jfif",
                "pjpeg",
                "pjp",
                "png",
                "svg",
                "webp",
                "bmp",
                "ico",
                "cur",
                "tif",
                "tiff",
                "heif",
            ]
            try {
                if (fileToDownload.extension?.toLowerCase() === "pdf") {
                    const fileUrl = await Api.downloadMultiFile([fileToDownload.id])
                    const url = window.URL.createObjectURL(new Blob([fileUrl]))
                    if (url) {
                        dispatch(
                            setEditingItem({
                                ...editingItem,
                                url: url,
                                previousModal: AppModals.EntityFileManager,
                            }),
                        )
                        dispatch(setModalOpen(AppModals.PdfViewer))
                    }
                } else if (imageExtensions.find((extension) => extension === fileToDownload.extension?.toLowerCase())) {
                    const fileUrl = await Api.downloadMultiFile([fileToDownload.id])
                    const url = window.URL.createObjectURL(new Blob([fileUrl]))
                    if (url) {
                        dispatch(
                            setEditingItem({
                                ...editingItem,
                                url: url,
                                previousModal: AppModals.EntityFileManager,
                                downloadId: id,
                                downloadName: fileToDownload?.name ?? "unknown",
                                extension: fileToDownload?.extension,
                            }),
                        )
                        dispatch(setModalOpen(AppModals.ImageViewer))
                    }
                } else {
                    await downloadFile({
                        downloadId: id,
                        downloadName: fileToDownload?.name ?? "unknown",
                        extension: fileToDownload?.extension,
                    })
                }
            } finally {
                closeSnackbar(key)
            }
        },
        [files, enqueueSnackbar, closeSnackbar, dispatch, downloadFile],
    )

    const showForm = useMemo(() => {
        if (context === AppContext.Tenant) {
            return (
                editingItem?.type === PropertyFileType.MeterValue ||
                (editingItem?.type === PropertyFileType.Income && editingItem?.status !== IncomeStatus.Accepted) ||
                editingItem?.type === PropertyFileType.TaskFile
            )
        } else {
            return !(!editingItem?.canWrite || editingItem?.isFromAnExpense || editingItem?.isFromAnIncome)
        }
    }, [editingItem, context])

    const isActionHidden = useMemo(() => {
        if (user?.roles == null) return false
        if (editingItem?.canDelete) return false
        if (context === AppContext.Tenant && editingItem?.type === PropertyFileType.TaskFile) return true
        return !editingItem?.canWrite || editingItem?.isFromAnExpense || editingItem?.isFromAnIncome
    }, [context, editingItem])

    return (
        <Box marginY={3}>
            {isFetching ? (
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={200}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box display={"flex"} flexDirection={"column"} gap={2}>
                    <DownloadFileCard
                        files={files}
                        onDownload={handleDownload}
                        onFileNameChange={handleOnFileNameChange}
                        isNameChangeLoading={isChangeNameLoading}
                        onDelete={handleDeleteEntityFile}
                        isActionDisabled={context === AppContext.Tenant}
                        isActionHidden={isActionHidden}
                        isDownloadIcon={false}
                        isEditAction={editingItem?.type !== PropertyFileType.TaskFile && user?.roles != null && editingItem?.canWrite}
                    />

                    {files?.length === 0 && !isLoading && (
                        <Box marginY={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                            <Typography sx={{ color: "text.secondary" }} fontWeight={"bold"} variant={"body2"}>
                                {translate("no_files")}
                            </Typography>
                        </Box>
                    )}
                    {showForm && (
                        <FormProvider methods={methods}>
                            {isLoading ? (
                                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection='column' height={145}>
                                    <CircularProgress />
                                    <Typography variant='subtitle2'>{translate("file_uploading")}</Typography>
                                </Box>
                            ) : (
                                <RHFUpload name={"files"} icon={"mdi:file-document-plus-outline"} multiple thumbnail={false} />
                            )}
                        </FormProvider>
                    )}
                </Box>
            )}
        </Box>
    )
}
