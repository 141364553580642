import { useMutation } from "@tanstack/react-query"
import Api from "../../../api/Api"
import { ComplexQueryIDs, SimpleQueryIDs, useQueryInvalidator } from "../../../hooks/useQueryInvalidator"
import { setErrorMessage } from "../../../redux/slices/App"
import { AxiosError } from "axios"
import { useIsMobile } from "rentzz"
import * as jsonpatch from "fast-json-patch"
import { useDispatch } from "../../../redux/store"
import { AddMeterValueRequest } from "../../../sections/propertyDetails/Meters/MetersHistory/Toolbar"
import { BackendError } from "../../../sections/types/user"

export const addMeterValueMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()

    return useMutation({
        mutationFn: (data: AddMeterValueRequest) => Api.addMeterValue(data),
        onMutate: () => {
            dispatch(setErrorMessage(undefined))
        },
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([
                    ComplexQueryIDs.DashboardTable,
                    ComplexQueryIDs.Meters,
                    ComplexQueryIDs.MetersValuesHistory,
                    ComplexQueryIDs.TenantPropertyFiles,
                    ComplexQueryIDs.OwnerPropertyFiles,
                    SimpleQueryIDs.UserData,
                ])
            } else if (isMobile) {
                console.error(error)
                const errorResponse = error as AxiosError
                const errorMessage = errorResponse?.response?.data as BackendError
                dispatch(setErrorMessage(errorMessage?.Message))
            }
        },
    })
}

export const updateMeterValuesMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ meterId, readingId, operations }: { meterId: number; readingId: number; operations: jsonpatch.Operation[] }) =>
            Api.updateMeterValue({
                meterId,
                readingId,
                operations,
            }),
        onSettled: async (data, error) => {
            if (!error) {
                await invalidateQueries([
                    ComplexQueryIDs.Meters,
                    ComplexQueryIDs.MetersValuesHistory,
                    SimpleQueryIDs.UserData,
                    ComplexQueryIDs.PendingMeterValues,
                    SimpleQueryIDs.UserNotifications,
                ])
            }
        },
    })
}

export const sendMeterValueMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ readingId, autoSend }: { readingId: number; autoSend: boolean }) =>
            Api.sendMeterValue({
                readingId,
                autoSend,
            }),
        onSettled: async (data, error) => {
            if (!error) {
                await invalidateQueries([
                    ComplexQueryIDs.Meters,
                    ComplexQueryIDs.MetersValuesHistory,
                    ComplexQueryIDs.PendingMeterValues,
                    SimpleQueryIDs.UserNotifications,
                ])
            }
        },
    })
}
