import { ComplexQueryIDs, SimpleQueryIDs, useQueryInvalidator } from "../hooks/useQueryInvalidator"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import Api from "../api/Api"
import * as jsonpatch from "fast-json-patch"
import { AddTaskRequest, TenantAddTaskRequest } from "../sections/kanban/AddTaskForm"
import { AddSectionRequest } from "../sections/nomenclature/sections/AddOrEditSectionForm"
import { OwnerAddTaskCommentRequest, TenantAddTaskCommentRequest } from "../sections/kanban/details/TaskDetailsCommentInput"
import { useSelector } from "../redux/store"
import { AppContext, IKanbanTask } from "rentzz"

export const addNewSectionMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: (data: AddSectionRequest) => Api.addNewUserSection({ data }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.UserTaskSections])
            }
        },
    })
}

export const addTaskCommentMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: (data: OwnerAddTaskCommentRequest) => Api.addTaskComment({ data }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.UserTaskSections, ComplexQueryIDs.SectionTasks, ComplexQueryIDs.TaskComments])
            }
        },
    })
}

export const tenantAddTaskCommentMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: (data: TenantAddTaskCommentRequest) => Api.tenantAddTaskComment({ data }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.TenantTasks, ComplexQueryIDs.TenantTaskComments])
            }
        },
    })
}

export const updateUserSectionMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ sectionId, operations }: { sectionId: string; operations: jsonpatch.Operation[] }) =>
            Api.updateUserSections({
                sectionId,
                operations,
            }),
        onSettled: async (data, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.UserTaskSections])
            }
        },
    })
}

export const deleteUserSectionMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: Api.deleteUserSection,
        onSettled: async () => {
            await invalidateQueries([SimpleQueryIDs.UserTaskSections])
        },
    })
}

export const addNewTaskMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()
    const { context } = useSelector((state) => state.appState)

    return useMutation({
        mutationFn: (data: AddTaskRequest | TenantAddTaskRequest) =>
            context === AppContext.Owner ? Api.addNewSectionTask({ data }) : Api.tenantAddNewTask({ data }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.SectionTasks, SimpleQueryIDs.UserTaskSections, ComplexQueryIDs.TenantTasks])
            }
        },
    })
}

export const deleteTaskMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ taskId, sectionId }: { taskId: string; sectionId: string | undefined }) => Api.deleteSectionTask(sectionId, taskId),
        onSettled: async () => {
            await invalidateQueries([SimpleQueryIDs.UserTaskSections, ComplexQueryIDs.SectionTasks])
        },
    })
}

export const updateTaskMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({
            sectionId,
            taskId,
            operations,
        }: {
            sectionId: string | undefined
            taskId: string
            operations: jsonpatch.Operation[]
            newIndex: number
        }) =>
            Api.updateSectionTask({
                sectionId,
                taskId,
                operations,
            }),
        onMutate: async ({ sectionId, taskId, operations, newIndex }) => {
            if (operations.length === 1 && operations[0].path === "/sectionId") {
                const previousOldSectionTasks = queryClient.getQueryData([ComplexQueryIDs.SectionTasks, { sectionId }]) as IKanbanTask[]
                const ourTask = previousOldSectionTasks.find((t) => t.id === taskId)!
                const ourTaskIndex = previousOldSectionTasks.findIndex((t) => t.id === taskId)
                const previousNewSectionTasks = queryClient.getQueryData([
                    ComplexQueryIDs.SectionTasks,
                    // @ts-expect-error we only have value here
                    { sectionId: operations[0].value },
                ]) as IKanbanTask[]

                previousOldSectionTasks.splice(ourTaskIndex, 1)
                previousNewSectionTasks.splice(newIndex, 0, ourTask)

                // @ts-expect-error we only have value here
                if (sectionId !== operations[0].value) {
                    queryClient.setQueryData(
                        // @ts-expect-error we only have value here
                        [ComplexQueryIDs.SectionTasks, { sectionId: operations[0].value }],
                        [...previousNewSectionTasks],
                    )
                    queryClient.setQueryData([ComplexQueryIDs.SectionTasks, { sectionId }], [...previousOldSectionTasks])
                } else {
                    queryClient.setQueryData([ComplexQueryIDs.SectionTasks, { sectionId }], [...previousOldSectionTasks])
                }
            }
        },
        onSettled: async (_, __) => {
            await invalidateQueries([SimpleQueryIDs.UserTaskSections, ComplexQueryIDs.SectionTasks, ComplexQueryIDs.TaskComments])
        },
    })
}

export const deleteTaskCommentMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ taskId, sectionId, commentId }: { taskId: string; sectionId: string | undefined; commentId: string }) =>
            Api.deleteTaskComment(sectionId, taskId, commentId),
        onSettled: async () => {
            await invalidateQueries([SimpleQueryIDs.UserTaskSections, ComplexQueryIDs.SectionTasks, ComplexQueryIDs.TaskComments])
        },
    })
}

export const tenantDeleteTaskMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ taskId, rentingPeriodId }: { taskId: string; rentingPeriodId: number }) => Api.tenantDeleteTask(taskId, rentingPeriodId),
        onSettled: async () => {
            await invalidateQueries([ComplexQueryIDs.TenantTasks])
        },
    })
}

export const updateTenantTaskMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ rentingPeriodId, taskId, operations }: { rentingPeriodId: number; taskId: string; operations: jsonpatch.Operation[] }) =>
            Api.updateTenantTask({
                rentingPeriodId,
                taskId,
                operations,
            }),
        onSettled: async (_, __) => {
            await invalidateQueries([ComplexQueryIDs.TenantTasks])
        },
    })
}

export const seenByTenantMutation = () => {
    return useMutation({
        mutationFn: ({ rentingPeriodId, taskId }: { rentingPeriodId: number; taskId: string }) => Api.seenByTenant(rentingPeriodId, taskId),
    })
}
