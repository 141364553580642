import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query"
import { ComplexQueryIDs, SimpleQueryIDs } from "../hooks/useQueryInvalidator"
import Api from "../api/Api"
import { useSelector } from "../redux/store"
import { AppContext, useIsMobile } from "rentzz"
import { DateTime } from "luxon"
import {
    RentingPeriod,
    RentingPeriodMailNotificationStatus,
    RentingPeriodResponse,
    RentingPeriodStatusCode,
    RentingPeriodUserAcceptanceStatus,
} from "../redux/slices/AddProperty"
import { useUserPropertiesQuery } from "./userData"
import { PermissionType, usePermissions } from "../hooks/usePermissions"
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-pro"
import { sum } from "lodash"

export interface NewInvitedUsers {
    name: string
    mail: string
    date: DateTime
    status: RentingPeriodUserAcceptanceStatus | RentingPeriodMailNotificationStatus
}

export const useRentingPeriodsWithPaginationQueryFn = (
    page: number,
    pageSize: number,
    sortingColumns: GridSortModel,
    filterModel?: GridFilterModel,
) => {
    const { context, currentPropertyId } = useSelector((state) => state.appState)
    const { data: userProperties } = useUserPropertiesQuery()
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.RentingPeriods)

    return useQuery({
        queryKey: [ComplexQueryIDs.TenantsPagination, { id: currentPropertyId, page, pageSize, sortingColumns, filterModel }],
        queryFn: Api.fetchTenantsWithPagination,
        staleTime: Infinity,
        enabled: context === AppContext.Owner,
        placeholderData: () => ({
            items: [],
            count: 20,
        }),
        select: (data) => {
            if (userProperties == null) return { count: 0, items: [] }
            return {
                count: data.count,
                items: data.items.map((rentingPeriod) => ({
                    ...rentingPeriod,
                    canWrite: editablePropertiesIds?.includes(rentingPeriod.propertyId) ?? false,
                    canDelete: deletablePropertiesIds?.includes(rentingPeriod.propertyId) ?? false,
                })),
            }
        },
    })
}

export const useInfiniteRentingPeriodsQueryFn = (sortingModel?: GridSortModel, filterModel?: GridFilterModel) => {
    const { context, currentPropertyId } = useSelector((state) => state.appState)
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.Expenses)

    return useInfiniteQuery({
        initialPageParam: 0,
        queryKey: [ComplexQueryIDs.TenantsPagination, { id: currentPropertyId, sortingModel, filterModel }],
        queryFn: Api.fetchInfiniteRentingPeriods,
        staleTime: Infinity,
        enabled: context === AppContext.Owner,
        getNextPageParam: (_, pages) => {
            const allItems = sum(pages.flatMap((p) => p.items.length))
            if (allItems === pages[0].count) {
                return undefined
            }
            return allItems
        },
        select: (data) => {
            return {
                pages: data.pages.map((e) => {
                    return {
                        count: e.count,
                        items: e.items.map((i) => ({
                            ...i,
                            canWrite: editablePropertiesIds?.includes(i.propertyId) ?? false,
                            canDelete: deletablePropertiesIds?.includes(i.propertyId) ?? false,
                        })),
                    }
                }),
                pageParams: data.pageParams,
            }
        },
    })
}

export const usePropertyAvailability = (startDate?: DateTime, endDate?: DateTime, propertyId?: number) => {
    const { context } = useSelector((state) => state.appState)

    return useQuery({
        queryKey: [ComplexQueryIDs.PropertyAvailability, { id: propertyId, startDate: startDate?.toISODate(), endDate: endDate?.toISODate() }],
        queryFn: Api.checkPropertyAvailability,
        staleTime: Infinity,
        enabled: context === AppContext.Owner && propertyId != null && startDate != null && endDate != null,
    })
}

export const useRentingPeriodDetails = (rentingPeriodId?: number) => {
    const { context } = useSelector((state) => state.appState)
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.RentingPeriods)

    return useQuery({
        queryKey: [ComplexQueryIDs.RentingPeriodDetails, { rentingPeriodId }],
        queryFn: Api.fetchRentingPeriodDetails,
        staleTime: Infinity,
        enabled: context === AppContext.Owner && !!rentingPeriodId,
        select: (data) => {
            const newUsers: NewInvitedUsers[] = []
            const activeUsersEmails = data?.users.map((user) => user.mail)
            const newInvitedTenants = data?.invitedUsers.filter((user) => activeUsersEmails?.every((mail) => mail !== user.mail))

            if (newInvitedTenants && newInvitedTenants.length > 0) {
                newInvitedTenants.forEach((tenant) => {
                    newUsers.push({
                        name: "-",
                        mail: tenant.mail,
                        date: tenant.lastTry,
                        status: tenant.status,
                    })
                })
            }

            return {
                ...data,
                rentingPeriodTenants: [...data.users, ...newUsers],
                canWrite: editablePropertiesIds?.includes(data.propertyId) ?? false,
                canDelete: deletablePropertiesIds?.includes(data.propertyId) ?? false,
            }
        },
    })
}

export const useRentingPeriodSummaryData = (rentingPeriodId: number | undefined) => {
    const { context } = useSelector((state) => state.appState)

    return useQuery({
        queryKey: [SimpleQueryIDs.RentingPeriodSummaryData, { rentingPeriodId }],
        queryFn: Api.fetchRentingPeriodSummaryData,
        staleTime: Infinity,
        enabled: context === AppContext.Owner && !!rentingPeriodId,
    })
}

export const useSingleRentingPeriodQuery = (rentingPeriodId?: number) => {
    const { currentPropertyId, context } = useSelector((state) => state.appState)
    const queryClient = useQueryClient()
    const isMobile = useIsMobile()
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.Expenses)
    return useQuery({
        queryKey: [ComplexQueryIDs.RentingPeriod, { rentingPeriodId }],
        queryFn: async () => {
            let toReturn: (RentingPeriod & { canWrite: boolean; canDelete: boolean }) | undefined | null
            if (isMobile) {
                const rentingPeriods = queryClient.getQueryData([ComplexQueryIDs.TenantsPagination, { id: currentPropertyId }]) as
                    | {
                          pages: RentingPeriodResponse[]
                      }
                    | undefined

                const arrayOfRentingPeriods = rentingPeriods?.pages.flatMap((r) => r.items)
                const rentingPeriod = arrayOfRentingPeriods?.find((r) => r.id == rentingPeriodId)

                if (rentingPeriod) {
                    toReturn = {
                        ...rentingPeriod,
                        canWrite: editablePropertiesIds?.includes(rentingPeriod.propertyId) ?? false,
                        canDelete: deletablePropertiesIds?.includes(rentingPeriod.propertyId) ?? false,
                    }
                } else toReturn = rentingPeriod
            } else {
                const rentingPeriodsPages = queryClient.getQueriesData({
                    queryKey: [ComplexQueryIDs.TenantsPagination, { id: currentPropertyId }],
                })

                const arrayOfRentingPeriods = (
                    rentingPeriodsPages?.flatMap((rentingPeriodWithKey) => rentingPeriodWithKey[1]) as RentingPeriodResponse[]
                )?.flatMap((r) => r?.items)

                const rentingPeriod = arrayOfRentingPeriods.find((r) => r?.id == rentingPeriodId)
                if (rentingPeriod) {
                    toReturn = {
                        ...rentingPeriod,
                        canWrite: editablePropertiesIds?.includes(rentingPeriod.propertyId) ?? false,
                        canDelete: deletablePropertiesIds?.includes(rentingPeriod.propertyId) ?? false,
                    }
                } else toReturn = rentingPeriod
            }
            const rentingPeriod = toReturn ?? (await Api.getRentingPeriod(rentingPeriodId!)) ?? null

            if (rentingPeriod) {
                toReturn = {
                    ...rentingPeriod,
                    canWrite: editablePropertiesIds?.includes(rentingPeriod.propertyId) ?? false,
                    canDelete: deletablePropertiesIds?.includes(rentingPeriod.propertyId) ?? false,
                }
            } else toReturn = null

            return toReturn
        },

        staleTime: Infinity,
        enabled: !!rentingPeriodId && context === AppContext.Owner,
    })
}
export const useSoonToBeExpiredRentingPeriodsQueryFn = () => {
    const { context } = useSelector((state) => state.appState)

    return useQuery({
        queryKey: [SimpleQueryIDs.SoonToBeExpiredTenants],
        queryFn: Api.fetchSoonToBeExpiredTenants,
        staleTime: Infinity,
        enabled: context === AppContext.Owner,
        select: (data) => {
            return data.filter(
                (rentingPeriod) =>
                    rentingPeriod.rentingPeriodStatus !== RentingPeriodStatusCode.Finished &&
                    rentingPeriod.rentingPeriodStatus !== RentingPeriodStatusCode.Declined,
            )
        },
    })
}

export const useRentingPeriodsReportQueryFn = () => {
    const { context, currentPropertyId } = useSelector((state) => state.appState)
    const { data: properties } = useUserPropertiesQuery()

    return useQuery({
        queryKey: [ComplexQueryIDs.RentingPeriodsReport, { propertiesIds: currentPropertyId ? [currentPropertyId] : properties?.map((p) => p.id) }],
        queryFn: Api.fetchRentingPeriodsReport,
        staleTime: Infinity,
        enabled: context === AppContext.Owner,
    })
}

export const usePropertyRentingPeriods = (propertyId?: number) => {
    const { context } = useSelector((state) => state.appState)
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.RentingPeriods)

    return useQuery({
        queryKey: [ComplexQueryIDs.PropertyRentingPeriods, { propertyId: propertyId }],
        queryFn: Api.getRentingPeriodsFromAProperty,
        staleTime: Infinity,
        enabled: context === AppContext.Owner && !!propertyId,
        select: (data) => {
            return data?.map((rentingPeriod) => ({
                ...rentingPeriod,
                canWrite: editablePropertiesIds?.includes(rentingPeriod.propertyId) ?? false,
                canDelete: deletablePropertiesIds?.includes(rentingPeriod.propertyId) ?? false,
            }))
        },
    })
}
