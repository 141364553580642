import React, { useMemo } from "react"
import { TenantPropertyMeter } from "../../../../redux/slices/AddProperty"
import { AppModals, setModalOpen } from "../../../../redux/slices/App"
import { DateTime } from "luxon"
import { renderActionSheetInfo } from "../../utils"
import { arrayOfNumbersToPeriod } from "../../../../utils/dateMagic"
import { useDispatch, useSelector } from "../../../../redux/store"
import { useTheme } from "@mui/material/styles"
import { DetailsSheet, useLocales } from "rentzz"
import { useMetersQueryFn } from "../../../../queries/meters"
import { Box } from "@mui/material"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import { MOBILE_ICON_SIZE } from "../../../../utils/helpers"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import { useTenantRentingPeriodsQuery } from "../../../../queries/userData"

interface TenantMeterDetailsProps {
    onClose: () => void
    getValue: (value: number, unitId: number) => string | undefined
}
export default function TenantMeterDetails({ onClose, getValue }: TenantMeterDetailsProps) {
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const { modalOpen, editingItem, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: meters } = useMetersQueryFn()
    const currentMeter = useMemo(() => (meters as TenantPropertyMeter[])?.find((meter) => meter?.id === editingItem?.id), [meters, editingItem])
    const { data: tenantRentingPeriods } = useTenantRentingPeriodsQuery()

    const options = useMemo((): CustomSheetOption[] => {
        if (currentMeter == null) return []
        return [
            {
                icon: <AddCircleOutlineIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "add_reading",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.AddNewIndex))
                },
                hidden: (tenantRentingPeriods?.find((r) => r.rentingPeriodId === currentRentingPeriodId)?.to ?? DateTime.now()) < DateTime.now(),
            },
            {
                icon: <RemoveRedEyeIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "view_history",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.MeterValuesHistory))
                },
            },
        ]
    }, [currentMeter, dispatch, theme])

    const meterHeaderColor = useMemo(() => {
        return currentMeter?.lastModified?.month === DateTime.now().month ? theme.palette.success.main : theme.palette.warning.main
    }, [currentMeter, theme])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.MeterDetails}
            onClose={onClose}
            isLoading={currentMeter == null}
            title={currentMeter?.name}
            options={options}
            headerColor={meterHeaderColor}
        >
            <Box p={1}>
                {renderActionSheetInfo(
                    "mdi:meter-electric",
                    `${translate("last_index")}: ${currentMeter && getValue(currentMeter.currentValue, currentMeter.unitId)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {/* {renderActionSheetInfo( */}
                {/*     "mdi:calendar-outline", */}
                {/*     `${translate("last_index_date")} ${currentMeter?.lastModified?.toLocaleString(DateTime.DATE_SHORT)}`, */}
                {/*     meterHeaderColor, */}
                {/*     theme.palette.grey["600"], */}
                {/* )} */}
                {renderActionSheetInfo(
                    "mdi:calendar-month",
                    `${translate("reading_period")}: ${currentMeter && arrayOfNumbersToPeriod(currentMeter.reportingPeriod)?.join(", ")}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                    true,
                    "",
                    "info",
                )}
            </Box>
        </DetailsSheet>
    )
}
