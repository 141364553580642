import { array, number, object, string } from "yup"

export const AddGroupSchema = object().shape({
    name: string().required("group_name_required").min(3, "min_3_characters"),
    description: string().required("description_required").min(3, "min_3_characters"),
    properties: array().required().min(1, "property_required"),
    createdAt: string().optional(),
})

export const GroupNameSchema = object().shape({
    name: string().required("group_name_required").min(3, "min_3_characters"),
})

export const GroupDescriptionSchema = object().shape({
    description: string().required("description_required").min(3, "min_3_characters"),
})

export const PropertyPercentageSchema = object().shape({
    percentage: number().required("percentage_required"),
})
