import { Grid } from "@mui/material"
import PropertyDetailsCard from "./PropertyDetailsCard"
import Map from "./Map"
import Features from "./Features"
import { useIsMobile } from "rentzz"
import React from "react"
import PropertyActions from "./PropertyActions"
import { useTheme } from "@mui/material/styles"

const General = () => {
    const theme = useTheme()
    const isMobile = useIsMobile()

    return (
        <Grid container sx={{ color: theme.palette.grey.A700 }}>
            <Grid item sm={12} md={6} xl={6}>
                <PropertyDetailsCard />
            </Grid>
            <Grid item xs={12} md={6} xl={6} paddingLeft={isMobile ? "" : 2} paddingTop={isMobile ? 2 : ""}>
                <PropertyActions />
            </Grid>
            <Grid item xs={12} md={6} xl={6} paddingTop={2}>
                <Features />
            </Grid>
            <Grid item xs={12} md={6} xl={6} paddingLeft={isMobile ? "" : 2} paddingTop={2}>
                <Map />
            </Grid>
        </Grid>
    )
}

export default General
