import { Box, Button, Card, CardHeader, Divider, Typography } from "@mui/material"
import { CustomDataGrid, Iconify, Label, LightTooltip, useIsMobile, useLocales } from "rentzz"
import DeleteIcon from "@mui/icons-material/Delete"
import { Manager, ManagerAcceptanceStatus } from "../../../../../redux/slices/AddProperty"
import { useDispatch, useSelector } from "../../../../../redux/store"
import { alpha, useTheme } from "@mui/material/styles"
import React, { MouseEvent, useCallback, useMemo, useState } from "react"
import { GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid-pro"
import { DateTime } from "luxon"
import { getMailNotificationStatusLabelColor, getUserAcceptanceStatusLabelColor } from "../../../Tenants/utils"
import ActionWithTooltip from "../../../../../utils/ActionWithTooltip"
import { DESKTOP_ICON_SIZE } from "../../../../../utils/helpers"
import { AppModals, setEditingItem, setModalOpen, setShowSubscriptionPage } from "../../../../../redux/slices/App"
import { usePropertyDetailsQueryFn } from "../../../../../queries"
import AddNewInvitation from "./AddNewInvitation"
import MobileManagersList from "./MobileManagersList"
import { ManagerType } from "./AddNewManagerInvitationForm"
import { Replay } from "@mui/icons-material"
import { PermissionType, usePermissions } from "../../../../../hooks/usePermissions"
import { RoleName, useUserDataQueryFn } from "../../../../../queries/userData"

export interface NewInvitationOwnerRequest {
    propertyId: number
    email: string
    managerType: ManagerType
}
export default function ManagersTable() {
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const [open, setOpen] = useState<HTMLElement | null>(null)
    const { data: propertyDetails, isLoading: arePropertyDetailsLoading } = usePropertyDetailsQueryFn()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { canAddManager } = usePermissions(PermissionType.Properties, currentPropertyId)
    const { data: userData } = useUserDataQueryFn()

    const getTooltipDeleteTitle = useCallback(
        (manager: Manager) => {
            if (userData?.email === manager.mail) return "can_not_delete_you"
            if (!manager.canDelete) return "can_not_delete_property_creator"
            return "delete"
        },
        [userData],
    )

    const managersColumns: GridColDef<Manager>[] = useMemo(
        () => [
            {
                field: "mail",
                type: "string",
                minWidth: 120,
                flex: 0.3,
                headerAlign: "center",
                align: "center",
                headerName: translate("email"),
            },
            {
                field: "name",
                minWidth: 100,
                flex: 0.3,
                headerAlign: "center",
                align: "center",
                headerName: translate("name"),
                valueFormatter: (value) => {
                    return value ?? "-"
                },
            },
            {
                field: "date",
                flex: 0.3,
                minWidth: 130,
                headerAlign: "center",
                align: "center",
                filterable: true,
                type: "number",
                headerName: translate("date"),
                valueFormatter: (value) => {
                    return (value as DateTime)?.toLocaleString(DateTime.DATE_SHORT) ?? "-"
                },
            },
            {
                field: "ownershipType",
                flex: 0.3,
                minWidth: 130,
                headerAlign: "center",
                align: "center",
                filterable: true,
                type: "number",
                headerName: translate("access_type"),
                valueFormatter: (value) => {
                    return translate(`managerType-${value}`)
                },
            },
            {
                field: "status",
                minWidth: 100,
                flex: 0.3,
                headerAlign: "center",
                align: "center",
                headerName: translate("status"),
                renderCell: (v) => {
                    const hasName = v.api.getRow(v.id).name !== "-"

                    return (
                        <LightTooltip
                            title={translate(hasName ? `user_acceptance_status_tooltip.${v.value}` : `mail_notification_status_tooltip.${v.value}`)}
                            arrow
                        >
                            <Label
                                color={hasName ? getUserAcceptanceStatusLabelColor(v.value) : getMailNotificationStatusLabelColor(v.value)}
                                style={{ textTransform: "none" }}
                            >
                                {translate(hasName ? `userAcceptanceStatus.${v.value}` : `mail_notification_status.${v.value}`)}
                            </Label>
                        </LightTooltip>
                    )
                },
            },
        ],
        [translate],
    )

    const actionsColumn = useMemo(
        (): GridColDef => ({
            field: "actions",
            resizable: false,
            flex: 0.2,
            minWidth: 100,
            type: "actions",
            headerName: translate("actions"),
            getActions: (params: GridRowParams) => {
                return [
                    ActionWithTooltip({
                        element: (
                            <GridActionsCellItem
                                color={params.row.status === ManagerAcceptanceStatus.ACCEPTED ? "default" : "primary"}
                                icon={<Replay sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                                onClick={() => {
                                    dispatch(setModalOpen(AppModals.ResendManagerInvitation))
                                    dispatch(setEditingItem({ mail: params.row.mail }))
                                }}
                                label={translate(params.row.status === ManagerAcceptanceStatus.ACCEPTED ? "accepted_invitation" : "resend")}
                                sx={{ p: 1 }}
                                disabled={params.row.status === ManagerAcceptanceStatus.ACCEPTED}
                            />
                        ),
                        tooltipText: translate(params.row.status === ManagerAcceptanceStatus.ACCEPTED ? "accepted_invitation" : "resend"),
                        key: "resend",
                        hidden: !canAddManager,
                    }),
                    ActionWithTooltip({
                        element: (
                            <GridActionsCellItem
                                color={"error"}
                                icon={<DeleteIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                                onClick={() => {
                                    dispatch(setModalOpen(AppModals.RemoveManagerInvitation))
                                    dispatch(setEditingItem({ mail: params.row.mail }))
                                }}
                                disabled={!params.row.canDelete}
                                label={translate("delete")}
                                sx={{ p: 1 }}
                            />
                        ),
                        tooltipText: translate(getTooltipDeleteTitle(params.row)),
                        key: "delete",
                        hidden: !canAddManager,
                    }),
                    ActionWithTooltip({
                        element: <Iconify icon={"mdi:account-lock"} color={theme.palette.error.main} />,
                        tooltipText: translate("only_read_no_write"),
                        key: "unavailable",
                        hidden: canAddManager,
                    }),
                ]
            },
        }),
        [translate, dispatch],
    )

    return (
        <Card>
            <CardHeader
                title={translate("manage_access")}
                sx={{ p: 2, color: theme.palette.grey.A700 }}
                action={
                    canAddManager && (
                        <LightTooltip
                            title={
                                userData?.roles.roleName === RoleName.BRONZE || userData?.roles.roleName === RoleName.FREE ? (
                                    <Box>
                                        {translate("upgrade_for_functionality")}
                                        <Button color='primary' onClick={() => dispatch(setShowSubscriptionPage(true))}>
                                            {translate("change_subscription")}
                                        </Button>
                                    </Box>
                                ) : (
                                    ""
                                )
                            }
                            arrow
                        >
                            <span>
                                <Button
                                    disabled={userData?.roles.roleName === RoleName.BRONZE || userData?.roles.roleName === RoleName.FREE}
                                    sx={{
                                        display: "flex",
                                        gap: 1,
                                        borderRadius: 1,
                                        backgroundColor: open ? alpha(theme.palette.primary.main, 0.1) : "",
                                        ":hover": { borderRadius: 1, backgroundColor: alpha(theme.palette.primary.main, 0.1) },
                                    }}
                                    variant={"text"}
                                    color={"primary"}
                                    onClick={(event: MouseEvent<HTMLElement>) => {
                                        if (isMobile) {
                                            dispatch(setModalOpen(AppModals.InviteManager))
                                        } else {
                                            event.stopPropagation()
                                            setOpen(event.currentTarget)
                                        }
                                    }}
                                >
                                    <Iconify icon={"mdi:add"} />
                                    <Typography>{translate("add_manager")}</Typography>
                                </Button>
                            </span>
                        </LightTooltip>
                    )
                }
            />
            <Divider />

            {isMobile ? (
                <MobileManagersList />
            ) : (
                <CustomDataGrid
                    sortingMode='server'
                    columns={managersColumns}
                    idKey={"mail"}
                    getRowId={(row) => row.mail}
                    actionsColumn={[actionsColumn]}
                    rows={propertyDetails?.finalManagers ?? []}
                    infoColumn={[]}
                    gridHeight={300}
                    isLoading={arePropertyDetailsLoading}
                />
            )}
            <AddNewInvitation open={open} setOpen={setOpen} />
        </Card>
    )
}
