import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { Box, Button, Typography } from "@mui/material"
import React, { useCallback } from "react"
import { useDispatch, useSelector } from "../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { removeTenantInvitationMutation } from "../../../mutations/tenants"
import { LoadingButton } from "@mui/lab"
import { useTheme } from "@mui/material/styles"

export default function RemoveInvitation() {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { modalOpen, editingItem, currentRentingPeriodId } = useSelector((state) => state.appState)

    const { mutateAsync: removeInvitation, isPending: isRemoveInvitationLoading } = removeTenantInvitationMutation()

    const onRemoveInvitation = useCallback(async () => {
        if (currentRentingPeriodId == null || editingItem == null) return
        await removeInvitation({ rentingPeriodId: currentRentingPeriodId, email: editingItem?.mail })
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [currentRentingPeriodId, editingItem, removeInvitation, dispatch])

    return (
        <>
            <CustomDialog
                isFullScreen={false}
                title={translate("delete_invitation")}
                onClose={() => dispatch(setModalOpen(undefined))}
                open={!isMobile && modalOpen === AppModals.RemoveInvitation}
                maxWidth='xs'
                onAccept={onRemoveInvitation}
                acceptText={translate("delete")}
                closeText={translate("cancel")}
                closeButtonColor={"primary"}
                variantCloseButton={"text"}
                acceptButtonColor={"error"}
                isDisabled={isRemoveInvitationLoading}
                isLoading={isRemoveInvitationLoading}
            >
                <Box display={"flex"} justifyContent={"center"} paddingY={2}>
                    <Typography>{translate("delete_invitation_content", { email: editingItem?.mail })}</Typography>
                </Box>
            </CustomDialog>

            <DetailsSheet
                isLoading={false}
                title={translate("delete_invitation")}
                isOpen={isMobile && modalOpen === AppModals.RemoveInvitation}
                onClose={() => dispatch(setModalOpen(AppModals.TenantDetails))}
                headerColor={theme.palette.error.main}
            >
                <Box display='flex' height='100%' flexDirection={"column"} justifyContent='space-between'>
                    <Typography padding={1} textAlign={"center"}>
                        {translate("delete_invitation_content", { email: editingItem?.mail })}
                    </Typography>
                    <Box
                        sx={{
                            alignItems: "center",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            padding: 2,
                            gap: 2,
                        }}
                    >
                        <LoadingButton
                            onClick={onRemoveInvitation}
                            variant='contained'
                            color={"error"}
                            fullWidth
                            loading={isRemoveInvitationLoading}
                            disabled={isRemoveInvitationLoading}
                        >
                            {translate("delete")}
                        </LoadingButton>
                        <Button
                            disabled={isRemoveInvitationLoading}
                            onClick={() => dispatch(setModalOpen(AppModals.TenantDetails))}
                            color={"primary"}
                            fullWidth
                        >
                            {translate("cancel")}
                        </Button>
                    </Box>
                </Box>
            </DetailsSheet>
        </>
    )
}
