import { useTheme } from "@mui/material/styles"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../redux/store"
import { useCallback } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { PropertyRevision, RevisionStatus } from "../../../redux/slices/AddProperty"
import * as jsonpatch from "fast-json-patch"
import { updateRevisionMutation } from "../../../mutations/revison"
import { getRevisionByIdQueryFn } from "../../../queries/revision"
import RevisionStatusForm from "../../../sections/propertyDetails/revision/RevisionStatusForm"

export default function RevisionStatusChangeModal() {
    const theme = useTheme()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: updateRevision, isPending: isUpdatingRevision } = updateRevisionMutation()
    const currentRevision = getRevisionByIdQueryFn(editingItem?.id, [{ field: "date", sort: "desc" }])

    const closeModal = useCallback(() => {
        if (isMobile) {
            dispatch(setModalOpen(AppModals.RevisionDetails))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [dispatch, isMobile])

    const handleRevisionStatusChange = useCallback(
        async (data: PropertyRevision) => {
            const operations = jsonpatch.compare({ status: currentRevision?.status }, { status: data.status })
            if (!data.id) return
            await updateRevision({
                propertyId: data.propertyId,
                revisionId: data.id,
                operations,
            })
            closeModal()
        },
        [updateRevision, closeModal],
    )

    const onAccept = useCallback(async () => {
        if (currentRevision == null) return
        await handleRevisionStatusChange({ ...currentRevision, status: RevisionStatus.Done })
    }, [handleRevisionStatusChange, currentRevision, closeModal])

    const onPending = useCallback(async () => {
        if (currentRevision == null) return
        await handleRevisionStatusChange({ ...currentRevision, status: RevisionStatus.Pending })
    }, [handleRevisionStatusChange, currentRevision, closeModal])

    return (
        <>
            <DetailsSheet
                isLoading={false}
                title={translate("revision_status_title")}
                isOpen={isMobile && modalOpen === AppModals.UpdateRevisionStatus}
                onClose={closeModal}
                headerColor={currentRevision?.status === RevisionStatus.Done ? theme.palette.primary.main : theme.palette.warning.main}
            >
                <RevisionStatusForm onAccept={onAccept} onPending={onPending} onClose={closeModal} isLoading={isUpdatingRevision} />
            </DetailsSheet>
            <CustomDialog
                title={translate("revision_status_title")}
                isFullScreen={false}
                maxWidth={"xs"}
                open={!isMobile && modalOpen === AppModals.UpdateRevisionStatus}
                onClose={closeModal}
            >
                <RevisionStatusForm onAccept={onAccept} onPending={onPending} onClose={closeModal} isLoading={isUpdatingRevision} />
            </CustomDialog>
        </>
    )
}
