import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../../redux/store"
import { DetailsSheet, useLocales } from "rentzz"
import { useSingleActiveUserQuery } from "../../../../queries/userData"
import React, { SyntheticEvent, useMemo } from "react"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import { MOBILE_ICON_SIZE } from "../../../../utils/helpers"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { renderActionSheetInfo } from "../../utils"
import { DateTime } from "luxon"
import PhonelinkEraseIcon from "@mui/icons-material/PhonelinkErase"
import PhonelinkRingIcon from "@mui/icons-material/PhonelinkRing"
import StopCircleIcon from "@mui/icons-material/StopCircle"
import LocalPhoneIcon from "@mui/icons-material/LocalPhone"

export default function ActiveUserDetailsSheet() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { data: currentUser } = useSingleActiveUserQuery(editingItem?.id)

    const options = useMemo((): CustomSheetOption[] => {
        if (currentUser == null) return []

        return [
            {
                icon: <LocalPhoneIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "edit_phone",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.EditActiveUserPhone))
                },
                hidden: currentUser.phoneNumber === "" || currentUser.phoneNumber == null,
            },
            {
                icon: currentUser.isSMSCodeActive ? (
                    <PhonelinkEraseIcon color={"error"} sx={{ fontSize: MOBILE_ICON_SIZE }} />
                ) : (
                    <PhonelinkRingIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />
                ),
                title: currentUser.isSMSCodeActive ? "remove_code_by_sms_mobile" : "send_code_by_sms",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.SendLoginCodeBySMS))
                },
            },
            {
                icon: <StopCircleIcon sx={{ fontSize: MOBILE_ICON_SIZE, color: theme.palette.error.main }} />,
                title: "block_user",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.BlockUser))
                },
                hidden: currentUser.isLocked,
            },
        ]
    }, [currentUser, dispatch])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.ActiveUserDetailsSheet}
            onClose={(event: MouseEvent | TouchEvent | PointerEvent | SyntheticEvent<object, Event> | undefined) => {
                event?.stopPropagation()
                dispatch(setEditingItem(undefined))
                dispatch(setModalOpen(undefined))
            }}
            isLoading={currentUser == null}
            title={currentUser?.fullName}
            subtitle={currentUser?.eMail}
            options={options}
        >
            {currentUser?.isLocked &&
                renderActionSheetInfo("mdi:person-block", translate("blocked_user_tooltip"), theme.palette.primary.main, theme.palette.grey["600"])}

            {(currentUser?.phoneNumber !== "" || currentUser?.phoneNumber != null) &&
                renderActionSheetInfo(
                    "mdi:phone",
                    `${translate("phone")}: ${currentUser?.phoneNumber}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
            {renderActionSheetInfo(
                "mdi:calendar-month",
                `${translate("createdAt")}: ${currentUser?.createdAt?.toLocaleString(DateTime.DATE_SHORT)}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
            {renderActionSheetInfo(
                "mdi:calendar-clock",
                `${translate("lastActive")}: ${currentUser?.lastActive?.toLocaleString(DateTime.DATE_SHORT)}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
        </DetailsSheet>
    )
}
