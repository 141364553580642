import React, { useEffect, useState } from "react"
import { Droppable } from "@hello-pangea/dnd"
import { Paper, Stack, Typography } from "@mui/material"
import { TaskSection, useAllSectionsQueryFn, useSectionItemsQueryFn } from "../../../queries/kanban"
import { KanbanTaskCard, useIsMobile } from "rentzz"
import { useTheme } from "@mui/material/styles"
import TaskSkeleton from "../TaskSkeleton"
import { useUserPropertiesQuery } from "../../../queries/userData"
import { useDispatch, useSelector } from "../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"

interface Props {
    section: TaskSection
}

export default function TaskSectionView({ section }: Props) {
    const dispatch = useDispatch()
    const theme = useTheme()
    const { modalOpen } = useSelector((state) => state.appState)
    const { data: allSections } = useAllSectionsQueryFn()
    const { data: sectionTasks, isLoading: areSectionTasksLoading } = useSectionItemsQueryFn(section.taskSectionId)
    const { data: properties } = useUserPropertiesQuery()
    const isMobile = useIsMobile()
    const [distance, setDistance] = useState(0)

    useEffect(() => {
        const elDistanceToTop = window.scrollY + (document.getElementById(section.taskSectionId)?.getBoundingClientRect().top ?? 0)
        setDistance(elDistanceToTop + 10)
    }, [])

    return (
        <Paper
            id={section.taskSectionId}
            variant='outlined'
            sx={{
                maxHeight: isMobile ? undefined : `calc(99vh - ${distance}px)`,
                px: 2,
                paddingBottom: 2,
                borderRadius: 1,
                bgcolor: "background.neutral",
                borderColor: "background.neutral",
                overflowY: isMobile ? undefined : "auto",
                color: theme.palette.grey.A700,
                "&:hover": {
                    boxShadow: `1px 1px 5px ${theme.palette.grey["300"]}`,
                },
            }}
        >
            <Stack spacing={3}>
                <Typography variant={"h5"} color={theme.palette.grey.A700} paddingTop={3}>
                    {section.name}
                </Typography>

                {sectionTasks == null || areSectionTasksLoading ? (
                    <>
                        <TaskSkeleton />
                        <TaskSkeleton />
                        <TaskSkeleton />
                        <TaskSkeleton />
                    </>
                ) : (
                    <Droppable droppableId={section.taskSectionId} type='TASK'>
                        {(dropProvided) => (
                            <Stack
                                ref={dropProvided.innerRef}
                                {...dropProvided.droppableProps}
                                spacing={2}
                                sx={{ width: 280, py: sectionTasks?.length === 0 ? 3 : 0 }}
                            >
                                {sectionTasks.map((task, taskIndex) => (
                                    <React.Fragment key={task.id}>
                                        <KanbanTaskCard
                                            propertyName={properties?.find((p) => p.id === task.propertyId)?.label ?? ""}
                                            areDetailsOpen={modalOpen === AppModals.TaskDetails}
                                            index={taskIndex}
                                            task={task}
                                            isDragDisabled={!allSections?.find((s) => s.taskSectionId === task.sectionId)?.canEdit || isMobile}
                                            onClick={() => {
                                                dispatch(setModalOpen(AppModals.TaskDetails))
                                                dispatch(setEditingItem({ taskId: task.id, sectionId: task.sectionId }))
                                            }}
                                        />
                                    </React.Fragment>
                                ))}
                                {dropProvided.placeholder}
                            </Stack>
                        )}
                    </Droppable>
                )}
            </Stack>
        </Paper>
    )
}
