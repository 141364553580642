import { CustomDataGrid, Iconify, useIsMobile, useLocales } from "rentzz"
import { useDispatch } from "../../../redux/store"
import { alpha, useTheme } from "@mui/material/styles"
import React, { useMemo } from "react"
import { UserBankAccount, useUserBankAccountsQuery } from "../../../queries/userData"
import { GridColDef } from "@mui/x-data-grid-pro"
import { DateTime } from "luxon"
import { Box, Button, Card, CardHeader, Divider, Typography } from "@mui/material"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../queries/currency"
import CustomMobileCard from "../../../components/CustomMobileCard"
import { AppModals, setModalOpen } from "../../../redux/slices/App"
import NoDataPage from "../../../components/NoDataPage"

export default function PropertyBankAccountsTable() {
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const { data: currencyData } = useCurrencyQueryFn()
    const { data: bankAccounts, isLoading: areBankAccountLoading } = useUserBankAccountsQuery()

    const columns: GridColDef<UserBankAccount>[] = useMemo(
        () => [
            {
                field: "holder",
                type: "string",
                minWidth: 120,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("holder"),
            },
            {
                field: "bank",
                minWidth: 160,
                headerAlign: "center",
                align: "center",
                flex: 0.2,
                headerName: translate("bank"),
                valueFormatter: (value) => translate(`bank-name-${value}`),
            },
            {
                field: "iban",
                minWidth: 160,
                headerAlign: "center",
                align: "center",
                flex: 0.2,
                headerName: translate("iban"),
            },
            {
                field: "currencyId",
                minWidth: 160,
                headerAlign: "center",
                align: "center",
                flex: 0.2,
                headerName: translate("currency"),
                valueFormatter: (value) => getCurrencyFromId(currencyData, value).code,
            },
            {
                field: "createdAt",
                type: "date",
                minWidth: 100,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("createdAt"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime).toLocaleString(DateTime.DATE_SHORT),
            },
            {
                field: "lastModifiedAt",
                type: "date",
                minWidth: 100,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("lastModifiedAt"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime).toLocaleString(DateTime.DATE_SHORT),
            },
        ],
        [translate],
    )

    return (
        <Card>
            <CardHeader
                title={translate("assigned_bank_accounts")}
                sx={{ p: 2, color: theme.palette.grey.A700 }}
                action={
                    <Button
                        sx={{
                            display: "flex",
                            gap: 1,
                            borderRadius: 1,
                            ":hover": { borderRadius: 1, backgroundColor: alpha(theme.palette.primary.main, 0.1) },
                        }}
                        variant={"text"}
                        color={"primary"}
                        onClick={() => {
                            dispatch(setModalOpen(AppModals.AddBankAccount))
                        }}
                    >
                        <Iconify icon={"mdi:add"} />
                        <Typography>{translate("add_bank_account")}</Typography>
                    </Button>
                }
            />
            <Divider />

            {bankAccounts?.count === 0 && isMobile && <NoDataPage icon={""} noDataText={"no_bank_accounts"} paddingTop={2} />}

            {isMobile ? (
                <Box display={"flex"} flexDirection={"column"} gap={1} paddingY={1}>
                    {bankAccounts?.items?.map((bankAccount) => (
                        <CustomMobileCard
                            key={bankAccount.id}
                            item={bankAccount}
                            onClick={undefined}
                            icon={"mdi:bank"}
                            iconColor={theme.palette.primary.main}
                            title={bankAccount.holder}
                            subtitle={bankAccount.iban}
                            rightFirstItem={translate(`bank-name-${bankAccount.bank}`)}
                            rightSecondItem={getCurrencyFromId(currencyData, bankAccount.currencyId).code}
                        />
                    ))}
                </Box>
            ) : (
                <CustomDataGrid
                    sortingMode='server'
                    columns={columns}
                    idKey={"id"}
                    getRowId={(row) => row.id}
                    actionsColumn={[]}
                    rows={bankAccounts?.items ?? []}
                    infoColumn={[]}
                    gridHeight={300}
                    isLoading={areBankAccountLoading}
                    components={{
                        noRowsOverlay: () => <NoDataPage icon={""} noDataText={"no_bank_accounts"} paddingTop={10} />,
                    }}
                />
            )}
        </Card>
    )
}
