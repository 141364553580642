import { Box } from "@mui/material"
import HelpComponent from "./HelpComponent"
import RoleLockedButton from "./RoleLockedButton"
import { RoleName } from "../queries/userData"
import React from "react"

interface CustomToolbarProps {
    onAddClick: () => void
    buttonText: string
    blockedRoles: RoleName[]
}
export default function CustomRoleBlockedToolbar({ onAddClick, buttonText, blockedRoles }: CustomToolbarProps) {
    return (
        <Box display={"flex"} flexDirection={"column"}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 2,
                    marginBottom: 2,
                    alignItems: "center",
                }}
            >
                <HelpComponent helpPage={"https://rentzz.app/kbtopic/"}>
                    <RoleLockedButton onClick={onAddClick} buttonText={buttonText} blockedRoles={blockedRoles} />
                </HelpComponent>
            </Box>
        </Box>
    )
}
