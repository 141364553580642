import { Box, CircularProgress } from "@mui/material"
import { useConfiguredExpenseProviders } from "../../../../queries"
import React from "react"
import { useDispatch } from "../../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import CustomProviderCard from "../../../../components/CustomProviderCard"
import { ProviderType } from "../../../../redux/slices/AddProperty"

export default function MobileProvidersList() {
    const dispatch = useDispatch()
    const { data: providers, isLoading: areProvidersLoading } = useConfiguredExpenseProviders()

    return (
        <>
            {areProvidersLoading ? (
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={200}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box padding={2} display={"flex"} flexDirection={"column"} rowGap={1}>
                    {providers?.map((provider) => (
                        <CustomProviderCard
                            onCardClick={() => {
                                dispatch(setModalOpen(AppModals.ProviderDetails))
                                dispatch(setEditingItem({ id: provider.id, name: provider.providerName, providerType: ProviderType.Expense }))
                            }}
                            key={provider.id}
                            logo={provider.providerLogo}
                            status={provider.status}
                            name={provider.providerName}
                        />
                    ))}
                </Box>
            )}
        </>
    )
}
