import { array, number, object, string } from "yup"

export const TaskNameSchema = object().shape({
    name: string().required("name_required").min(3, "minimum_characters"),
})
export const TaskFilesSchema = object().shape({
    files: array().required(),
})

export const AddNewSectionSchema = object().shape({
    name: string().required("required_name").min(3, "minimum_characters"),
})

export const AddNewTaskBaseSchema = () =>
    object().shape({
        name: string().required("required_name"),
        description: string().required("task_description_required"),
        files: array().required(),
        rentingPeriodId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .optional(),
    })

export const OwnerAddTaskSchema = () =>
    AddNewTaskBaseSchema().shape({
        sectionId: string().required("section_required"),
        propertyId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required(),
    })

export const TaskDescriptionSchema = object().shape({ description: string().required("task_description_required") })

export const TaskCommentBaseSchema = () =>
    object().shape({
        message: string().required("task_message_required").min(1, "minimum_characters"),
        taskId: string().required("section_required"),
        files: array().required(),
    })

export const OwnerTaskCommentSchema = () =>
    TaskCommentBaseSchema().shape({
        sectionId: string().required("section_required"),
    })

export const TenantTaskCommentSchema = () =>
    TaskCommentBaseSchema().shape({
        rentingPeriodId: number().required(),
    })

export const EditTaskSchema = object().shape({
    name: string().required("name_required").min(3, "minimum_characters"),
    sectionId: string().required("section_required"),
    rentingPeriodId: number().required("renting_period_required"),
    description: string().required("task_description_required"),
})
