import { AppContext, FormProvider } from "rentzz"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { TaskNameSchema } from "../../../validations/kanban"
import { useCallback } from "react"
import RHFEditableInput from "../../../components/RHFEditableInput"
import { updateTaskMutation, updateTenantTaskMutation } from "../../../mutations/kanban"
import * as jsonpatch from "fast-json-patch"
import { inputBaseClasses } from "@mui/material"
import { useSelector } from "../../../redux/store"

interface TaskNameRequest {
    name: string
}

interface Props {
    taskName: string
    taskId: string
    sectionId: string | undefined
    canEdit?: boolean
}

export default function InputNameForm({ taskName, taskId, sectionId, canEdit }: Props) {
    const { mutateAsync: editTaskName, isPending: isEditTaskNameLoading } = updateTaskMutation()
    const { mutateAsync: editTenantTaskName, isPending: isEditTenantTaskNameLoading } = updateTenantTaskMutation()
    const { context, editingItem } = useSelector((state) => state.appState)

    const methods = useForm<TaskNameRequest>({
        resolver: yupResolver(TaskNameSchema),
        defaultValues: {
            name: taskName,
        },
    })

    const { getValues } = methods

    const onSubmit = useCallback(async () => {
        const operations = jsonpatch.compare({ name: taskName }, { name: getValues("name") })
        if (taskName === getValues("name")) return
        if (context === AppContext.Owner) {
            if (sectionId == null) return
            await editTaskName({
                sectionId,
                taskId,
                operations,
                newIndex: -1,
            })
        } else {
            await editTenantTaskName({
                rentingPeriodId: editingItem?.rentingPeriodId,
                taskId,
                operations,
            })
        }
    }, [sectionId, taskId, editTaskName, getValues, taskName, editTenantTaskName, editingItem])

    return (
        <FormProvider methods={methods}>
            <RHFEditableInput
                name={"name"}
                onBlur={onSubmit}
                disabled={isEditTaskNameLoading || !canEdit || isEditTenantTaskNameLoading}
                sx={{
                    [`&.${inputBaseClasses.root}`]: {
                        py: 0.75,
                        borderRadius: 1,
                        typography: "h6",
                        borderWidth: 2,
                        borderStyle: "solid",
                        borderColor: "transparent",
                        transition: (theme) => theme.transitions.create(["padding-left", "border-color"]),
                        [`&.${inputBaseClasses.focused}`]: {
                            pl: 1.5,
                            borderColor: "text.primary",
                        },
                    },
                    [`& .${inputBaseClasses.input}`]: {
                        typography: "h6",
                    },
                }}
            />
        </FormProvider>
    )
}
