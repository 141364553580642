import { Box, IconButton, useTheme } from "@mui/material"
import { CustomPopover, LightTooltip, useLocales } from "rentzz"
import React, { MouseEvent, useState } from "react"
import { useDispatch } from "../../../redux/store"
import { object, string } from "yup"
import { DESKTOP_ICON_SIZE } from "../../../utils/helpers"
import { PermissionType, usePermissions } from "../../../hooks/usePermissions"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import ArchiveIcon from "@mui/icons-material/Archive"
import { useAtom } from "jotai"
import { PropertiesPage, propertiesPageAtom } from "../../../utils/atom"
import UnarchiveIcon from "@mui/icons-material/Unarchive"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import EditPropertyNameForm from "./EditPropertyNameForm"
import { UserFlags } from "../../../queries/userData"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import DeleteIcon from "@mui/icons-material/Delete"

interface HeaderProps {
    propertyName: string
    propertyId: number
    isArchived: boolean
}

export const PropertyNameChangeSchema = object().shape({
    newPropertyName: string().required("property_name_required").min(3, "minimum_characters"),
})

export interface PropertyNameChangeRequest {
    newPropertyName: string
}

export default function Header({ propertyId, propertyName, isArchived }: HeaderProps) {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const theme = useTheme()
    const [open, setOpen] = useState<HTMLElement | null>(null)
    const { isPropertyEditable, canDelete } = usePermissions(PermissionType.Properties, propertyId)
    const [propertiesPage] = useAtom(propertiesPageAtom)
    const canArchiveProperties = useFeatureIsOn(UserFlags.ArchiveProperties.toString())

    const handleOpen = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setOpen(event.currentTarget)
    }

    return (
        <Box display='flex' justifyContent='space-between' flexDirection='row' width='100%' alignItems='center' gap={1}>
            <Box flex={1} display='flex' flexDirection='row' alignItems='center' justifyContent={"end"}>
                <Box>
                    {isPropertyEditable && (
                        <LightTooltip title={translate("edit_property_name")} arrow>
                            <IconButton size='small' color={"primary"} onClick={handleOpen}>
                                <ModeEditIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />
                            </IconButton>
                        </LightTooltip>
                    )}

                    <CustomPopover
                        open={open}
                        anchorEl={open}
                        arrow={"top-center"}
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                        onClose={(event: MouseEvent<HTMLElement>) => {
                            event.stopPropagation()
                            setOpen(null)
                        }}
                        sx={{
                            mt: 1.5,
                            ml: 0.75,
                            width: 200,
                            "& .MuiMenuItem-root": { px: 1, typography: "body2", borderRadius: 0.75 },
                        }}
                    >
                        <Box p={1}>
                            <EditPropertyNameForm propertyId={propertyId} onClose={() => setOpen(null)} />
                        </Box>
                    </CustomPopover>
                </Box>
                {isPropertyEditable && canArchiveProperties && (
                    <LightTooltip title={translate(propertiesPage === PropertiesPage.Active ? "archived" : "unzip")} arrow>
                        <span>
                            <IconButton
                                size='small'
                                onClick={async (e) => {
                                    e.stopPropagation()
                                    dispatch(setEditingItem({ isArchived, name: propertyName, id: propertyId }))
                                    dispatch(setModalOpen(AppModals.HandleArchivedProperties))
                                }}
                                style={{ color: theme.palette.primary.main }}
                            >
                                {propertiesPage === PropertiesPage.Active ? (
                                    <ArchiveIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />
                                ) : (
                                    <UnarchiveIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />
                                )}
                            </IconButton>
                        </span>
                    </LightTooltip>
                )}
                {canDelete && (
                    <IconButton
                        size='small'
                        color={"error"}
                        onClick={(e) => {
                            e.stopPropagation()

                            dispatch(
                                setEditingItem({
                                    propertyId,
                                    propertyName,
                                }),
                            )
                            dispatch(setModalOpen(AppModals.DeleteProperty))
                        }}
                    >
                        <DeleteIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />
                    </IconButton>
                )}
            </Box>
        </Box>
    )
}
