import { useMutation } from "@tanstack/react-query"
import Api from "../../api/Api"
import { ComplexQueryIDs, SimpleQueryIDs, useQueryInvalidator } from "../../hooks/useQueryInvalidator"
import * as jsonpatch from "fast-json-patch"
import { AddRecurringExpenseRequest } from "./types"
import { setErrorMessage } from "../../redux/slices/App"
import { useDispatch } from "../../redux/store"
import { AxiosError } from "axios"
import { useIsMobile } from "rentzz"
import { BackendError } from "../../sections/types/user"
import { AddExpenseRequest } from "../../sections/expenses/AddExpenseForm"

export const addExpenseMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()
    const dispatch = useDispatch()
    const isMobile = useIsMobile()

    return useMutation({
        mutationFn: ({ data }: { data: AddExpenseRequest }) => Api.addExpense(data),
        onMutate: () => {
            dispatch(setErrorMessage(undefined))
        },
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([
                    ComplexQueryIDs.Expense,
                    SimpleQueryIDs.UserData,
                    SimpleQueryIDs.UserProperties,
                    ComplexQueryIDs.SummaryCard,
                    ComplexQueryIDs.DashboardChart,
                    ComplexQueryIDs.DashboardTable,
                    ComplexQueryIDs.Expenses,
                    ComplexQueryIDs.TenantPropertyFiles,
                    ComplexQueryIDs.RentingPeriodDetails,
                    ComplexQueryIDs.Property,
                    ComplexQueryIDs.OwnerPropertyFiles,
                    ComplexQueryIDs.Events,
                    ComplexQueryIDs.ExpenseSummary,
                    ComplexQueryIDs.ExpenseSummaryReport,
                ])
            } else if (isMobile) {
                const errorResponse = error as AxiosError
                const errorMessage = errorResponse?.response?.data as BackendError
                dispatch(setErrorMessage(errorMessage.Message))
            }
        },
    })
}

export const deleteExpenseMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ expenseId }: { expenseId: number; propertyId?: number }) => Api.deleteExpense({ expenseId }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([
                    ComplexQueryIDs.Expense,
                    ComplexQueryIDs.Property,
                    ComplexQueryIDs.OwnerPropertyFiles,
                    SimpleQueryIDs.UserData,
                    SimpleQueryIDs.UserProperties,
                    ComplexQueryIDs.SummaryCard,
                    ComplexQueryIDs.DashboardChart,
                    ComplexQueryIDs.DashboardTable,
                    ComplexQueryIDs.Expenses,
                    ComplexQueryIDs.TenantPropertyFiles,
                    ComplexQueryIDs.RentingPeriodDetails,
                    ComplexQueryIDs.Events,
                    ComplexQueryIDs.ExpenseSummary,
                    ComplexQueryIDs.ExpenseSummaryReport,
                    SimpleQueryIDs.UserNotifications,
                ])
            }
        },
    })
}

export const createInvoiceFromExpenseMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({
            expenseId,
            invoiceDate,
            invoiceDueDate,
            modifyExpenseWithInvoiceData,
        }: {
            expenseId: number
            invoiceDate: string
            invoiceDueDate: string
            modifyExpenseWithInvoiceData: boolean
        }) => Api.createInvoiceFromExpense({ expenseId, invoiceDate, invoiceDueDate, modifyExpenseWithInvoiceData }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([
                    ComplexQueryIDs.Expense,
                    ComplexQueryIDs.Property,
                    ComplexQueryIDs.OwnerPropertyFiles,
                    SimpleQueryIDs.UserData,
                    SimpleQueryIDs.UserProperties,
                    ComplexQueryIDs.SummaryCard,
                    ComplexQueryIDs.DashboardChart,
                    ComplexQueryIDs.DashboardTable,
                    ComplexQueryIDs.Expenses,
                    ComplexQueryIDs.TenantPropertyFiles,
                    ComplexQueryIDs.RentingPeriodDetails,
                    ComplexQueryIDs.Events,
                    ComplexQueryIDs.ExpenseSummary,
                    ComplexQueryIDs.ExpenseSummaryReport,
                    SimpleQueryIDs.PendingExpense,
                ])
            }
        },
    })
}

export const deleteRecurringExpenseMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ expenseId }: { expenseId: string }) => Api.deleteRecurringExpense({ expenseId }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([
                    ComplexQueryIDs.RecurringExpenses,
                    SimpleQueryIDs.RecurringExpense,
                    ComplexQueryIDs.Expense,
                    SimpleQueryIDs.UserData,
                    SimpleQueryIDs.UserProperties,
                    ComplexQueryIDs.SummaryCard,
                    ComplexQueryIDs.DashboardChart,
                    ComplexQueryIDs.DashboardTable,
                    ComplexQueryIDs.Expenses,
                    ComplexQueryIDs.TenantPropertyFiles,
                    ComplexQueryIDs.RentingPeriodDetails,
                    ComplexQueryIDs.Property,
                    ComplexQueryIDs.OwnerPropertyFiles,
                    ComplexQueryIDs.Events,
                ])
            }
        },
    })
}

export const updateRecurringExpenseMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ id, operations }: { id: string; operations: jsonpatch.Operation[] }) => Api.updateRecurringExpense({ id, operations }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([
                    ComplexQueryIDs.RecurringExpenses,
                    SimpleQueryIDs.RecurringExpense,
                    ComplexQueryIDs.Expense,
                    SimpleQueryIDs.UserData,
                    SimpleQueryIDs.UserProperties,
                    ComplexQueryIDs.SummaryCard,
                    ComplexQueryIDs.DashboardChart,
                    ComplexQueryIDs.DashboardTable,
                    ComplexQueryIDs.Expenses,
                    ComplexQueryIDs.TenantPropertyFiles,
                    ComplexQueryIDs.RentingPeriodDetails,
                    ComplexQueryIDs.Property,
                    ComplexQueryIDs.OwnerPropertyFiles,
                    ComplexQueryIDs.Events,
                ])
            }
        },
    })
}

export const updateExpenseMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()
    const dispatch = useDispatch()
    const isMobile = useIsMobile()

    return useMutation({
        mutationFn: ({ expenseId, operations }: { propertyId?: number; expenseId: number; operations: jsonpatch.Operation[] }) =>
            Api.updateExpenseAsync({
                expenseId,
                operations,
            }),
        onMutate: () => {
            dispatch(setErrorMessage(undefined))
        },
        onSettled: async (data, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.Expenses], true)
                await invalidateQueries([
                    ComplexQueryIDs.Property,
                    ComplexQueryIDs.SummaryCard,
                    ComplexQueryIDs.DashboardChart,
                    ComplexQueryIDs.DashboardTable,
                    ComplexQueryIDs.Expenses,
                    ComplexQueryIDs.Expense,
                    ComplexQueryIDs.Events,
                    ComplexQueryIDs.ExpenseSummary,
                    ComplexQueryIDs.ExpenseSummaryReport,
                    ComplexQueryIDs.Incomes,
                    ComplexQueryIDs.Income,
                    SimpleQueryIDs.UserData,
                    SimpleQueryIDs.UserNotifications,
                ])
            } else if (isMobile) {
                const errorResponse = error as AxiosError
                const errorMessage = errorResponse?.response?.data as BackendError
                dispatch(setErrorMessage(errorMessage.Message))
            }
        },
    })
}

export const addRecurringExpenseMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ data }: { data: AddRecurringExpenseRequest }) => Api.addRecurringExpense(data),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([
                    ComplexQueryIDs.Property,
                    ComplexQueryIDs.OwnerPropertyFiles,
                    ComplexQueryIDs.Events,
                    ComplexQueryIDs.RecurringExpenses,
                    ComplexQueryIDs.Expense,
                    SimpleQueryIDs.UserData,
                    SimpleQueryIDs.UserProperties,
                    ComplexQueryIDs.SummaryCard,
                    ComplexQueryIDs.DashboardChart,
                    ComplexQueryIDs.DashboardTable,
                    ComplexQueryIDs.Expenses,
                    ComplexQueryIDs.TenantPropertyFiles,
                    ComplexQueryIDs.RentingPeriodDetails,
                ])
            }
        },
    })
}

export const redirectToPaymentsPageMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()
    const dispatch = useDispatch()
    const isMobile = useIsMobile()

    return useMutation({
        mutationFn: ({ expenseId }: { expenseId: number }) => Api.fetchRedirectPaymentPage(expenseId),
        onMutate: () => {
            dispatch(setErrorMessage(undefined))
        },
        onSettled: async (data, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.Expense], true)
                await invalidateQueries([
                    ComplexQueryIDs.Expenses,
                    ComplexQueryIDs.Events,
                    ComplexQueryIDs.ExpenseSummary,
                    ComplexQueryIDs.ExpenseSummaryReport,
                    ComplexQueryIDs.Incomes,
                    ComplexQueryIDs.Income,
                ])
                if (data) {
                    window.open(data, "_blank")
                }
            } else if (isMobile) {
                const errorResponse = error as AxiosError
                const errorMessage = errorResponse?.response?.data as BackendError
                dispatch(setErrorMessage(errorMessage.Message))
            }
        },
    })
}
