import { TaskSection } from "../../../queries/kanban"
import { FormProvider, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useCallback, useEffect } from "react"
import * as jsonpatch from "fast-json-patch"
import { Box, Button } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { AddNewSectionSchema } from "../../../validations/kanban"
import { addNewSectionMutation, updateUserSectionMutation } from "../../../mutations/kanban"

interface Props {
    onClose: () => void
    defaultValues?: TaskSection
}

export interface AddSectionRequest {
    name: string
}

export default function AddOrEditSectionForm({ onClose, defaultValues }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { mutateAsync: createNewSection, isPending: isCreateNewSectionLoading } = addNewSectionMutation()
    const { mutateAsync: updateSection, isPending: isUpdateSectionLoading } = updateUserSectionMutation()

    const methods = useForm<AddSectionRequest>({
        resolver: yupResolver(AddNewSectionSchema),
        mode: "onChange",
    })

    const { handleSubmit, reset } = methods

    useEffect(() => {
        if (defaultValues) {
            reset({
                ...defaultValues,
            })
        }
    }, [defaultValues, reset])

    const onSave = useCallback(
        async (data: AddSectionRequest) => {
            if (defaultValues) {
                const operations = jsonpatch.compare({ name: defaultValues?.name }, { name: data.name })
                await updateSection({
                    sectionId: defaultValues.taskSectionId,
                    operations,
                })
            } else {
                await createNewSection({ name: data.name })
            }

            onClose()
        },
        [createNewSection, onClose, updateSection, defaultValues],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
            <Box display={"flex"} gap={2} paddingTop={2} flexDirection={"column"}>
                <RHFTextField name='name' label={translate("name")} size='small' required />

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingX: 0,
                        paddingTop: 2,
                        gap: 2,
                        flexDirection: isMobile ? "column-reverse" : "row",
                    }}
                >
                    <Button fullWidth={isMobile} color={"primary"} onClick={onClose} disabled={isCreateNewSectionLoading || isUpdateSectionLoading}>
                        {translate("cancel")}
                    </Button>
                    <LoadingButton
                        fullWidth={isMobile}
                        color={"primary"}
                        type='submit'
                        variant='contained'
                        disabled={isCreateNewSectionLoading || isUpdateSectionLoading}
                        loading={isCreateNewSectionLoading || isUpdateSectionLoading}
                    >
                        {translate("submit")}
                    </LoadingButton>
                </Box>
            </Box>
        </FormProvider>
    )
}
