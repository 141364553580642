import React, { useCallback, useEffect, useMemo, useState } from "react"
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridActionsCellItem, GridColDef, GridRowParams, GridSortModel, useGridApiRef } from "@mui/x-data-grid-pro"
import { AppContext, CustomDataGrid, Label, LightTooltip, LoadingIcon, useIsMobile, useLocales } from "rentzz"
import { DateTime } from "luxon"
import { IncomeStatus, PropertyFileType, PropertyIncome, PropertyIncomePaymentMethod } from "../../redux/slices/AddProperty"
import { useDispatch, useSelector } from "../../redux/store"
import { AccountType, useUserDataQueryFn, useUserPropertiesQuery } from "../../queries/userData"
import { useCurrencyQueryFn } from "../../queries/currency"
import { useRentingPeriodsWithPaginationQueryFn } from "../../queries/tenants"
import { AppModals, setEditingItem, setModalOpen } from "../../redux/slices/App"
import ActionWithTooltip from "../../utils/ActionWithTooltip"
import { DESKTOP_ICON_SIZE, getIncomeLabelColorByPaymentMethod, getIncomeLabelColorByStatus } from "../../utils/helpers"
import { Box, Divider, Typography } from "@mui/material"
import { useIncomeQueryFn } from "../../queries/income"
import DeleteIcon from "@mui/icons-material/Delete"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import NoPropertyPage from "../../guards/no-property/NoPropertyPage"
import { PermissionType, usePermissions } from "../../hooks/usePermissions"
import { incomesFilterState } from "../../utils/atom"
import { useAtomValue } from "jotai"
import NoDataPage from "../../components/NoDataPage"
import IncomeDetailsPanel from "./IncomeDetailsPanel"
import CustomDetailPanelToggle from "../expenses/CustomDetailPanelToggle"
import RentingPeriodDetailsHeader from "../propertyDetails/Tenants/rentingPeriodDetails/RentingPeriodDetailsHeader"
import { getIncomeTitleStatus } from "./utils"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import IncomeInfoButton from "./IncomeInfoButton"
import CustomToolbar from "../../components/CustomToolbar"
import HeaderIncomes from "./HeaderIncomes"
import { ExportType } from "../../utils/actions"

interface DesktopIncomeListProps {
    getAmount: (value: number, currencyId: number) => string
}
export default function DesktopIncomeList({ getAmount }: DesktopIncomeListProps) {
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    })
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "date", sort: "desc" }])
    const filterModel = useAtomValue(incomesFilterState)
    const api = useGridApiRef()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { currentLang } = useLocales()
    const { data: userProperties } = useUserPropertiesQuery()
    const { isLoading: areCurrenciesLoading } = useCurrencyQueryFn()
    const { data: userData } = useUserDataQueryFn()
    const isMobile = useIsMobile()
    const { currentPropertyId, context, currentGroupId, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriods, isLoading: areRentingPeriodLoading } = useRentingPeriodsWithPaginationQueryFn(0, 0, [])
    const { data: incomesData, isFetching } = useIncomeQueryFn(paginationModel.page, paginationModel.pageSize, sortModel, filterModel)
    const { canWrite } = usePermissions(PermissionType.Incomes)

    useEffect(() => {
        if (incomesData?.items?.length === 0 && incomesData.count === 0 && api.current?.setRows) {
            api.current?.setRows([])
        }
    }, [incomesData, isFetching])

    const actionsColumn = useMemo(
        (): GridColDef => ({
            field: "actions",
            resizable: false,
            flex: 0.5,
            minWidth: 180,
            type: "actions",
            headerName: translate("expenses.actions"),
            getActions: (params: GridRowParams) => [
                ActionWithTooltip({
                    hidden: !params.row.canWrite || params.row.status !== IncomeStatus.Waiting,
                    tooltipText: translate("payment_is_waiting_owner"),
                    key: "status",
                    element: (
                        <GridActionsCellItem
                            key='status'
                            color='warning'
                            icon={<ErrorOutlineIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            label={translate("status")}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setEditingItem(params.row))
                                dispatch(setModalOpen(AppModals.HandleIncomeStatus))
                            }}
                            sx={{ p: 1 }}
                        />
                    ),
                }),
                ActionWithTooltip({
                    hidden: !params.row.canWrite || params.row.paymentMethod === PropertyIncomePaymentMethod.Online,
                    tooltipText: translate("edit"),
                    key: "edit",
                    element: (
                        <GridActionsCellItem
                            key='edit'
                            color='primary'
                            icon={<ModeEditIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            label={translate("grid_actions.edit")}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setEditingItem({ id: params.row.id }))
                                dispatch(setModalOpen(AppModals.EditIncome))
                            }}
                            sx={{ p: 1 }}
                        />
                    ),
                }),
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"info"}
                            icon={<CloudDownloadIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={async (event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(
                                    setEditingItem({
                                        id: params.id,
                                        type: PropertyFileType.Income,
                                        name: translate(`income-table.incomeType-${params.row.type}`),
                                        canWrite: params.row.canWrite,
                                    }),
                                )
                                dispatch(setModalOpen(AppModals.EntityFileManager))
                            }}
                            label={translate("see_files")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: translate("see_files"),
                    key: "download",
                }),
                ActionWithTooltip({
                    tooltipText: translate("delete"),
                    key: "delete",
                    hidden: !params.row.canDelete,
                    element: (
                        <GridActionsCellItem
                            key='delete'
                            color={"error"}
                            icon={<DeleteIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setModalOpen(AppModals.DeleteIncome))
                                dispatch(setEditingItem(params.row))
                            }}
                            label={translate("grid_actions.delete")}
                            sx={{ p: 1 }}
                        />
                    ),
                }),
            ],
        }),
        [translate],
    )

    const incomeColumns = useMemo(() => {
        let columns: GridColDef<PropertyIncome>[] = [
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                minWidth: 80,
                flex: 0.2,
                renderCell: (params) => {
                    return (
                        <Box display={"flex"} gap={1} alignItems={"center"}>
                            <CustomDetailPanelToggle id={params.id} value={params.value} title={"see_expenses"} />
                            <IncomeInfoButton rentingPeriodId={params.row.rentingPeriodId} />
                        </Box>
                    )
                },
            },
            {
                field: "propertyId",
                type: "string",
                flex: 0.8,
                minWidth: 180,
                headerAlign: "center",
                align: "center",
                headerName: translate("property"),
                renderCell: (v) => {
                    return (
                        <Typography textAlign={"center"} variant={"body2"}>
                            {userProperties?.find((p) => p.id === v.value)?.label}
                        </Typography>
                    )
                },
            },
            {
                field: "paymentMethod",
                minWidth: 90,
                flex: 0.4,
                headerAlign: "center",
                align: "center",
                headerName: translate("income-table.paymentMethod"),
                renderCell: (v) => {
                    return (
                        <Label color={getIncomeLabelColorByPaymentMethod(v.value)} style={{ textTransform: "none" }}>
                            {translate(`income-table.paymentMethod-${v.value}`)}
                        </Label>
                    )
                },
            },
            {
                field: "value",
                type: "number",
                flex: 0.7,
                minWidth: 140,
                headerAlign: "center",
                align: "center",
                headerName: translate("income-table.value"),
                valueFormatter: (value, row) => {
                    return getAmount(value, row.currencyId as number)
                },
            },
            {
                field: "status",
                type: "string",
                minWidth: 140,
                flex: 0.5,
                headerAlign: "center",
                align: "center",
                headerName: translate("payment.status"),
                renderCell: (v) => {
                    return (
                        <LightTooltip title={translate(getIncomeTitleStatus(v.value))} arrow>
                            <span>
                                <Label color={getIncomeLabelColorByStatus(v.value)} style={{ textTransform: "none" }}>
                                    {translate(`payments-table.status-${v.value as number}`)}
                                </Label>
                            </span>
                        </LightTooltip>
                    )
                },
            },
            {
                field: "date",
                type: "date",
                minWidth: 100,
                flex: 0.7,
                headerAlign: "center",
                align: "center",
                headerName: translate("income-table.date"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime).toLocaleString(DateTime.DATE_SHORT),
            },
        ]

        if (filterModel.items.find((i) => i.field === "status")?.value === "'Waiting'") {
            columns = columns.filter((c) => c.field !== "status")
        }

        if (filterModel.items.find((i) => i.field === "status")?.value === "'AcceptedFromExternalSystem'") {
            columns = columns.filter((c) => c.field !== "status")
        }

        if (currentPropertyId || currentRentingPeriodId) {
            return columns.filter((c) => c.field !== "propertyId")
        }

        return columns
    }, [incomesData, currentPropertyId, currentLang, userProperties, currentRentingPeriodId, filterModel])

    const isDisabled = useMemo(() => {
        if (userProperties?.length === 0) return true

        return rentingPeriods?.count === 0
    }, [userProperties, rentingPeriods])

    const getTooltipTitle = useCallback(() => {
        if (userProperties?.length === 0) return "no_property_no_income"

        if (rentingPeriods?.count === 0) {
            return "tenant_required_income"
        }
        return ""
    }, [userProperties, rentingPeriods, dispatch])

    const getIncomePanel = useCallback((row: GridRowParams<PropertyIncome>) => {
        return <IncomeDetailsPanel currentIncome={row} />
    }, [])

    if (incomesData == null || areCurrenciesLoading || areRentingPeriodLoading) return <LoadingIcon />

    if (userProperties?.length === 0 && userData?.accountType === AccountType.WHITELABEL_USER && context === AppContext.Owner) {
        return <NoPropertyPage />
    }

    return (
        <>
            {currentRentingPeriodId ? (
                <RentingPeriodDetailsHeader page={"incomes"} />
            ) : (
                <CustomToolbar
                    onAddClick={canWrite && !currentGroupId ? () => dispatch(setModalOpen(AppModals.AddIncome)) : undefined}
                    numberOfItems={incomesData?.count}
                    canExport={!!currentGroupId}
                    buttonText={"income-table.addIncome"}
                    exportType={ExportType.Income}
                >
                    <HeaderIncomes />
                    <Divider />
                </CustomToolbar>
            )}
            <CustomDataGrid
                apiRef={api}
                paginationMode={incomesData.count === 20 && incomesData.items.length === 0 ? "client" : "server"}
                hidePagination={incomesData.count === 20 && incomesData.items.length === 0}
                isLoading={isFetching && !(incomesData.count === 20 && incomesData.items.length === 0)}
                rowsLoadingMode={incomesData.count === 20 && incomesData.items.length === 0 ? "server" : "client"}
                columns={incomeColumns}
                infoColumn={[]}
                rows={incomesData.items ?? []}
                components={{
                    noRowsOverlay: () => (
                        <NoDataPage
                            onAddNewItem={
                                isMobile && canWrite && !currentRentingPeriodId ? () => dispatch(setModalOpen(AppModals.AddIncome)) : undefined
                            }
                            icon={"mdi:cash-remove"}
                            noDataText={"no_income"}
                            textButton={"income-table.addIncome"}
                            tooltipText={getTooltipTitle()}
                            isDisabled={isDisabled}
                        />
                    ),
                }}
                idKey={"id"}
                sortModel={sortModel}
                onSortModelChange={setSortModel}
                filterModel={filterModel}
                sortingMode='server'
                totalCount={incomesData.count}
                actionsColumn={[actionsColumn]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                getDetailPanelContent={getIncomePanel}
                rowHeight={50}
            />
        </>
    )
}
