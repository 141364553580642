import CustomCard from "./CustomCard"
import { Avatar, Box, Typography } from "@mui/material"
import { Label, useLocales } from "rentzz"
import { ProviderStatus } from "../redux/slices/AddProperty"
import React, { useCallback } from "react"
import { useTheme } from "@mui/material/styles"

interface Props {
    key: any
    onCardClick: () => void
    logo: string
    name: string
    status: ProviderStatus
}
export default function CustomProviderCard({ onCardClick, key, logo, name, status }: Props) {
    const theme = useTheme()
    const { translate } = useLocales()

    const getLabelColor = useCallback(
        (status: ProviderStatus) => {
            if (status === ProviderStatus.Pending) return "warning"
            if (status === ProviderStatus.Rejected) return "error"
            return "success"
        },
        [translate],
    )

    return (
        <CustomCard key={key} onClick={onCardClick} isCardClickable px={1} py={1}>
            <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} gap={1} paddingTop={2} alignItems={"center"}>
                <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"}>
                    <Box width={35} height={35} display={"flex"} justifyContent={"center"} alignItems={"center"} px={3}>
                        <Avatar src={logo} />
                    </Box>

                    <Box display={"flex"} flexDirection={"column"}>
                        <Typography
                            fontWeight={"bold"}
                            fontSize={14}
                            color={theme.palette.grey.A700}
                            maxWidth={160}
                            overflow={"hidden"}
                            textOverflow={"ellipsis"}
                        >
                            {name}
                        </Typography>
                    </Box>
                </Box>
                <Label color={getLabelColor(status)} style={{ textTransform: "none" }}>
                    {translate(translate(`rejected_provider_label-${status}`))}
                </Label>{" "}
            </Box>
        </CustomCard>
    )
}
