import { Box, CircularProgress, MenuItem, Paper, Select, Stack, Typography } from "@mui/material"
import { PropertyAutoAcceptType, RoleName, UserFlags, useUserDataQueryFn } from "../../../queries/userData"
import ManagersTable from "../General/management/managers/ManagersTable"
import ProvidersTable from "./providers/ProvidersTable"
import { useTheme } from "@mui/material/styles"
import { Page, useLocales } from "rentzz"
import { useSelector } from "../../../redux/store"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { usePropertyDetailsQueryFn } from "../../../queries"
import { updatePropertyDetailsMutation } from "../../../mutations"
import { PermissionType, usePermissions } from "../../../hooks/usePermissions"
import { useCallback } from "react"
import { SelectChangeEvent } from "@mui/material/Select/SelectInput"
import PropertyBankAccountsTable from "./PropertyBankAccountsTable"

export default function Configurations() {
    const theme = useTheme()
    const { translate } = useLocales()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const areExpenseProvidersEnabled = useFeatureIsOn(UserFlags.ExpenseProviders.toString())
    const { data: currentProperty } = usePropertyDetailsQueryFn(currentPropertyId)
    const { mutate, isPending: isUpdating } = updatePropertyDetailsMutation(currentPropertyId)
    const { canWrite } = usePermissions(PermissionType.Properties)
    const { data: user } = useUserDataQueryFn()

    const onAutoAcceptChange = useCallback((ev: SelectChangeEvent<PropertyAutoAcceptType>) => {
        mutate({ operations: [{ path: "/paymentAutoAcceptType", op: "replace", value: ev.target.value }] })
    }, [])

    return (
        <Page title={translate("configurations")} propertyName={currentProperty?.name} paddingTop={1} paddingBottom={2}>
            <Box display={"flex"} flexDirection={"column"} gap={3} paddingBottom={5}>
                <Paper elevation={3} square={false}>
                    <Stack direction='row' alignItems='center' justifyContent='space-between' p={2}>
                        <Typography variant='h6' sx={{ color: theme.palette.grey.A700 }}>
                            {translate("property-auto-accept-payment")}
                        </Typography>
                        {!isUpdating && (
                            <Select
                                value={currentProperty?.paymentAutoAcceptType}
                                onChange={onAutoAcceptChange}
                                size='small'
                                sx={{ color: theme.palette.grey.A700 }}
                                disabled={!canWrite}
                            >
                                <MenuItem value={PropertyAutoAcceptType.None} sx={{ color: theme.palette.grey.A700 }}>
                                    {translate(`property-auto-accept-type-${PropertyAutoAcceptType.None}`)}
                                </MenuItem>
                                <MenuItem value={PropertyAutoAcceptType.OnlyWithFiles} sx={{ color: theme.palette.grey.A700 }}>
                                    {translate(`property-auto-accept-type-${PropertyAutoAcceptType.OnlyWithFiles}`)}
                                </MenuItem>
                                <MenuItem value={PropertyAutoAcceptType.All} sx={{ color: theme.palette.grey.A700 }}>
                                    {translate(`property-auto-accept-type-${PropertyAutoAcceptType.All}`)}
                                </MenuItem>
                            </Select>
                        )}
                        {isUpdating && <CircularProgress size={"2rem"} />}
                    </Stack>
                </Paper>

                <ManagersTable />
                {user?.roles.roleName !== RoleName.FREE && <PropertyBankAccountsTable />}

                {areExpenseProvidersEnabled && <ProvidersTable />}
            </Box>
        </Page>
    )
}
