import { Box, Button, Typography } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import React from "react"
import { useIsMobile, useLocales } from "rentzz"
import { useSelector } from "../../../redux/store"
import { getRevisionByIdQueryFn } from "../../../queries/revision"
import { RevisionStatus } from "../../../redux/slices/AddProperty"

interface Props {
    onAccept: () => void
    onPending: () => void
    onClose: () => void
    isLoading: boolean
}
export default function RevisionStatusForm({ onAccept, onPending, isLoading, onClose }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { editingItem } = useSelector((state) => state.appState)
    const currentRevision = getRevisionByIdQueryFn(editingItem?.id, [{ field: "date", sort: "desc" }])

    return (
        <Box display='flex' height='100%' flexDirection={"column"} justifyContent='space-between' gap={2}>
            <Typography padding={1}>
                {translate(currentRevision?.status === RevisionStatus.Done ? "revisionStatusContentAccept" : "revisionStatusContentPending", {
                    name: currentRevision?.name,
                })}
            </Typography>
            <Box
                sx={{
                    alignItems: isMobile ? "center" : "flex-end",
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row-reverse",
                    gap: 2,
                }}
            >
                <LoadingButton
                    onClick={currentRevision?.status === RevisionStatus.Done ? onPending : onAccept}
                    variant='contained'
                    color={"primary"}
                    fullWidth={isMobile}
                    loading={isLoading}
                    disabled={isLoading}
                >
                    {translate(currentRevision?.status === RevisionStatus.Done ? "change_status" : "yes")}
                </LoadingButton>
                <Button onClick={onClose} color={"primary"} fullWidth={isMobile} disabled={isLoading}>
                    {translate("cancel")}
                </Button>
            </Box>
        </Box>
    )
}
