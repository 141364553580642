import { AppContext, CustomFile, FormProvider, RHFAutocomplete, RHFSelect, RHFTextField, RHFUpload, useIsMobile, useLocales } from "rentzz"
import { addNewTaskMutation } from "../../mutations/kanban"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { AddNewTaskBaseSchema, OwnerAddTaskSchema } from "../../validations/kanban"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Box, Button, MenuItem } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { useAllSectionsQueryFn } from "../../queries/kanban"
import { useTenantRentingPeriodsQuery, useUserPropertiesQuery } from "../../queries/userData"
import { usePropertyRentingPeriods } from "../../queries/tenants"
import { orderBy } from "lodash"
import { DateTime } from "luxon"
import { useSelector } from "../../redux/store"

export interface AddTaskRequest {
    name: string
    sectionId: string
    files: CustomFile[]
    description: string
    propertyId: number
    rentingPeriodId?: number
}

export interface TenantAddTaskRequest {
    name: string
    files: CustomFile[]
    description: string
    rentingPeriodId?: number
}

interface Props {
    onClose: () => void
}
export default function AddTaskForm({ onClose }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { data: allSections } = useAllSectionsQueryFn()
    const { data: properties } = useUserPropertiesQuery()
    const { data: tenantRentingPeriods } = useTenantRentingPeriodsQuery()
    const { mutateAsync: createNewTask, isPending: isCreateNewTaskLoading } = addNewTaskMutation()
    const [currentPropertyId, setCurrentPropertyId] = useState<number | undefined>(undefined)
    const { data: rentingPeriodsFromAProperty } = usePropertyRentingPeriods(currentPropertyId)
    const { context, currentPropertyId: tenantCurrentPropertyId } = useSelector((state) => state.appState)

    const methods = useForm<AddTaskRequest | TenantAddTaskRequest>({
        resolver: yupResolver(context === AppContext.Owner ? OwnerAddTaskSchema() : AddNewTaskBaseSchema()),
        mode: "onChange",
        defaultValues: {
            files: [],
            rentingPeriodId:
                context === AppContext.Tenant ? tenantRentingPeriods?.find((r) => r.propertyId === tenantCurrentPropertyId)?.rentingPeriodId : -1,
        },
    })

    const { handleSubmit, watch } = methods

    useEffect(() => {
        const subscription = watch((v, { name }) => {
            if (name === "propertyId") {
                setCurrentPropertyId((v as AddTaskRequest).propertyId)
            }
        })
        return () => subscription.unsubscribe()
    }, [setCurrentPropertyId])

    const onSave = useCallback(
        async (data: AddTaskRequest | TenantAddTaskRequest) => {
            if (context === AppContext.Owner) {
                if (data.rentingPeriodId === -1) {
                    data.rentingPeriodId = undefined
                }
                await createNewTask(data as AddTaskRequest)
            } else {
                await createNewTask(data as TenantAddTaskRequest)
            }

            onClose()
        },
        [createNewTask, onClose, context],
    )

    const orderedRentingPeriods = useMemo(() => orderBy(rentingPeriodsFromAProperty, ["moveOutDate"], ["desc"]), [rentingPeriodsFromAProperty])

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
            <Box display={"flex"} gap={2} paddingTop={2} flexDirection={"column"}>
                <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                    <RHFTextField name='name' label={translate("title")} size='small' required />
                    {context == AppContext.Owner && (
                        <RHFSelect name='sectionId' label={translate("section")} size='small' required>
                            {allSections?.map((section) => (
                                <MenuItem key={section.taskSectionId} value={section.taskSectionId}>
                                    {section.name}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                    )}
                </Box>

                <RHFTextField
                    multiline
                    maxRows={10}
                    minRows={2}
                    name='description'
                    placeholder={translate("task_description_placeholder")}
                    label={translate("task_description")}
                    required
                />
                {context === AppContext.Owner && (
                    <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                        <RHFAutocomplete
                            autocompleteProps={{
                                sx: {
                                    width: "100%",
                                },
                                noOptionsText: translate("no_value_found"),
                            }}
                            matchId
                            freeSolo={false}
                            name={"propertyId"}
                            options={properties?.map((property) => ({ id: property.id, label: property.label })) ?? []}
                            label={translate("property")}
                            required
                        />
                        <RHFSelect
                            name={"rentingPeriodId"}
                            disabled={currentPropertyId == null}
                            size='small'
                            label={translate("tenant-data.rentingPeriod")}
                        >
                            <MenuItem value={-1} style={{ textTransform: "none" }}>
                                {translate("no_renting_period")}
                            </MenuItem>
                            {orderedRentingPeriods?.map((period) => (
                                <MenuItem key={period.id} value={period.id} style={{ textTransform: "none" }}>
                                    {`${period.moveInDate.toLocaleString(DateTime.DATE_SHORT)} - ${period.moveOutDate.toLocaleString(
                                        DateTime.DATE_SHORT,
                                    )}`}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                    </Box>
                )}

                <RHFUpload name={"files"} icon={"mdi:file-document-plus-outline"} multiple thumbnail={false} />

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingX: 0,
                        paddingTop: 2,
                        gap: 2,
                        flexDirection: isMobile ? "column-reverse" : "row",
                    }}
                >
                    <Button fullWidth={isMobile} color={"primary"} onClick={onClose} disabled={isCreateNewTaskLoading}>
                        {translate("cancel")}
                    </Button>
                    <LoadingButton
                        fullWidth={isMobile}
                        color={"primary"}
                        type='submit'
                        variant='contained'
                        disabled={isCreateNewTaskLoading}
                        loading={isCreateNewTaskLoading}
                    >
                        {translate("submit")}
                    </LoadingButton>
                </Box>
            </Box>
        </FormProvider>
    )
}
