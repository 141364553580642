import { Box, Typography } from "@mui/material"
import { Currencies } from "rentzz"
import React from "react"
import { UserFlags } from "../../../../queries/userData"
import { useConfiguredExpenseProviders } from "../../../../queries"
import { useFeatureValue } from "@growthbook/growthbook-react"

const ProviderTableSubheader = () => {
    const configuredExpenseProviderPrice = useFeatureValue(UserFlags.ConfiguredExpenseProviderPrice.toString(), 0)
    const { data: providers } = useConfiguredExpenseProviders()

    if (providers == null) return null

    return (
        <Box display='flex' flexDirection='column'>
            <Typography variant='caption'>
                {providers.length * configuredExpenseProviderPrice} {Currencies.RON}
            </Typography>
        </Box>
    )
}

export default ProviderTableSubheader
