import { Box, Step, StepContent, StepLabel, Stepper } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import AddRecurringExpenseRecurrenceStep, { AddRecurringExpenseRecurrenceStepData } from "./AddRecurringExpenseRecurrenceStep"
import { max, min } from "lodash"
import AddRecurringExpenseDetailsStep, { AddRecurringExpenseDetailsStepData } from "./AddRecurringExpenseDetailsStep"
import { AppModals } from "../../../redux/slices/App"
import AddRecurringExpenseReviewStep from "./AddRecurringExpenseReviewStep"
import { AddRecurringExpenseRequest } from "../../../mutations/expenses"
import { useSelector } from "../../../redux/store"
import { useUserDataQueryFn } from "../../../queries/userData"
import { useIsMobile, useLocales } from "rentzz"
import AddRecurringExpenseInvoiceData, { AddRecurringExpenseInvoiceDataRequest } from "./AddRecurringExpenseInvoiceDataRequest"
import { DateTime } from "luxon"

enum AddRecurringExpenseSteps {
    RecurrenceAndName,
    Details,
    InvoiceData,
    Review,
}

interface Props {
    onSave: (data: AddRecurringExpenseRequest) => Promise<void>
    onClose: () => void
    isLoading: boolean
}
export default function AddRecurrentExpenseForm({ onSave, onClose, isLoading }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const [activeStep, setActiveStep] = useState<AddRecurringExpenseSteps>(AddRecurringExpenseSteps.RecurrenceAndName)
    const [newRecurringExpenseRequest, setNewRecurringExpenseRequest] = useState<Partial<AddRecurringExpenseRequest>>()
    const { data: userData } = useUserDataQueryFn()

    useEffect(() => {
        setNewRecurringExpenseRequest(undefined)
    }, [open])

    const getSteps = useCallback(() => {
        const steps = [
            {
                title: translate("recurrence"),
            },
            {
                title: translate("details"),
            },
            {
                title: translate("review"),
            },
        ]

        if (editingItem?.shouldGenerateInvoice) {
            steps.splice(2, 0, { title: translate("invoice_data") })
        }

        return steps
    }, [translate, newRecurringExpenseRequest, editingItem])

    const renderActiveStep = useCallback(() => {
        switch (activeStep) {
            case AddRecurringExpenseSteps.RecurrenceAndName:
                return (
                    <AddRecurringExpenseRecurrenceStep
                        onClose={onClose}
                        onSave={(data: AddRecurringExpenseRecurrenceStepData) => {
                            const creationDay = min(data.recurrencePeriod)
                            const dueDay = max(data.recurrencePeriod)
                            setActiveStep(AddRecurringExpenseSteps.Details)
                            setNewRecurringExpenseRequest((prevState) => ({ ...prevState, creationDay, dueDay, name: data.name }))
                        }}
                        presetValues={
                            newRecurringExpenseRequest
                                ? {
                                      recurrencePeriod: [newRecurringExpenseRequest.creationDay!, newRecurringExpenseRequest.dueDay!],
                                      name: newRecurringExpenseRequest.name!,
                                  }
                                : undefined
                        }
                    />
                )
            case AddRecurringExpenseSteps.Details:
                return (
                    <AddRecurringExpenseDetailsStep
                        onSave={(data: AddRecurringExpenseDetailsStepData) => {
                            if (data.shouldGenerateInvoice) {
                                setActiveStep(AddRecurringExpenseSteps.InvoiceData)
                            } else {
                                setActiveStep(AddRecurringExpenseSteps.Review)
                            }

                            setNewRecurringExpenseRequest((prevState) => ({
                                ...prevState,
                                ...data,
                                expirationDate: data.expirationDate ? DateTime.fromISO(data.expirationDate).toISODate()! : undefined,
                            }))
                        }}
                        isAdding={modalOpen === AppModals.AddRecurringExpense}
                        presetValues={newRecurringExpenseRequest?.assignee != null ? newRecurringExpenseRequest : undefined}
                        onClose={() => {
                            setActiveStep(AddRecurringExpenseSteps.RecurrenceAndName)
                        }}
                    />
                )
            case AddRecurringExpenseSteps.InvoiceData:
                return (
                    <AddRecurringExpenseInvoiceData
                        onSave={(data: AddRecurringExpenseInvoiceDataRequest) => {
                            setNewRecurringExpenseRequest((prevState) => ({ ...prevState, ...data }))
                            setActiveStep(AddRecurringExpenseSteps.Review)
                        }}
                        onClose={() => {
                            setActiveStep(AddRecurringExpenseSteps.Details)
                        }}
                        presetValues={newRecurringExpenseRequest}
                    />
                )
            case AddRecurringExpenseSteps.Review:
                return (
                    <AddRecurringExpenseReviewStep
                        isLoading={isLoading}
                        onSave={() => {
                            if (newRecurringExpenseRequest) onSave(newRecurringExpenseRequest as AddRecurringExpenseRequest)
                        }}
                        onClose={() => {
                            if (editingItem?.shouldGenerateInvoice) {
                                setActiveStep(AddRecurringExpenseSteps.InvoiceData)
                            } else {
                                setActiveStep(AddRecurringExpenseSteps.Details)
                            }
                        }}
                        presetValues={newRecurringExpenseRequest}
                    />
                )
        }
    }, [newRecurringExpenseRequest, activeStep, isLoading, userData, onClose, onSave, editingItem])

    return (
        <Box display={"flex"} flexDirection={"column"} width={"100%"}>
            <Stepper activeStep={activeStep} alternativeLabel={!isMobile} orientation={isMobile ? "vertical" : "horizontal"}>
                {getSteps().map((step, index) => {
                    return (
                        <Step key={step.title} completed={index < activeStep}>
                            <StepLabel>{step.title}</StepLabel>
                            {isMobile && <StepContent>{renderActiveStep()}</StepContent>}
                        </Step>
                    )
                })}
            </Stepper>
            {!isMobile && renderActiveStep()}
        </Box>
    )
}
