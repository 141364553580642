import { array, boolean, number, object, string } from "yup"
import { DateTime } from "luxon"
import { uniq } from "lodash"

export const InviteTenantSchema = (isPropertyAvailable: boolean) =>
    object().shape({
        id: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .optional(),
        mails: array()
            .of(string().email("must_be_email").required("mail_required"))
            .required()
            .when("createTenantAccount", (createTenantAccount, schema) => {
                if (createTenantAccount[0]) {
                    return schema.test("Max one e-mail", "max_one_tenant", (value) => {
                        return uniq(value).length === 1
                    })
                }
                return schema
            }),
        createTenantAccount: boolean(),
        scheduleMaintenanceAtHalfPeriod: boolean().required(),
        tenantFirstName: string().when("createTenantAccount", (createTenantAccount, schema) => {
            if (createTenantAccount[0]) {
                return schema.required("tenant_first_name_required")
            }

            return schema
        }),
        tenantLastName: string().when("createTenantAccount", (createTenantAccount, schema) => {
            if (createTenantAccount[0]) {
                return schema.required("tenant_last_name_required")
            }
            return schema
        }),
        moveInDate: string()
            .required()
            .test("t", "date_before", (val: string | undefined, context) => {
                if (val == null || context.parent == null) return false
                const moveOutDate = DateTime.fromISO(context.parent.moveOutDate)
                const moveInDate = DateTime.fromISO(val)

                return moveInDate < moveOutDate
            }),
        moveOutDate: string()
            .required()
            .test("t", "date_before", (val: string | undefined, context) => {
                if (val == null || context.parent == null) return false
                const moveOutDate = DateTime.fromISO(val)
                const moveInDate = DateTime.fromISO(context.parent.moveInDate)

                return moveInDate < moveOutDate
            }),
        propertyId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required()
            .test("invalidProperty", "choose another property", function (value: number | undefined) {
                if (value == null) return false
                return isPropertyAvailable
            }),
        invitationLanguage: string().required(),
    })

export const NewInvitationSchema = () =>
    object().shape({
        email: string().email("must_be_email").required("mail_required"),
        rentingPeriodId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required(),
        invitationLanguage: string().required(),
    })
