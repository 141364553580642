import { Iconify, LoadingIcon, Page, useIsMobile, useLocales } from "rentzz"
import { DragDropContext, DropResult } from "@hello-pangea/dnd"
import { Fab, Stack } from "@mui/material"
import TaskSection from "../../sections/kanban/section/TaskSection"
import { useAllSectionsQueryFn } from "../../queries/kanban"
import { hideScroll } from "../../sections/kanban/utils"
import React, { useCallback } from "react"
import { AppModals, setModalOpen } from "../../redux/slices/App"
import { useDispatch, useSelector } from "../../redux/store"
import { updateTaskMutation } from "../../mutations/kanban"
import * as jsonpatch from "fast-json-patch"
import NoDataPage from "../../components/NoDataPage"
import { useNavigate } from "react-router-dom"
import { RoleName, useUserDataQueryFn } from "../../queries/userData"
import CustomRoleBlockedToolbar from "../../components/CustomRoleBlockedToolbar"

export default function OwnerTasks() {
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const navigate = useNavigate()
    const { mutateAsync: updateTask } = updateTaskMutation()
    const { data: allSections } = useAllSectionsQueryFn()
    const { modalOpen } = useSelector((state) => state.appState)
    const { data: user } = useUserDataQueryFn()

    const handleTaskDrop = useCallback(
        async (result: DropResult) => {
            if (user?.roles == null) return
            if (result.destination?.droppableId) {
                const operations: jsonpatch.Operation[] = [{ op: "replace", path: "/sectionId", value: result.destination?.droppableId }]
                await updateTask({
                    taskId: result.draggableId,
                    sectionId: result.source.droppableId,
                    newIndex: result.destination.index,
                    operations,
                })
            }
        },
        [updateTask],
    )

    if (allSections == null) return <LoadingIcon />
    if (allSections.length === 0)
        return (
            <NoDataPage
                icon={""}
                noDataText={"no_sections"}
                textButton={"go_to_sections"}
                onAddNewItem={user?.roles.roleName === RoleName.FREE ? undefined : () => navigate("/nomenclature/sections")}
            />
        )

    return (
        <Page title={translate("tasks")} px={2} paddingBottom={2}>
            {!isMobile && (
                <CustomRoleBlockedToolbar
                    onAddClick={() => dispatch(setModalOpen(AppModals.AddTask))}
                    buttonText={"add_task"}
                    blockedRoles={[RoleName.FREE]}
                />
            )}

            {isMobile && modalOpen == null && user?.roles.roleName !== RoleName.FREE && (
                <Fab
                    onClick={() => dispatch(setModalOpen(AppModals.AddTask))}
                    sx={{ position: "absolute", bottom: 10, right: 10 }}
                    color='primary'
                    aria-label='add'
                >
                    <Iconify icon={"mdi:plus"} width={25} height={25} />
                </Fab>
            )}

            <DragDropContext onDragEnd={handleTaskDrop}>
                <Stack
                    spacing={3}
                    direction='row'
                    alignItems='flex-start'
                    sx={{
                        p: 0.25,
                        height: 1,
                        overflowY: "hidden",
                        ...hideScroll.x,
                    }}
                >
                    {allSections.map((section) => (
                        <TaskSection key={section.taskSectionId} section={section} />
                    ))}
                </Stack>
            </DragDropContext>
        </Page>
    )
}
