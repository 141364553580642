import { useDispatch } from "../../../redux/store"
import { FormProvider, Iconify, LightTooltip, RHFAutocomplete, RHFSelect, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { useUserDataQueryFn } from "../../../queries/userData"
import { useCurrencyQueryFn } from "../../../queries/currency"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { AddRecurringExpenseInvoiceDataSchema } from "../../../validations/recurringexpense"
import { Box, Button, CircularProgress, InputAdornment, MenuItem, Typography } from "@mui/material"
import { AddRecurringExpenseRequest } from "../../../mutations/expenses"
import { useAllUserInvoicingCompanies, useCompanyClients, useCompanySeries, useCompanyVATs } from "../../../queries/invoicing-configuration"
import { allLangs } from "../../../config"
import { useTheme } from "@mui/material/styles"

export interface AddRecurringExpenseInvoiceDataRequest {
    invoiceLanguage: string
    invoiceCompany: string
    invoiceClient: number
    invoiceCurrencyId: number
    invoiceTVA: number
    invoiceProductName: string
    invoiceProductDescription: string
    invoicingSeries: string
    invoicingPeriodStart: number | null
    invoicingPeriodEnd: number | null
}
interface Props {
    onSave: (data: AddRecurringExpenseInvoiceDataRequest) => void
    presetValues: Partial<AddRecurringExpenseRequest> | undefined | null
    onClose: () => void
}
export default function AddRecurringExpenseInvoiceData({ onSave, presetValues, onClose }: Props) {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const [invoicingCompanyId, setInvoicingCompanyId] = useState<number | undefined>(undefined)
    const { data: user } = useUserDataQueryFn()
    const { data: allCurrencies } = useCurrencyQueryFn()
    const { data: allUserCompanies } = useAllUserInvoicingCompanies()
    const { data: allUserClients, isLoading: areAllUserClientsPending } = useCompanyClients(invoicingCompanyId)
    const { data: allUserVATs } = useCompanyVATs(invoicingCompanyId)
    const { data: allUserSeries } = useCompanySeries(invoicingCompanyId)

    const methods = useForm<AddRecurringExpenseInvoiceDataRequest>({
        resolver: yupResolver<AddRecurringExpenseInvoiceDataRequest>(AddRecurringExpenseInvoiceDataSchema),
        mode: "onChange",
        defaultValues: {
            invoiceCurrencyId: user?.currency.id,
            invoiceLanguage: allLangs.find((lang) => lang.value === user?.languageCode)?.value,
        },
    })
    const { reset, handleSubmit, watch } = methods

    useEffect(() => {
        const subscription = watch((v, { name }) => {
            if (name === "invoiceCompany") {
                setInvoicingCompanyId(Number(v.invoiceCompany))
            }
        })
        return () => subscription.unsubscribe()
    }, [watch, dispatch])

    useEffect(() => {
        if (presetValues) {
            reset({
                ...presetValues,
                invoiceCurrencyId: presetValues.invoiceCurrencyId,
            })
        }
    }, [presetValues])

    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
                <Box display='flex' flexDirection='column' gap={2} marginY={2}>
                    <Box display={"flex"} flexDirection={isMobile ? "column" : "row"} gap={2}>
                        <RHFSelect
                            name={"invoiceCompany"}
                            size='small'
                            variant={"outlined"}
                            label={translate("invoicing_company")}
                            disabled={allUserCompanies?.length === 0}
                            fullWidth
                            required
                        >
                            {allUserCompanies ? (
                                allUserCompanies.map((c) => (
                                    <MenuItem key={c.id} value={c.id} sx={{ color: theme.palette.grey.A700 }}>
                                        {c.name}
                                    </MenuItem>
                                ))
                            ) : (
                                <CircularProgress />
                            )}
                        </RHFSelect>
                        <RHFAutocomplete
                            name={"invoiceClient"}
                            textFieldProps={{ size: "small" }}
                            options={allUserClients?.map((p) => ({ id: p.id, label: p.name })) ?? []}
                            matchId
                            label={translate("invoicing_client")}
                            loading={areAllUserClientsPending}
                            autocompleteProps={{
                                size: "small",
                            }}
                            required
                            disabled={allUserCompanies?.length === 0}
                        />
                    </Box>
                    <Box display={"flex"} flexDirection={isMobile ? "column" : "row"} gap={2}>
                        <RHFSelect
                            name={"invoicingSeries"}
                            size='small'
                            variant={"outlined"}
                            label={translate("invoicing_series")}
                            disabled={allUserSeries == null || allUserSeries.length === 0}
                            fullWidth
                        >
                            {allUserSeries ? (
                                allUserSeries.map((c) => (
                                    <MenuItem key={c.id} value={c.id}>
                                        {c.name}
                                    </MenuItem>
                                ))
                            ) : (
                                <CircularProgress />
                            )}
                        </RHFSelect>
                        <RHFSelect
                            name={"invoiceTVA"}
                            label={translate("TVA")}
                            size='small'
                            disabled={allUserVATs == null || allUserVATs.length === 0}
                            fullWidth
                        >
                            {allUserVATs ? (
                                allUserVATs.map((c) => (
                                    <MenuItem key={c.id} value={c.id}>
                                        {c.name}
                                    </MenuItem>
                                ))
                            ) : (
                                <CircularProgress />
                            )}
                        </RHFSelect>
                    </Box>
                    <Box display={"flex"} flexDirection={isMobile ? "column" : "row"} gap={2}>
                        <RHFSelect
                            name={"invoiceLanguage"}
                            size='small'
                            variant={"outlined"}
                            label={translate("invoicing_language")}
                            fullWidth
                            required
                        >
                            {allLangs?.map((c) => (
                                <MenuItem key={c.value} value={c.value} sx={{ color: theme.palette.grey.A700 }}>
                                    {c.label}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                        <RHFSelect
                            name={"invoiceCurrencyId"}
                            size='small'
                            variant={"outlined"}
                            label={translate("invoicing_currency")}
                            fullWidth
                            required
                        >
                            {allCurrencies ? (
                                allCurrencies.map((c) => (
                                    <MenuItem key={c.id} value={c.id} sx={{ color: theme.palette.grey.A700 }}>
                                        {c.code}
                                    </MenuItem>
                                ))
                            ) : (
                                <CircularProgress />
                            )}
                        </RHFSelect>
                    </Box>
                    <Box display={"flex"} flexDirection={isMobile ? "column" : "row"} gap={2}>
                        <RHFTextField
                            name={"invoiceProductName"}
                            size='small'
                            variant={"outlined"}
                            label={translate("invoicing_product_name")}
                            fullWidth
                            required
                        />
                        <RHFTextField
                            name={"invoiceProductDescription"}
                            size='small'
                            variant={"outlined"}
                            label={translate("invoicing_product_description")}
                            fullWidth
                            required
                        />
                    </Box>
                    <Box display={"flex"} flexDirection={isMobile ? "column" : "row"} gap={2}>
                        <RHFTextField
                            name={"invoicingPeriodStart"}
                            label={translate("invoicingPeriodStart")}
                            placeholder={"1"}
                            size='small'
                            type={"number"}
                            required
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position={"end"}>
                                        <LightTooltip
                                            title={
                                                <Box display={"flex"} flexDirection={"column"}>
                                                    <Typography variant={"body2"} textAlign={"start"}>
                                                        {translate("invoicingPeriodTooltipInfo")}
                                                    </Typography>
                                                    <Typography variant={"body2"} textAlign={"start"}>
                                                        {translate("forExample")}
                                                    </Typography>
                                                    <Typography variant={"body2"} textAlign={"start"}>
                                                        {translate("invoicingPeriodTooltipExample-1")}
                                                    </Typography>
                                                    <Typography variant={"body2"} textAlign={"start"}>
                                                        {translate("invoicingPeriodTooltipExample-2")}
                                                    </Typography>
                                                    <Typography variant={"body2"} textAlign={"start"}>
                                                        {translate("invoicingPeriodTooltipExample-3")}
                                                    </Typography>
                                                    <Typography variant={"body2"} textAlign={"start"}>
                                                        {translate("invoicingPeriodTooltipExample-4")}
                                                    </Typography>
                                                </Box>
                                            }
                                            arrow
                                        >
                                            <Iconify icon={"mdi:information"} width={18} height={18} color={theme.palette.info.main} />
                                        </LightTooltip>
                                    </InputAdornment>
                                ),
                                inputProps: { min: 0, max: 31 },
                            }}
                        />

                        <RHFTextField
                            required
                            name={"invoicingPeriodEnd"}
                            label={translate("invoicingPeriodEnd")}
                            placeholder={"31"}
                            size='small'
                            type={"number"}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position={"end"}>
                                        <LightTooltip
                                            title={
                                                <Box display={"flex"} flexDirection={"column"}>
                                                    <Typography variant={"body2"} textAlign={"start"}>
                                                        {translate("invoicingPeriodTooltipInfo")}
                                                    </Typography>
                                                    <Typography variant={"body2"} textAlign={"start"}>
                                                        {translate("forExample")}
                                                    </Typography>
                                                    <Typography variant={"body2"} textAlign={"start"}>
                                                        {translate("invoicingPeriodTooltipExample-1")}
                                                    </Typography>
                                                    <Typography variant={"body2"} textAlign={"start"}>
                                                        {translate("invoicingPeriodTooltipExample-2")}
                                                    </Typography>
                                                    <Typography variant={"body2"} textAlign={"start"}>
                                                        {translate("invoicingPeriodTooltipExample-3")}
                                                    </Typography>
                                                    <Typography variant={"body2"} textAlign={"start"}>
                                                        {translate("invoicingPeriodTooltipExample-4")}
                                                    </Typography>
                                                </Box>
                                            }
                                            arrow
                                        >
                                            <Iconify icon={"mdi:information"} width={18} height={18} color={theme.palette.info.main} />
                                        </LightTooltip>
                                    </InputAdornment>
                                ),
                                inputProps: { min: 0, max: 31, type: "number" },
                            }}
                        />
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingX: 0,
                        paddingTop: 2,
                        gap: 2,
                        flexDirection: isMobile ? "column-reverse" : "row",
                    }}
                >
                    <Button color={"primary"} onClick={onClose}>
                        {translate("back")}
                    </Button>
                    <Button type='submit' variant='contained' color={"primary"}>
                        {translate("next")}
                    </Button>
                </Box>
            </FormProvider>
        </>
    )
}
