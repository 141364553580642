import { AppContext, IKanbanTask, Scrollbar, useLocales } from "rentzz"
import { Box, Stack, Typography } from "@mui/material"
import InputNameForm from "./InputNameForm"
import RentingPeriodInput from "./RentingPeriodInput"
import TaskDescriptionForm from "./TaskDescriptionForm"
import TaskDetailsFilesForm from "./TaskDetailsFilesForm"
import KanbanDetailsCommentList from "./TaskDetailsComment"
import React, { useMemo } from "react"
import { StyledLabel } from "../utils"
import { useAllSectionsQueryFn, useTaskCommentsQueryFn, useTenantTaskCommentsQueryFn } from "../../../queries/kanban"
import { useUserDataQueryFn, useUserPropertiesQuery } from "../../../queries/userData"
import { useSelector } from "../../../redux/store"

interface Props {
    task: IKanbanTask
}
export default function DetailsContent({ task }: Props) {
    const { translate } = useLocales()
    const { context, editingItem } = useSelector((state) => state.appState)
    const { data: user } = useUserDataQueryFn()
    const { data: ownerComments } = useTaskCommentsQueryFn(task.id, task.sectionId)
    const { data: tenantComments } = useTenantTaskCommentsQueryFn(editingItem?.rentingPeriodId, task.id)
    const { data: userProperties } = useUserPropertiesQuery()
    const { data: allSections } = useAllSectionsQueryFn()
    const property = useMemo(() => userProperties?.find((p) => p.id === task?.propertyId), [task, userProperties])

    const currentSection = useMemo(() => allSections?.find((s) => s.taskSectionId === task.sectionId), [allSections, task])

    const canEdit = useMemo(
        () => (context === AppContext.Owner ? currentSection?.canEdit && user?.roles != null : task.canEdit),
        [context, currentSection, task],
    )

    const comments = useMemo(() => (context === AppContext.Owner ? ownerComments : tenantComments), [context, ownerComments, tenantComments])

    return (
        <Scrollbar
            sx={{
                height: 1,
                "& .simplebar-content": {
                    height: 1,
                    display: "flex",
                    flexDirection: "column",
                },
            }}
        >
            <Box gap={3} display={"flex"} flexDirection={"column"} sx={{ px: 2.5, pt: 3 }}>
                <InputNameForm sectionId={task.sectionId} taskId={task.id} taskName={task.name} canEdit={canEdit} />
                {context === AppContext.Owner && (
                    <>
                        <Stack direction='row' alignItems='center'>
                            <StyledLabel>{translate("property")}</StyledLabel>
                            <Typography variant='caption'>{property?.label}</Typography>
                        </Stack>
                        <Stack direction='row'>
                            <StyledLabel>{translate("tenant-data.rentingPeriod")}</StyledLabel>
                            <RentingPeriodInput
                                rentingPeriodId={task.rentingPeriodId}
                                propertyId={task.propertyId}
                                taskId={task.id}
                                sectionId={task.sectionId}
                                canEdit={canEdit}
                            />
                        </Stack>
                    </>
                )}

                <Stack direction='row'>
                    <StyledLabel>{translate("task_description")}</StyledLabel>
                    <Box width={"100%"}>
                        <TaskDescriptionForm taskDescription={task.description} taskId={task.id} sectionId={task.sectionId} canEdit={canEdit} />
                    </Box>
                </Stack>

                <Stack direction='row'>
                    <StyledLabel sx={{ py: 0.5 }}>{translate("files")}</StyledLabel>
                    <Box width={"100%"}>
                        <TaskDetailsFilesForm taskId={task.id} canEdit={canEdit} />
                    </Box>
                </Stack>
            </Box>
            {comments && comments.length > 0 && (
                <Stack
                    spacing={3}
                    sx={{
                        py: 3,
                        px: 2.5,
                        bgcolor: "background.neutral",
                    }}
                >
                    {comments?.map((comment) => (
                        <KanbanDetailsCommentList
                            canEdit={
                                context === AppContext.Owner
                                    ? currentSection?.canEdit &&
                                      comment.createdBy.includes(`${user?.firstName} ${user?.lastName}`) &&
                                      user?.roles != null
                                    : false
                            }
                            sectionId={task.sectionId}
                            key={comment.id}
                            comment={comment}
                        />
                    ))}
                </Stack>
            )}
        </Scrollbar>
    )
}
