import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query"
import Api from "../../../api/Api"
import * as jsonpatch from "fast-json-patch"
import { DateTime } from "luxon"
import { ComplexQueryIDs, useQueryInvalidator } from "../../../hooks/useQueryInvalidator"
import { OwnerPropertyMeter } from "../../../redux/slices/AddProperty"
import { AddMeterRequest } from "../../../sections/propertyDetails/Meters/AddMeterForm"
import { setErrorMessage } from "../../../redux/slices/App"
import { AxiosError } from "axios"
import { useDispatch } from "../../../redux/store"
import { useIsMobile } from "rentzz"
import { BackendError } from "../../../sections/types/user"

export const addMeterMutation = () => {
    const queryClient = useQueryClient()
    const { invalidateQueries } = useQueryInvalidator()
    const dispatch = useDispatch()
    const isMobile = useIsMobile()

    return useMutation({
        mutationFn: (data: AddMeterRequest) => Api.addMeter({ data }),
        onMutate: (data) => {
            dispatch(setErrorMessage(undefined))
            return meterUpdater(queryClient, data, addMeterToQuery, data.propertyId)
        },
        onSuccess: async (data, { propertyId }) => {
            if (data) {
                await meterUpdater(queryClient, data, replaceTemporaryMeterInQuery, propertyId)
            }
        },
        onSettled: async (_, error) => {
            if (isMobile && error) {
                const errorResponse = error as AxiosError
                const errorMessage = errorResponse?.response?.data as BackendError
                dispatch(setErrorMessage(errorMessage.Message))
            }
            await invalidateQueries([ComplexQueryIDs.DashboardTable, ComplexQueryIDs.Meters, ComplexQueryIDs.Property])
        },
    })
}

export const deleteMeterMutation = () => {
    const queryClient = useQueryClient()
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ meterId }: { meterId: number; propertyId: number }) => Api.deleteMeter({ meterId }),
        onSettled: async (data, error, { propertyId, meterId }) => {
            await invalidateQueries([ComplexQueryIDs.DashboardTable, ComplexQueryIDs.Meters, ComplexQueryIDs.Property])
            await meterUpdater(queryClient, meterId, removeMeterFromQuery, propertyId)
        },
    })
}

export const deleteMeterValueMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ meterId, meterValueId }: { meterId: number; meterValueId: number }) => Api.deleteMeterValue({ meterId, meterValueId }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([
                    ComplexQueryIDs.DashboardTable,
                    ComplexQueryIDs.Meters,
                    ComplexQueryIDs.MetersValuesHistory,
                    ComplexQueryIDs.PendingMeterValues,
                ])
            }
        },
    })
}

export const updateMeterMutation = () => {
    const queryClient = useQueryClient()
    const { invalidateQueries } = useQueryInvalidator()
    const dispatch = useDispatch()
    const isMobile = useIsMobile()

    return useMutation({
        mutationFn: ({ operations, meterId }: { operations: jsonpatch.Operation[]; meterId: number; propertyId?: number }) =>
            Api.editMeter({ operations, meterId: meterId }),
        onMutate: ({ meterId, propertyId, operations }) => {
            dispatch(setErrorMessage(undefined))
            return meterUpdater(queryClient, meterId, updateMeter, propertyId, operations)
        },
        onSettled: async (data, error, _, context) => {
            await invalidateQueries([ComplexQueryIDs.DashboardTable])
            if (error) {
                queryClient.setQueryData([ComplexQueryIDs.Meters, { id: context?.propertyId }], context?.previousData)
                if (isMobile) {
                    const errorResponse = error as AxiosError
                    const errorMessage = errorResponse?.response?.data as BackendError
                    dispatch(setErrorMessage(errorMessage.Message))
                }
            }
        },
    })
}

async function meterUpdater(
    queryClient: QueryClient,
    newData: unknown,
    updater: (oldData: unknown, newData: unknown, operations?: jsonpatch.Operation[]) => unknown,
    propertyId?: number,
    operations?: jsonpatch.Operation[],
) {
    await queryClient.cancelQueries({ queryKey: [ComplexQueryIDs.Meters, { id: propertyId }] })

    const previousData = queryClient.getQueryData([ComplexQueryIDs.Meters, { id: propertyId }])

    queryClient.setQueryData([ComplexQueryIDs.Meters, { id: propertyId }], (oldData) => {
        return updater(oldData, newData, operations)
    })

    return { previousData, propertyId }
}

function addMeterToQuery(oldData: unknown, data: unknown) {
    const converted = (oldData ?? []) as OwnerPropertyMeter[]
    const convertedData = data as AddMeterRequest

    return [
        {
            ...convertedData,
            id: -1,
            unitId: Number(convertedData.unitId),
            isActive: true,
            lastModified: DateTime.now(),
        },
        ...converted,
    ]
}

function replaceTemporaryMeterInQuery(oldData: unknown, data: unknown) {
    const converted = (oldData ?? []) as OwnerPropertyMeter[]
    const convertedData = data as OwnerPropertyMeter

    return [
        {
            ...convertedData,
            unitId: Number(convertedData.unitId),
        },
        ...converted.filter((e) => e.id !== -1),
    ]
}

function removeMeterFromQuery(oldData: unknown, data: unknown) {
    const converted = (oldData ?? []) as OwnerPropertyMeter[]

    return [...converted.filter((e) => e.id !== data)]
}

export function updateMeter(oldData: unknown, meterId: unknown, operations?: jsonpatch.Operation[]) {
    const converted = (oldData ?? []) as OwnerPropertyMeter[]
    const ourObject = jsonpatch.deepClone(converted.find((t) => t.id === meterId))
    const newObject = jsonpatch.applyPatch(ourObject, operations ?? []).newDocument

    newObject.lastModified = DateTime.fromISO(newObject.lastModified)
    return [...converted.map((meter) => (meter.id === meterId ? newObject : meter))]
}
