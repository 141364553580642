import { Banks, UserBankAccount, useUserDataQueryFn } from "../../../queries/userData"
import { FormProvider, RHFAutocomplete, RHFSelect, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { addBankAccountMutation, addPropertyToBankAccountMutation, updateBankAccountMutation } from "../../../mutations/user"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useCallback, useEffect } from "react"
import * as jsonpatch from "fast-json-patch"
import { Box, Button, CircularProgress, MenuItem } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { AddBankAccountSchema } from "../../../validations/bankAccounts"
import { useCurrencyQueryFn } from "../../../queries/currency"
import { useSelector } from "../../../redux/store"
import { PermissionType, usePermissions } from "../../../hooks/usePermissions"

interface Props {
    onClose: () => void
    defaultValues?: UserBankAccount
    isFromProperties: boolean | undefined
}

export interface AddBankAccountRequest {
    holder: string
    iban: string
    bank: number
    currencyId: number
    properties?: number[]
}

export default function AddBankAccountForm({ onClose, defaultValues, isFromProperties }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { data: user } = useUserDataQueryFn()
    const { data: currencies } = useCurrencyQueryFn()
    const { editableProperties } = usePermissions(PermissionType.BankAccounts)
    const { mutateAsync: addBankAccount, isPending: isAddBankAccount } = addBankAccountMutation()
    const { mutateAsync: updateBankAccount, isPending: isUpdateBankAccount } = updateBankAccountMutation()
    const { mutateAsync: addProperties, isPending: isAddProperties } = addPropertyToBankAccountMutation()

    const methods = useForm<AddBankAccountRequest>({
        resolver: yupResolver(AddBankAccountSchema(isFromProperties)),
        mode: "onChange",
        defaultValues: {
            holder: "",
            bank: undefined,
            iban: "",
            currencyId: user?.currency.id,
            properties: currentPropertyId ? [currentPropertyId] : [],
        },
    })

    const { handleSubmit, reset } = methods

    useEffect(() => {
        if (defaultValues && !isFromProperties) {
            reset({
                ...defaultValues,
                properties: defaultValues.properties,
            })
        }
        if (defaultValues && isFromProperties) {
            reset({
                ...defaultValues,
                properties: [],
            })
        }
    }, [defaultValues, reset])

    const onSave = useCallback(
        async (data: AddBankAccountRequest) => {
            if (defaultValues && !isFromProperties) {
                const operations = jsonpatch.compare({ ...defaultValues }, { ...data })
                await updateBankAccount({
                    bankAccountId: defaultValues.id,
                    operations,
                })
            } else if (defaultValues && isFromProperties) {
                await addProperties({
                    bankAccountId: defaultValues.id,
                    propertyIds: data.properties ?? [],
                })
            } else {
                await addBankAccount(data)
            }

            onClose()
        },
        [addBankAccount, onClose, updateBankAccount, defaultValues, isFromProperties, addProperties],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
            <Box display={"flex"} gap={2} paddingTop={1} flexDirection={"column"}>
                {((!isFromProperties && defaultValues) || !defaultValues) && (
                    <>
                        <RHFTextField name='holder' label={translate("holder")} size='small' required />
                        <RHFSelect name={"bank"} label={translate("bank")} required>
                            {Object.keys(Banks)
                                .filter((key) => Number(key))
                                .map((bank) => (
                                    <MenuItem key={bank} value={bank}>
                                        {translate(`bank-name-${bank}`)}
                                    </MenuItem>
                                ))}
                        </RHFSelect>
                        <RHFTextField name='iban' label={translate("iban")} size='small' required />

                        <RHFSelect name={"currencyId"} label={translate("currency")} required>
                            {currencies ? (
                                currencies.map((currency) => (
                                    <MenuItem key={currency.id} value={currency.id}>
                                        {currency.code}
                                    </MenuItem>
                                ))
                            ) : (
                                <CircularProgress />
                            )}
                        </RHFSelect>
                    </>
                )}

                {(defaultValues == null || (defaultValues && isFromProperties)) && (
                    <RHFAutocomplete
                        disabled={!!currentPropertyId}
                        autocompleteProps={{
                            sx: {
                                width: "100%",
                                marginTop: isMobile && isFromProperties ? 1 : undefined,
                            },
                            noOptionsText: translate("no_value_found"),
                            limitTags: 3,
                        }}
                        matchId
                        multiple
                        name={"properties"}
                        options={editableProperties?.map((property) => ({ id: property.id, label: property.label })) ?? []}
                        label={translate("associate_properties")}
                        freeSolo={false}
                    />
                )}

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingX: 0,
                        paddingTop: 2,
                        gap: 2,
                        flexDirection: isMobile ? "column-reverse" : "row",
                    }}
                >
                    <Button
                        fullWidth={isMobile}
                        color={"primary"}
                        onClick={onClose}
                        disabled={isAddBankAccount || isUpdateBankAccount || isAddProperties}
                    >
                        {translate("cancel")}
                    </Button>
                    <LoadingButton
                        fullWidth={isMobile}
                        color={"primary"}
                        type='submit'
                        variant='contained'
                        disabled={isAddBankAccount || isUpdateBankAccount || isAddProperties}
                        loading={isAddBankAccount || isUpdateBankAccount || isAddProperties}
                    >
                        {translate("submit")}
                    </LoadingButton>
                </Box>
            </Box>
        </FormProvider>
    )
}
