import { FormProvider, RHFTextField, RHFUpload, useIsMobile, useLocales } from "rentzz"
import { Alert, Box, Button } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import React, { useCallback } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { MessageSchema } from "../../../validations/support"
import { AddMessageRequest } from "./types"
import { useSelector } from "../../../redux/store"

interface AddExpenseFormProps {
    onSave: (data: AddMessageRequest) => Promise<void>
    isSendingMessageLoading: boolean
    onClose: () => void
}

export default function SendMessageForm({ onSave, isSendingMessageLoading, onClose }: AddExpenseFormProps) {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { editingItem, errorMessage } = useSelector((state) => state.appState)

    const methods = useForm<AddMessageRequest>({
        resolver: yupResolver<AddMessageRequest>(MessageSchema),
        mode: "onChange",
        defaultValues: {
            files: [],
            executionId: editingItem?.executionId ?? undefined,
        },
    })

    const { reset, handleSubmit } = methods

    const onSubmit = useCallback(
        async (data: AddMessageRequest) => {
            await onSave(data)
            onClose()
            reset()
        },
        [onSave, onClose, reset],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display='flex' flexDirection='column' gap={2} marginY={2}>
                {editingItem?.executionId && (
                    <RHFTextField name='executionId' label={translate("support.executionId")} size='small' required disabled />
                )}
                <RHFTextField
                    multiline
                    maxRows={10}
                    minRows={5}
                    name='message'
                    placeholder={translate("support.message_placeholder")}
                    label={translate("support.message")}
                    size='small'
                    required
                />
                <RHFUpload name={"files"} multiple maxSize={20000000} icon={"mdi:file-document-plus-outline"} />
            </Box>
            {errorMessage && (
                <Box marginTop={2}>
                    <Alert severity={"error"}>{errorMessage}</Alert>
                </Box>
            )}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingX: 0,
                    paddingTop: 2,
                    gap: 2,
                    flexDirection: isMobile ? "column-reverse" : "row",
                }}
            >
                <Button color={"primary"} onClick={onClose} disabled={isSendingMessageLoading}>
                    {translate("cancel")}
                </Button>
                <LoadingButton
                    type='submit'
                    variant='contained'
                    color={"primary"}
                    loading={isSendingMessageLoading}
                    disabled={isSendingMessageLoading}
                >
                    {translate("submit")}
                </LoadingButton>
            </Box>
        </FormProvider>
    )
}
