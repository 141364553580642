import React, { useState } from "react"
import { Iconify, MotionContainer, useLocales } from "rentzz"
import { Box, Button, Divider } from "@mui/material"
import OwnerNotificationsForm from "./OwnerNotificationsForm"

interface OwnerMobileNotificationsProps {
    isDisabled: boolean
}

export default function OwnerMobileNotifications({ isDisabled }: OwnerMobileNotificationsProps) {
    const [expanded, setExpanded] = useState(false)
    const { translate } = useLocales()

    return (
        <MotionContainer action animate={expanded} sx={{ position: "relative" }}>
            <Box
                display='flex'
                height={expanded ? undefined : 550}
                flexDirection='column'
                paddingLeft={5}
                paddingY={3}
                overflow={"hidden"}
                sx={{ WebkitBoxOrient: "vertical", WebkitLineClamp: 5 }}
            >
                <OwnerNotificationsForm expanded={expanded} isDisabled={isDisabled} />
            </Box>
            <Divider />

            <Box sx={{ p: 2, textAlign: "right" }}>
                <Button
                    size='small'
                    color='primary'
                    onClick={() => setExpanded((prevExpanded) => !prevExpanded)}
                    endIcon={<Iconify icon={expanded ? "" : "mdi:chevron-down"} />}
                >
                    {translate(expanded ? "close" : "view_all")}
                </Button>
            </Box>
        </MotionContainer>
    )
}
