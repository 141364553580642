import React from "react"
import NomenclatureGuard from "./nomenclature/NomenclatureGuard"
import BlockUserGuard from "./activeUsers/BlockUserGuard"
import MobileNotificationSheet from "./MobileNotificationSheet"
import DeleteGroupGuard from "../groups/DeleteGroupGuard"
import AddGroupGuard from "../groups/AddGroupGuard"
import DeleteNotificationGuard from "./notifications.ts/DeleteNotificationGuard"
import NotificationPreviewGuard from "./notifications.ts/NotificationPreviewGuard"
import DeleteGeneralDocumentGuard from "./generalDocuments/DeleteGeneralDocumentGuard"
import GeneralDocumentPreview from "./generalDocuments/GeneralDocumentPreview"
import GeneralDocumentDetailsSheet from "./generalDocuments/GeneralDocumentDetailsSheet"
import GeneralDocumentsLearnMoreGuard from "./generalDocuments/GeneralDocumentsLearnMoreGuard"
import UploadGeneralDocument from "../../../sections/documents-templates/generalDocuments/UploadGeneralDocument"
import ImageViewer from "./ImageViewer"
import RentingPeriodStatusGuard from "./RentingPeriodStatusGuard"
import EditPropertyPercentage from "../groups/EditPropertyPercentage"
import GroupPropertyDetails from "../../../sections/groups/groupSection/GroupPropertyDetails"
import SendSMSCodeGuard from "./activeUsers/SendSMSCodeGuard"
import EditUserPhone from "./activeUsers/EditUserPhone"
import ActiveUserDetailsSheet from "./activeUsers/ActiveUserDetailsSheet"

export default function UserGuard() {
    return (
        <>
            <NomenclatureGuard />
            <BlockUserGuard />
            <SendSMSCodeGuard />
            <EditUserPhone />
            <ActiveUserDetailsSheet />
            <MobileNotificationSheet />

            <DeleteGroupGuard />
            <AddGroupGuard />
            <EditPropertyPercentage />
            <GroupPropertyDetails />

            <DeleteNotificationGuard />
            <NotificationPreviewGuard />

            <DeleteGeneralDocumentGuard />
            <GeneralDocumentPreview />
            <GeneralDocumentDetailsSheet />
            <GeneralDocumentsLearnMoreGuard />
            <UploadGeneralDocument />

            <ImageViewer />
            <RentingPeriodStatusGuard />
        </>
    )
}
