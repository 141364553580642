import { ProviderStatus } from "../../../redux/slices/AddProperty"

export const getProviderStatusColor = (status: ProviderStatus | undefined) => {
    switch (status) {
        case ProviderStatus.Active:
            return "success"
        case ProviderStatus.Pending:
            return "warning"
        case ProviderStatus.Rejected:
            return "error"
    }
}
