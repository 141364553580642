import React from "react"
import { Box, Typography } from "@mui/material"
import { Iconify, Label, LabelColor } from "rentzz"
import { MOBILE_ICON_SIZE } from "../../utils/helpers"

export const renderActionSheetInfo = (
    icon: string,
    text: string | undefined,
    iconColor: string,
    textColor: string,
    isLabel?: boolean,
    secondaryText?: string,
    labelColor?: LabelColor,
) => {
    return (
        <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"} padding={1}>
            <Iconify icon={icon} sx={{ color: iconColor, fontSize: MOBILE_ICON_SIZE }} minWidth={22} />
            {secondaryText && (
                <Typography fontSize={13} color={textColor} maxWidth={250}>
                    {text}
                </Typography>
            )}
            {isLabel ? (
                <Label color={labelColor} style={{ textTransform: "none" }}>
                    {secondaryText || text}
                </Label>
            ) : (
                <Typography fontSize={13} color={textColor}>
                    {text}
                </Typography>
            )}
        </Box>
    )
}
