import { Box, CircularProgress } from "@mui/material"
import ReadingCustomCard from "../MetersHistory/ReadingCustomCard"
import * as React from "react"
import { useCallback, useEffect, useMemo, useRef } from "react"
import { useInfiniteMeterValuesHistoryQueryFn, useMetersQueryFn } from "../../../../queries/meters"
import { getUnitFromId, useUnitsQueryFn } from "../../../../queries/units"
import { useIntl } from "react-intl"
import { OwnerPropertyMeter, TenantPropertyMeter } from "../../../../redux/slices/AddProperty"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { DateTime } from "luxon"
import { useDispatch, useSelector } from "../../../../redux/store"
import { AppContext, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import { MOBILE_ICON_SIZE } from "../../../../utils/helpers"
import { ExportType } from "../../../../utils/actions"
import FileDownloadIcon from "@mui/icons-material/FileDownload"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import { useTenantRentingPeriodsQuery } from "../../../../queries/userData"

interface Props {
    onClose: () => void
}

export default function MobileMeterValuesList({ onClose }: Props) {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const intl = useIntl()
    const ref = useRef<HTMLDivElement>(null)
    const { modalOpen, context, currentRentingPeriodId, editingItem } = useSelector((state) => state.appState)
    const { data: meters } = useMetersQueryFn(editingItem?.tableId ? editingItem?.propertyId : undefined)
    const { data: unitData } = useUnitsQueryFn()
    const { data: readingsList, isLoading: areReadingLoading, hasNextPage, fetchNextPage } = useInfiniteMeterValuesHistoryQueryFn()
    const { data: tenantRentingPeriods } = useTenantRentingPeriodsQuery()

    const currentMeter = useMemo(
        () =>
            (meters as ((TenantPropertyMeter & { canWrite: boolean }) | (OwnerPropertyMeter & { canWrite: boolean }))[])?.find(
                (meter) => meter?.id === editingItem?.id,
            ),
        [meters, editingItem],
    )

    const options = useMemo((): CustomSheetOption[] => {
        if (currentMeter == null) return []
        const arrayOfReadings = readingsList?.pages.map((r) => r.items)[0]

        return [
            {
                icon: <ModeEditIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "edit_last_reading",
                hidden: context === AppContext.Owner ? !currentMeter.canWrite : arrayOfReadings && !arrayOfReadings[0].canEdit,
                onClick: () => {
                    if (arrayOfReadings == null) return
                    dispatch(setModalOpen(AppModals.EditReading))
                    dispatch(setEditingItem({ ...editingItem, readingId: arrayOfReadings[0].id }))
                },
            },
            {
                icon: <FileDownloadIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "export",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.ExportDetailsSheet))
                    dispatch(setEditingItem({ ...editingItem, type: ExportType.Reading }))
                },
            },
            {
                icon: <AddCircleOutlineIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "add_reading",
                hidden:
                    context === AppContext.Owner
                        ? !currentMeter.canWrite
                        : (tenantRentingPeriods?.find((r) => r.rentingPeriodId === currentRentingPeriodId)?.to ?? DateTime.now()) < DateTime.now(),
                onClick: () => {
                    dispatch(setModalOpen(AppModals.AddNewIndex))
                },
            },
        ]
    }, [currentMeter, dispatch, readingsList, context, tenantRentingPeriods, currentRentingPeriodId])

    const meterHeaderColor = useMemo(() => {
        if (modalOpen === AppModals.AddNewIndex && editingItem == undefined) return theme.palette.primary.main
        return currentMeter?.lastModified?.month === DateTime.now().month ? theme.palette.success.main : theme.palette.warning.main
    }, [currentMeter, modalOpen, editingItem])

    const getValue = useCallback(
        (value: number) => {
            if (value === -1) return "-"
            if (currentMeter == null) return ""

            return `${intl.formatNumber(value as number, {
                style: "decimal",
                useGrouping: false,
            })} ${getUnitFromId(unitData, currentMeter?.unitId).code}`
        },
        [unitData, intl, currentMeter],
    )

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting && hasNextPage) fetchNextPage()
            }),
        [hasNextPage],
    )

    useEffect(() => {
        if (ref?.current) {
            observer.observe(ref.current)
        }
        return () => {
            observer.disconnect()
        }
    }, [ref, observer])

    return (
        <DetailsSheet
            isOpen={isMobile && modalOpen === AppModals.MeterValuesHistory}
            onClose={onClose}
            isLoading={currentMeter == null}
            title={`${translate("readings")} ${currentMeter?.name}`}
            options={options}
            headerColor={meterHeaderColor}
        >
            {areReadingLoading ? (
                <Box display={"flex"} justifyContent={"center"} height={300}>
                    <CircularProgress size={"2rem"} sx={{ alignSelf: "center" }} />
                </Box>
            ) : (
                <Box sx={{ overflowY: "auto", maxHeight: 500 }}>
                    <Box gap={1} display={"flex"} flexDirection={"column"} px={1} py={2}>
                        {readingsList?.pages.map((group, i) => (
                            <React.Fragment key={i}>
                                {group.items.map((reading) => (
                                    <ReadingCustomCard
                                        getValue={getValue}
                                        key={reading.id}
                                        reading={reading}
                                        isFirstReading={group.items[group.items.length - 1].id === reading.id}
                                    />
                                ))}
                            </React.Fragment>
                        ))}
                        {hasNextPage && (
                            <Box ref={ref} display={"flex"} justifyContent={"center"} paddingTop={2}>
                                <CircularProgress size={"2rem"} sx={{ alignSelf: "center" }} />
                            </Box>
                        )}
                    </Box>
                </Box>
            )}
        </DetailsSheet>
    )
}
