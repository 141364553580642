import { Box, MenuItem, Paper, Select, Switch, Typography, useTheme } from "@mui/material"
import { Furniture, TVType, useIsMobile, useLocales } from "rentzz"
import { usePropertyDetailsQueryFn } from "../../../../queries"
import { updatePropertyDetailsMutation } from "../../../../mutations"
import { PermissionType, usePermissions } from "../../../../hooks/usePermissions"

const Features = () => {
    const { translate } = useLocales()
    const { data } = usePropertyDetailsQueryFn()
    const { mutateAsync } = updatePropertyDetailsMutation()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const { isPropertyEditable } = usePermissions(PermissionType.Properties)

    const handleOnClick = async (path: string, value: string) => {
        await mutateAsync({ operations: [{ path: `/${path}`, value, op: "replace" }], city: data?.city, county: data?.county })
    }

    const switchProperties = ["hasHeater", "hasAC", "hasDishwasher", "isDetached"]

    return (
        <Paper elevation={6}>
            <Box display='flex' flexDirection='column' height={isMobile ? 370 : 350} gap={1} sx={{ padding: 3 }} justifyContent={"center"}>
                {switchProperties.map((p) => (
                    <Box key={p} display='flex' flexDirection='row' alignItems={"center"}>
                        <Box flex={1}>
                            <Typography
                                letterSpacing={0.5}
                                textTransform='uppercase'
                                fontWeight={800}
                                fontSize={13}
                                color={theme.palette.primary.dark}
                            >
                                {translate(p)}
                            </Typography>
                        </Box>
                        <Box display='flex' flex={1} justifyContent='flex-end'>
                            <Switch
                                disabled={isPropertyEditable ?? false ? undefined : true}
                                /* eslint-disable-next-line */
                                /* @ts-ignore */
                                checked={data[p] ?? false}
                                /* eslint-disable-next-line */
                                /* @ts-ignore */
                                onChange={(e, checked) => handleOnClick(p, checked)}
                            />
                        </Box>
                    </Box>
                ))}
                <Box display='flex' flexDirection='row' alignItems='center'>
                    <Box flex={1}>
                        <Typography letterSpacing={0.5} textTransform='uppercase' fontWeight={800} fontSize={13} color={theme.palette.primary.dark}>
                            {translate("furniture")}
                        </Typography>
                    </Box>
                    <Box display='flex' flex={1} justifyContent='flex-end'>
                        <Select
                            value={(data?.furniture ?? Furniture.NONE).toString()}
                            size='small'
                            onChange={(e) => handleOnClick("furniture", e.target.value)}
                            sx={{ width: 110 }}
                            disabled={!isPropertyEditable}
                        >
                            <MenuItem value={Furniture.NONE.toString()} style={{ textTransform: "none" }}>
                                {translate("furniture-0")}{" "}
                            </MenuItem>
                            <MenuItem value={Furniture.SUMAR.toString()} style={{ textTransform: "none" }}>
                                {translate("furniture-1")}
                            </MenuItem>
                            <MenuItem value={Furniture.FULL.toString()} style={{ textTransform: "none" }}>
                                {translate("furniture-2")}
                            </MenuItem>
                        </Select>
                    </Box>
                </Box>
                <Box display='flex' flexDirection='row' alignItems='center'>
                    <Box flex={1}>
                        <Typography letterSpacing={0.5} textTransform='uppercase' fontWeight={800} fontSize={13} color={theme.palette.primary.dark}>
                            {translate("TV")}
                        </Typography>
                    </Box>
                    <Box display='flex' flex={1} justifyContent='flex-end'>
                        <Select
                            value={(data?.tvType ?? TVType.NONE).toString()}
                            size='small'
                            onChange={(e) => handleOnClick("tvType", e.target.value)}
                            sx={{ width: 110 }}
                            disabled={!isPropertyEditable}
                        >
                            <MenuItem value={TVType.NONE.toString()} style={{ textTransform: "none" }}>
                                {translate("tvType-0")}
                            </MenuItem>
                            <MenuItem value={TVType.REGULAR.toString()} style={{ textTransform: "none" }}>
                                {translate("tvType-1")}
                            </MenuItem>
                            <MenuItem value={TVType.SMART.toString()} style={{ textTransform: "none" }}>
                                {translate("tvType-2")}
                            </MenuItem>
                        </Select>
                    </Box>
                </Box>
            </Box>
        </Paper>
    )
}

export default Features
