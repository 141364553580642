import React, { useEffect, useState } from "react"
import { FormProvider, RHFAutocomplete, RHFSelect, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Alert, Box, Button, InputAdornment, MenuItem, Typography } from "@mui/material"
import { availableStories } from "../../../config"
import { useDispatch, useSelector } from "../../../redux/store"
import { AddPropertyPage, PropertyDetails as PropertyDetailsRequest, setActiveStep, setPropertyDetails } from "../../../redux/slices/AddProperty"
import { useCitiesQueryFn, useCountiesQueryFn, usePropertyDetailsQueryFn } from "../../../queries"
import { PropertyDetailsSchema } from "../../../validations/propertyDetails"
import { updatePropertyDetailsMutation } from "../../../mutations"
import * as jsonpatch from "fast-json-patch"
import { LoadingButton } from "@mui/lab"

interface PropertyDetailsProps {
    defaultValues?: any
    isEditDialogOpen?: boolean
    closeDialog?: () => void
}

const PropertyDetails = ({ defaultValues, isEditDialogOpen, closeDialog }: PropertyDetailsProps) => {
    const { translate } = useLocales()
    const { data: propertyDetails } = usePropertyDetailsQueryFn()
    const { data: allCounties, isFetching: allCountiesFetching } = useCountiesQueryFn()
    const [county, setCounty] = useState<string | undefined>(undefined)
    const { data: allCities, isFetching: allCitiesFetching } = useCitiesQueryFn(allCounties?.find((c) => c.name === county)?.countyId)
    const [hasCountyAndCity, setHasCountyAndCity] = useState(false)
    const dispatch = useDispatch()
    const [areInputsEmpty, setAreInputsEmpty] = useState<boolean>(true)
    const { mutateAsync: editProperty, isPending: isEditPropertyLoading } = updatePropertyDetailsMutation()
    const isMobile = useIsMobile()
    const { errorMessage } = useSelector((state) => state.appState)

    const methods = useForm<PropertyDetailsRequest>({
        resolver: yupResolver<PropertyDetailsRequest>(PropertyDetailsSchema(county === "Bucuresti")),
        mode: "onBlur",
        defaultValues: defaultValues,
    })

    const { reset, resetField, handleSubmit, watch } = methods

    useEffect(() => {
        const subscription = watch((v, { name }) => {
            if (name === "county") {
                setCounty(allCounties?.find((c) => c.name === v.county)?.name ?? "")
                resetField("city")
                resetField("street")
                resetField("streetNumber")
                resetField("buildingNumber")
                resetField("stair")
                resetField("floor")
                resetField("apartment")
            }
            setHasCountyAndCity(
                (v.county != null && v?.county.length > 0 && v.city != null && v.city.length > 0) ||
                    (defaultValues && (defaultValues.county?.length ?? 0) > 0 && (defaultValues.city?.length ?? 0) > 0),
            )
            setAreInputsEmpty(v.county === "" || v.county == null)
        })

        return () => subscription.unsubscribe()
    }, [watch, allCounties])

    useEffect(() => {
        reset(
            defaultValues
                ? {
                      ...defaultValues,
                      city: defaultValues.city,
                      county: defaultValues.county,
                      street: defaultValues.street,
                      streetNumber: defaultValues.streetNumber,
                      buildingNumber: defaultValues.buildingNumber,
                      stair: defaultValues.stair,
                      floor: defaultValues.floor,
                      apartment: defaultValues.apartment,
                      rooms: defaultValues.rooms,
                      year: defaultValues.year,
                      size: defaultValues.size,
                  }
                : {},
        )
        setCounty(defaultValues?.county ?? "")
        setAreInputsEmpty(defaultValues?.county === "" || defaultValues?.county == null)
    }, [defaultValues])

    const onSubmit = async (data: PropertyDetailsRequest) => {
        try {
            if (defaultValues == null) {
                dispatch(
                    setPropertyDetails({
                        ...data,
                        county: allCounties?.find((c) => c.name === data.county)?.countyId.toString() ?? "0",
                        city: allCities?.find((c) => c.name === data.city)?.cityId.toString() ?? "0",
                    }),
                )
            } else if (propertyDetails) {
                await editProperty({
                    operations: jsonpatch
                        .compare(propertyDetails, {
                            ...data,
                            // backend accepts countyId and cityId, not the names
                            countyId: allCounties?.find((c) => c.name === data.county)?.countyId ?? 0,
                            cityId: allCities?.find((c) => c.name === data.city)?.cityId ?? 0,
                        })
                        // remove operations on city and county as backend doesn't recognize them
                        .filter((o) => ["/city", "/county"].indexOf(o.path) === -1),
                    // send city and county for optimistic rendering
                    city: data.city,
                    county: data.county,
                })
            }
            closeDialog?.()
        } catch (error) {
            reset()
        }
    }

    const handleOnNextClick = () => {
        if (areInputsEmpty) dispatch(setActiveStep(AddPropertyPage.Location))
    }

    const getNextText = () => {
        if (areInputsEmpty && !defaultValues) return translate("skip")
        if (isEditDialogOpen) return translate("save")
        return translate("next")
    }

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display='flex' flexDirection='column' gap={2} marginTop={3}>
                <Box display='flex' flexDirection={isMobile ? "column" : "row"} gap={1}>
                    <RHFAutocomplete
                        name={"county"}
                        textFieldProps={{ size: "small" }}
                        options={allCounties?.map((c) => c.name) ?? []}
                        label={translate("county")}
                        loading={allCountiesFetching}
                        freeSolo={false}
                        autocompleteProps={{ size: "small", noOptionsText: translate("no_value_found") }}
                    />

                    <RHFAutocomplete
                        name={"city"}
                        options={allCities?.map((c) => c.name) ?? []}
                        loading={allCitiesFetching}
                        label={county === "Bucuresti" ? translate("sector") : translate("city")}
                        freeSolo={false}
                        autocompleteProps={{
                            disabled: allCities?.length === 0,
                            size: "small",
                            noOptionsText: translate("no_value_found"),
                        }}
                        textFieldProps={{ disabled: allCities?.length === 0, size: "small" }}
                    />
                </Box>
                <Box display='flex' flexDirection={isMobile ? "column" : "row"} gap={1}>
                    <RHFTextField
                        name='street'
                        label={!hasCountyAndCity ? "" : translate("street")}
                        disabled={!hasCountyAndCity}
                        placeholder={translate("street")}
                    />
                    <RHFTextField
                        name='streetNumber'
                        disabled={!hasCountyAndCity}
                        label={!hasCountyAndCity ? "" : translate("number")}
                        placeholder={translate("number")}
                    />
                </Box>
                <Box display='flex' flexDirection={isMobile ? "column" : "row"} gap={1}>
                    <RHFTextField
                        name='buildingNumber'
                        disabled={!hasCountyAndCity}
                        label={!hasCountyAndCity ? "" : translate("buildingNumber")}
                        placeholder={translate("buildingNumber")}
                    />
                    <RHFTextField
                        name='stair'
                        disabled={!hasCountyAndCity}
                        label={!hasCountyAndCity ? "" : translate("stair")}
                        placeholder={translate("stair")}
                    />
                </Box>

                <Box display='flex' flexDirection={isMobile ? "column" : "row"} gap={1}>
                    <RHFSelect
                        name='floor'
                        label={translate("floor")}
                        placeholder={translate("floor")}
                        disabled={!hasCountyAndCity}
                        sx={{ textAlign: "start" }}
                        size={"small"}
                    >
                        {availableStories.map((option) => (
                            <MenuItem key={option.value} value={option.value} style={{ textTransform: "none" }}>
                                {translate(option.value === 0 ? "ground" : "floor_with_number", { floor: option.value })}
                            </MenuItem>
                        ))}
                    </RHFSelect>
                    <RHFTextField
                        name='apartment'
                        label={!hasCountyAndCity ? "" : translate("apartment")}
                        placeholder={translate("apartment")}
                        disabled={!hasCountyAndCity}
                    />
                </Box>

                <Box display='flex' flexDirection={isMobile ? "column" : "row"} gap={1}>
                    <RHFTextField
                        name='rooms'
                        label={!hasCountyAndCity ? "" : translate("rooms")}
                        placeholder={translate("rooms")}
                        disabled={!hasCountyAndCity}
                    />
                    <RHFTextField
                        name='size'
                        label={!hasCountyAndCity ? "" : translate("size")}
                        placeholder={translate("size")}
                        disabled={!hasCountyAndCity}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position={"end"}>
                                    <Typography>
                                        m<sup>2</sup>
                                    </Typography>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Box display='flex' flexDirection='row' gap={1} width={isMobile ? "100%" : "50%"} paddingRight={isMobile ? 0 : 1}>
                    <RHFTextField
                        name='year'
                        label={!hasCountyAndCity ? "" : translate("year")}
                        placeholder={translate("year")}
                        disabled={!hasCountyAndCity}
                    />
                </Box>
                {errorMessage && (
                    <Box marginTop={2}>
                        <Alert severity={"error"}>{errorMessage}</Alert>
                    </Box>
                )}
                <Box display='flex' flexDirection={isMobile ? "column-reverse" : "row"} justifyContent='flex-end' gap={2}>
                    <Button
                        color='primary'
                        disabled={isEditPropertyLoading}
                        onClick={() => (isEditDialogOpen ? closeDialog?.() : dispatch(setActiveStep(0)))}
                        sx={{ marginRight: 1 }}
                    >
                        {translate(isEditDialogOpen ? "cancel" : "previous")}
                    </Button>

                    <LoadingButton
                        loading={isEditPropertyLoading}
                        type={areInputsEmpty ? undefined : "submit"}
                        variant='contained'
                        onClick={handleOnNextClick}
                        color={"primary"}
                    >
                        {getNextText()}
                    </LoadingButton>
                </Box>
            </Box>
        </FormProvider>
    )
}

export default PropertyDetails
