import { FormProvider, Iconify, LightTooltip, RHFCheckbox, RHFDayPicker, RHFUnitInput, useIsMobile, useLocales } from "rentzz"
import { Box, Button, Typography } from "@mui/material"
import * as React from "react"
import { useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { RentDetailsSchema } from "../../../../validations/paymentPeriod"
import { useUserBankAccountsQuery, useUserDataQueryFn } from "../../../../queries/userData"
import { useCurrencyQueryFn } from "../../../../queries/currency"
import { RentDetails } from "../../../types/AddTenant"
import { DESKTOP_ICON_SIZE } from "../../../../utils/helpers"
import { useTheme } from "@mui/material/styles"
import { useSelector } from "../../../../redux/store"

interface PaymentProps {
    onBack: () => void
    onNext: (rent: RentDetails) => void
    defaultValues?: RentDetails
    hasEmails: boolean
}

export default function Payment({ onNext, onBack, defaultValues, hasEmails }: PaymentProps) {
    const theme = useTheme()
    const { translate } = useLocales()
    const { data: user } = useUserDataQueryFn()
    const { data: currencies } = useCurrencyQueryFn()
    const isMobile = useIsMobile()
    const { editingItem } = useSelector((state) => state.appState)
    const { data: bankAccounts } = useUserBankAccountsQuery(editingItem?.propertyId)

    const methods = useForm<RentDetails>({
        resolver: yupResolver<RentDetails>(RentDetailsSchema),
        mode: "onChange",
    })
    const { handleSubmit, trigger, watch, reset } = methods

    useEffect(() => {
        reset(
            defaultValues ?? {
                paymentPeriod: [],
                currencyId: user?.currency.id,
                securityDepositCurrencyId: user?.currency.id,
                isRentExpenseAutomatic: false,
                addIncomeOnlyWithAssignedExpense: false,
                acceptPartialPayments: false,
            },
        )
    }, [reset, bankAccounts])

    useEffect(() => {
        const subscription = watch(() => {
            trigger("paymentPeriod")
        })
        return () => subscription.unsubscribe()
    }, [watch])

    const onSubmit = useCallback(
        (data: RentDetails) => {
            onNext(data)
        },
        [onNext],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display='flex' width='100%' marginTop={4} justifyContent='space-around' flexDirection={"column"} alignItems={"center"}>
                <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} marginBottom={2} width={"100%"}>
                    <RHFUnitInput
                        unitName={"currencyId"}
                        unitType={"currency"}
                        name='value'
                        type='number'
                        size='small'
                        data={currencies ?? []}
                        label={translate("tenant-data.price")}
                        required
                        fullWidth={isMobile}
                    />
                    <RHFUnitInput
                        data={currencies ?? []}
                        unitName={"securityDepositCurrencyId"}
                        unitType={"currency"}
                        name='securityDeposit'
                        type='number'
                        size='small'
                        label={translate("tenant-data.securityDeposit")}
                        fullWidth={isMobile}
                    />
                </Box>

                <Box display='flex' gap={2} flexDirection={"column"} width={"100%"}>
                    <Box display={"flex"} flexDirection={"column"}>
                        <Box display={"flex"} alignItems={"center"}>
                            <RHFCheckbox name={"isRentExpenseAutomatic"} label={translate("automatic_expense")} />
                            <LightTooltip title={translate("automatic_expense_tooltip")} arrow>
                                <Iconify icon={"mdi:information"} color={theme.palette.info.main} width={DESKTOP_ICON_SIZE} />
                            </LightTooltip>
                        </Box>
                        <LightTooltip title={!hasEmails ? translate("disabled_when_no_mails") : ""} arrow>
                            <Box display={"flex"} alignItems={"center"}>
                                <RHFCheckbox
                                    disabled={!hasEmails}
                                    name={"addIncomeOnlyWithAssignedExpense"}
                                    label={translate("tenant_add_income_only_with_expense")}
                                />
                                <LightTooltip title={translate("tenant_add_income_only_with_expense_tooltip")} arrow>
                                    <Iconify icon={"mdi:information"} color={theme.palette.info.main} width={DESKTOP_ICON_SIZE} />
                                </LightTooltip>
                            </Box>
                        </LightTooltip>
                        <LightTooltip title={!hasEmails ? translate("disabled_when_no_mails") : ""} arrow>
                            <Box display={"flex"} alignItems={"center"}>
                                <RHFCheckbox
                                    disabled={!hasEmails}
                                    name={"acceptPartialPayments"}
                                    label={translate("tenant_can_add_partial_payments")}
                                />
                            </Box>
                        </LightTooltip>
                    </Box>

                    <Box display='flex' flexDirection={"column"} alignItems={"center"} gap={2}>
                        <Typography alignSelf={"center"} marginTop={1} marginBottom={-4} fontSize={14} fontWeight={"bold"}>
                            {translate("pick_a_payment_period")} *
                        </Typography>
                        <RHFDayPicker name={"paymentPeriod"} defaultValue={[]} />
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: -3, gap: 2 }} flexDirection={isMobile ? "column-reverse" : "row"}>
                <Button color={"primary"} onClick={onBack}>
                    {translate("back")}
                </Button>
                <Button color={"primary"} type='submit' variant='contained'>
                    {translate("next")}
                </Button>
            </Box>
        </FormProvider>
    )
}
