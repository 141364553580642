import { FormProvider, RHFTextField, RHFUpload, useIsMobile, useLocales } from "rentzz"
import { Alert, Box } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import * as React from "react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { getUnitFromId, useUnitsQueryFn } from "../../../../queries/units"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { AddMeterValueSchema } from "../../../../validations/meterToolbar"
import { useSelector } from "../../../../redux/store"
import { useIntl } from "react-intl"
import { useTheme } from "@mui/material/styles"
import { useMetersQueryFn } from "../../../../queries/meters"
import { OwnerPropertyMeter, PropertyMeterValue } from "../../../../redux/slices/AddProperty"
import { AddMeterValueRequest } from "./Toolbar"
import * as jsonpatch from "fast-json-patch"
import { addMeterValueMutation, updateMeterValuesMutation } from "../../../../mutations/property/meters/meter-values"

interface AddNewIndexFormProps {
    onClose: () => void
    defaultValues?: PropertyMeterValue
}

export default function AddOrEditIndexForm({ onClose, defaultValues }: AddNewIndexFormProps) {
    const isMobile = useIsMobile()
    const intl = useIntl()
    const theme = useTheme()
    const { translate } = useLocales()
    const { editingItem, context, errorMessage, currentRentingPeriodId } = useSelector((state) => state.appState)
    const [isNegative, setIsNegative] = useState(false)
    const { data: meters } = useMetersQueryFn(editingItem?.tableId ? editingItem?.propertyId : undefined)
    const { data: unitData } = useUnitsQueryFn()
    const currentMeter = useMemo(() => (meters as OwnerPropertyMeter[])?.find((meter) => meter?.id === editingItem?.id), [meters, editingItem])
    const { mutateAsync: addNewReading, isPending: isLoading } = addMeterValueMutation()
    const { mutateAsync: updateReading, isPending: isUpdateReadingLoading } = updateMeterValuesMutation()

    useEffect(() => {
        setTimeout(() => {
            if (defaultValues) {
                reset({
                    ...defaultValues,
                    difference: defaultValues.currentValue - defaultValues.previousValue,
                    previousValue: defaultValues.previousValue,
                    value: defaultValues.currentValue,
                    meterId: currentMeter?.id,
                    files: [],
                    rentingPeriodId: currentRentingPeriodId,
                })
            } else {
                reset({
                    meterId: currentMeter?.id,
                    previousValue: currentMeter?.currentValue,
                    files: [],
                    rentingPeriodId: currentRentingPeriodId,
                })
            }
        }, 100)
    }, [currentMeter, defaultValues])

    const methods = useForm<AddMeterValueRequest>({
        resolver: yupResolver(AddMeterValueSchema(context, currentMeter?.requirePhotoForReading ?? false, currentMeter?.requireInteger ?? false)),
        mode: "onChange",
        defaultValues: {
            value: defaultValues ? defaultValues.currentValue : 0,
        },
    })

    const { watch, setValue, resetField, handleSubmit, reset, trigger } = methods

    const onSubmit = useCallback(
        async (data: AddMeterValueRequest) => {
            if (defaultValues) {
                const operations = jsonpatch.compare({ currentValue: defaultValues.currentValue, files: [] }, { currentValue: data.value, files: [] })
                await updateReading({
                    meterId: data.meterId,
                    readingId: defaultValues.id,
                    operations,
                })
            } else {
                await addNewReading(data)
            }

            reset()
            onClose()
        },
        [reset, addNewReading, onClose, defaultValues, updateReading],
    )

    useEffect(() => {
        const subscription = watch((v, { name }) => {
            trigger("files")
            if (name === "value") {
                if (v.value) {
                    setValue("difference", Number(((v.value ?? 0) - (v.previousValue ?? 0) ?? 0).toFixed(2)))
                    setIsNegative(v.value < (v.previousValue ?? 0))
                } else {
                    resetField("difference")
                }
            }
        })
        return () => subscription.unsubscribe()
    }, [watch, trigger])

    const getUnit = useCallback(() => {
        if (!currentMeter?.unitId) return ""
        return getUnitFromId(unitData, currentMeter.unitId).code
    }, [currentMeter, unitData, intl])

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display='flex' gap={2} paddingTop={1} flexDirection={"column"}>
                <Box display={"flex"} gap={2}>
                    <RHFTextField
                        name={"previousValue"}
                        label={translate("previous_index")}
                        disabled
                        variant={"standard"}
                        size={"medium"}
                        InputProps={{ disableUnderline: true, endAdornment: getUnit() }}
                    />
                    <RHFTextField
                        name={"difference"}
                        label={translate("index_difference")}
                        disabled
                        showZero
                        sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: isNegative ? theme.palette.warning.dark : undefined,
                            },
                        }}
                        variant={"filled"}
                        InputProps={{ endAdornment: getUnit() }}
                    />
                </Box>
                <RHFTextField name={"value"} label={translate("new_index")} InputProps={{ endAdornment: getUnit() }} required />
                {defaultValues == null && <RHFUpload name={"files"} icon={"mdi:image-plus-outline"} multiple text={"addMeterPhoto"} onlyPhotos />}

                {errorMessage && (
                    <Box marginTop={2}>
                        <Alert severity={"error"}>{errorMessage}</Alert>
                    </Box>
                )}
                <Box sx={{ display: "flex", justifyContent: "flex-end", paddingX: 0, paddingTop: 2, gap: 2 }}>
                    <LoadingButton
                        fullWidth={isMobile}
                        color={"primary"}
                        type={"submit"}
                        variant={"contained"}
                        disabled={isLoading || isUpdateReadingLoading}
                        loading={isLoading || isUpdateReadingLoading}
                    >
                        {translate("send")}
                    </LoadingButton>
                </Box>
            </Box>
        </FormProvider>
    )
}
