import { useMutation } from "@tanstack/react-query"
import Api from "../../api/Api"
import * as jsonpatch from "fast-json-patch"
import { useUserDataQueryFn } from "../../queries/userData"
import { ComplexQueryIDs, SimpleQueryIDs, useQueryInvalidator } from "../../hooks/useQueryInvalidator"
import { useDispatch, useSelector } from "../../redux/store"
import { CreatePropertyRequest } from "../../dialogs/add-edit-property/steps/MapPicker"
import { ManagerType } from "../../sections/propertyDetails/General/management/managers/AddNewManagerInvitationForm"
import { setErrorMessage } from "../../redux/slices/App"
import { AxiosError } from "axios"
import { useIsMobile } from "rentzz"
import { BackendError } from "../../sections/types/user"

export const addNewInvitationMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()

    return useMutation({
        mutationFn: ({ propertyId, email, type }: { propertyId: number; email: string; type: ManagerType }) =>
            Api.addManagerInvitation(propertyId, email, type),

        onMutate: () => {
            dispatch(setErrorMessage(undefined))
        },
        onSuccess: async () => {
            await invalidateQueries([ComplexQueryIDs.Property])
        },
        onSettled: (_, error) => {
            if (isMobile && error) {
                const errorResponse = error as AxiosError
                const errorMessage = errorResponse?.response?.data as BackendError
                dispatch(setErrorMessage(errorMessage.Message))
            }
        },
    })
}
export const resendManagerInvitationMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ propertyId, email }: { propertyId: number; email: string }) => Api.resendManagerInvitation(propertyId, email),
        onSuccess: async () => {
            await invalidateQueries([ComplexQueryIDs.Property])
        },
    })
}
export const removeOrDeleteManagerMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ propertyId, email, isInvitationAccepted }: { propertyId: number; email: string; isInvitationAccepted: boolean }) =>
            isInvitationAccepted ? Api.deleteManager(propertyId, email) : Api.removeManagerInvitation(propertyId, email),
        onSuccess: async () => {
            await invalidateQueries([ComplexQueryIDs.Property])
        },
    })
}

export const createPropertyMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()
    const dispatch = useDispatch()
    const isMobile = useIsMobile()

    return useMutation({
        mutationFn: ({ data }: { data: CreatePropertyRequest }) => Api.createProperty(data),
        onMutate: () => {
            dispatch(setErrorMessage(undefined))
        },
        onSettled: async (data, error) => {
            if (!error) {
                await invalidateQueries([
                    SimpleQueryIDs.UserProperties,
                    ComplexQueryIDs.SummaryCard,
                    ComplexQueryIDs.DashboardTable,
                    ComplexQueryIDs.DashboardChart,
                    ComplexQueryIDs.Property,
                ])
            } else if (isMobile) {
                const errorResponse = error as AxiosError
                const errorMessage = errorResponse?.response?.data as BackendError
                dispatch(setErrorMessage(errorMessage.Message))
            }
        },
    })
}
export const deletePropertyMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()
    const { refetch: refetchUserData } = useUserDataQueryFn()

    return useMutation({
        mutationFn: (propertyId: number) => Api.deleteProperty(propertyId),
        onSettled: async (_, __, propertyId) => {
            await refetchUserData()
            await invalidateQueries([
                ComplexQueryIDs.SummaryCard,
                ComplexQueryIDs.DashboardTable,
                ComplexQueryIDs.DashboardChart,
                ComplexQueryIDs.Expenses,
                ComplexQueryIDs.Insurance,
                SimpleQueryIDs.UserProperties,
                { query: ComplexQueryIDs.Property, params: { id: propertyId } },
            ])
        },
    })
}

export const updatePropertyDetailsMutation = (propertyId?: number) => {
    const { invalidateQueries } = useQueryInvalidator()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const isMobile = useIsMobile()
    const dispatch = useDispatch()

    return useMutation({
        mutationFn: ({ operations }: { operations: jsonpatch.Operation[]; city?: string; county?: string }) =>
            Api.updatePropertyDetails({ id: currentPropertyId ?? propertyId, operations }),
        onMutate: () => {
            dispatch(setErrorMessage(undefined))
        },
        onSettled: async (_, error) => {
            if (isMobile && error) {
                const errorResponse = error as AxiosError
                const errorMessage = errorResponse?.response?.data as BackendError
                dispatch(setErrorMessage(errorMessage.Message))
            }
            await invalidateQueries([
                { query: ComplexQueryIDs.Property, params: { id: currentPropertyId ?? propertyId } },
                SimpleQueryIDs.UserProperties,
            ])
        },
    })
}

export const updatePropertyNameMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ operations, propertyId }: { operations: jsonpatch.Operation[]; propertyId: number }) =>
            Api.updatePropertyDetails({
                id: propertyId,
                operations,
            }),
        onSettled: async () => {
            await invalidateQueries([SimpleQueryIDs.UserData, SimpleQueryIDs.UserProperties])
        },
    })
}
