import { AppContext, CustomDataGrid, useIsMobile, useLocales } from "rentzz"
import * as React from "react"
import { useCallback, useMemo, useState } from "react"
import { getUnitFromId, useUnitsQueryFn } from "../../../../queries/units"
import { useIntl } from "react-intl"
import { Box, Card, CardContent, CircularProgress } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { OwnerPropertyMeter, PropertyFileType, PropertyMeterValue, TenantPropertyMeter } from "../../../../redux/slices/AddProperty"
import { GridActionsCellItem, GridColDef, GridRowParams, GridSortModel } from "@mui/x-data-grid-pro"
import { DateTime } from "luxon"
import ActionWithTooltip from "../../../../utils/ActionWithTooltip"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import { DESKTOP_ICON_SIZE } from "../../../../utils/helpers"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { useMeterValuesHistoryQueryFn } from "../../../../queries/meters"
import { useDispatch, useSelector } from "../../../../redux/store"
import Header from "../Header"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import DeleteIcon from "@mui/icons-material/Delete"

interface Props {
    currentMeter: (TenantPropertyMeter & { canWrite: boolean }) | (OwnerPropertyMeter & { canWrite: boolean; canDelete: boolean }) | undefined
}

const MeterReadingsPanel = ({ currentMeter }: Props) => {
    const theme = useTheme()
    const { data: unitData, isLoading: areUnitsLoading } = useUnitsQueryFn()
    const intl = useIntl()
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    })
    const { context } = useSelector((state) => state.appState)
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "createdAt", sort: "desc" }])
    const { translate } = useLocales()
    const {
        data: readingsList,
        isFetching,
        isLoading,
    } = useMeterValuesHistoryQueryFn(currentMeter?.id, paginationModel.page, paginationModel.pageSize, sortModel)
    const isMobile = useIsMobile()
    const dispatch = useDispatch()

    const getValue = useCallback(
        (value: number) => {
            if (value === -1) return "-"
            if (!currentMeter?.unitId) return ""
            return `${intl.formatNumber(value as number, {
                style: "decimal",
                useGrouping: false,
            })} ${getUnitFromId(unitData, currentMeter?.unitId).code}`
        },
        [currentMeter, unitData, intl],
    )

    const columns = useMemo(
        (): GridColDef<PropertyMeterValue>[] => [
            {
                field: "createdAt",
                width: 100,
                headerAlign: "center",
                align: "center",
                flex: isMobile ? undefined : 0.5,
                type: "string",
                valueFormatter: (value) => (value as DateTime).toLocaleString(DateTime.DATE_SHORT),
                headerName: translate("index_sent_date"),
            },
            {
                field: "createdBy",
                width: 140,
                headerAlign: "center",
                align: "center",
                flex: isMobile ? undefined : 0.5,
                type: "string",
                sortable: false,
                headerName: translate("index_sent_by"),
            },
            {
                field: "previousValue",
                width: 100,
                headerAlign: "center",
                align: "center",
                flex: isMobile ? undefined : 0.5,
                type: "number",
                headerName: translate("previous_index"),
                valueFormatter: (value) => {
                    return getValue(value)
                },
            },
            {
                field: "currentValue",
                width: 90,
                headerAlign: "center",
                align: "center",
                flex: isMobile ? undefined : 0.5,
                type: "number",
                headerName: translate("new_index"),
                valueFormatter: (value) => {
                    return getValue(value)
                },
            },
        ],
        [translate, getValue, isMobile],
    )

    const actionsColumn = useMemo(
        (): GridColDef => ({
            field: "actions",
            resizable: false,
            flex: 0.2,
            minWidth: 150,
            type: "actions",
            headerName: translate("actions"),
            getActions: (params: GridRowParams<PropertyMeterValue>) => [
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color='primary'
                            icon={<ModeEditIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setEditingItem({ id: currentMeter?.id, readingId: params.row.id }))
                                dispatch(setModalOpen(AppModals.EditReading))
                            }}
                            label={translate("grid_actions.edit")}
                            sx={{ p: 1 }}
                        />
                    ),
                    hidden: !params.row.canEdit,
                    tooltipText: "",
                    key: "edit",
                }),
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"info"}
                            icon={<CloudDownloadIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(
                                    setEditingItem({
                                        readingId: params.row.id,
                                        type: PropertyFileType.MeterValue,
                                        canWrite: currentMeter?.canWrite,
                                        createdBy: params.row.createdBy,
                                    }),
                                )
                                dispatch(setModalOpen(AppModals.EntityFileManager))
                            }}
                            label={`${translate("see_files")}`}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: translate("see_files"),
                    key: "see_files",
                    hidden: context === AppContext.Tenant ? readingsList?.items[readingsList?.items.length - 1].id === params.id : false,
                }),
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"error"}
                            icon={<DeleteIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()

                                dispatch(
                                    setEditingItem({
                                        id: currentMeter?.id,
                                        meterValueId: params.row.id,
                                        value: params.row.currentValue,
                                        propertyId: currentMeter?.propertyId,
                                    }),
                                )
                                dispatch(setModalOpen(AppModals.DeleteMeterValue))
                            }}
                            label={`${translate("delete")}`}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: translate("delete"),
                    key: "delete",
                    hidden: context === AppContext.Tenant ? true : !params.row.canEdit,
                }),
            ],
        }),
        [translate, dispatch, currentMeter, context],
    )

    if (isLoading || areUnitsLoading || currentMeter == null)
        return (
            <Box display='flex' flexDirection='column' width='100%' height={150} alignItems='center' justifyContent='center'>
                <CircularProgress />
            </Box>
        )

    return (
        <Box display={"flex"} justifyContent={"center"} paddingY={2}>
            <Card
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "90%",
                    height: "100%",
                    backgroundColor: theme.palette.grey.A100,
                }}
            >
                <CardContent
                    sx={{
                        justifyContent: "center",
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        flexDirection: "column",
                        color: theme.palette.grey["700"],
                        alignSelf: "center",
                        gap: 2,
                        paddingBottom: 0,
                        "&.MuiCardContent-root:last-child": { paddingBottom: 0 },
                    }}
                >
                    <Header
                        currentMeterId={currentMeter?.id}
                        canWrite={currentMeter?.canWrite}
                        lastValue={readingsList ? readingsList.items[0]?.currentValue : null}
                        numberOfItems={readingsList?.items.length}
                    />
                    <CustomDataGrid
                        sortModel={sortModel}
                        sortingMode='server'
                        onSortModelChange={setSortModel}
                        paginationMode={readingsList?.count === 20 && readingsList.items.length === 0 ? "client" : "server"}
                        rowsLoadingMode={readingsList?.count === 20 && readingsList?.items.length === 0 ? "server" : "client"}
                        hidePagination={readingsList?.count === 20 && readingsList.items.length === 0}
                        columns={columns}
                        rows={readingsList?.items ?? []}
                        gridHeight={400}
                        isLoading={isFetching && !(readingsList?.count === 20 && readingsList?.items.length === 0)}
                        idKey='id'
                        totalCount={readingsList?.count}
                        actionsColumn={[actionsColumn]}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                    />
                </CardContent>
            </Card>
        </Box>
    )
}

export default MeterReadingsPanel
