import { useQuery } from "@tanstack/react-query"
import Api from "../api/Api"
import { ComplexQueryIDs, SimpleQueryIDs } from "../hooks/useQueryInvalidator"
import { DateTime } from "luxon"
import { AppContext } from "rentzz"
import { useSelector } from "../redux/store"

export interface TaskSectionBase {
    taskSectionId: string
    name: string
    order: number
    canEdit: boolean
}

export interface TaskSectionDto extends TaskSectionBase {
    createdAt: string
}

export interface TaskSection extends TaskSectionBase {
    createdAt: DateTime
}

export const useAllSectionsQueryFn = () => {
    const { context } = useSelector((state) => state.appState)
    return useQuery({
        queryFn: Api.fetchUserSections,
        queryKey: [SimpleQueryIDs.UserTaskSections],
        staleTime: Infinity,
        enabled: context === AppContext.Owner,
    })
}

export const useSectionItemsQueryFn = (id: string) => {
    const { context } = useSelector((state) => state.appState)

    return useQuery({
        queryFn: Api.fetchItemsSection,
        queryKey: [ComplexQueryIDs.SectionTasks, { sectionId: id }],
        staleTime: Infinity,
        enabled: context === AppContext.Owner && !!id,
    })
}

export const useTaskCommentsQueryFn = (taskId: string, sectionId: string | undefined) => {
    const { context } = useSelector((state) => state.appState)

    return useQuery({
        queryFn: Api.fetchTaskComments,
        queryKey: [ComplexQueryIDs.TaskComments, { sectionId, taskId }],
        staleTime: Infinity,
        enabled: context === AppContext.Owner && !!taskId && !!sectionId,
    })
}

export const useTenantTasksQueryFn = (rentingPeriodId: number | undefined) => {
    const { context } = useSelector((state) => state.appState)

    return useQuery({
        queryFn: Api.fetchTenantTasks,
        queryKey: [ComplexQueryIDs.TenantTasks, { rentingPeriodId }],
        staleTime: Infinity,
        enabled: context === AppContext.Tenant && !!rentingPeriodId,
    })
}

export const useTenantTaskCommentsQueryFn = (rentingPeriodId: number | undefined, taskId: string) => {
    const { context } = useSelector((state) => state.appState)

    return useQuery({
        queryFn: Api.fetchTenantTaskComments,
        queryKey: [ComplexQueryIDs.TenantTaskComments, { rentingPeriodId, taskId }],
        staleTime: Infinity,
        enabled: context === AppContext.Tenant && !!rentingPeriodId && !!rentingPeriodId,
    })
}
