import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import React, { useCallback } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import * as jsonpatch from "fast-json-patch"
import { addRevisionMutation, updateRevisionMutation } from "../../../mutations/revison"
import { getRevisionByIdQueryFn } from "../../../queries/revision"
import AddOrEditRevisionForm, { AddRevisionRequest } from "../../../sections/propertyDetails/revision/AddOrEditRevisionForm"
import { fromIsoToDateTime } from "../../../utils/dateMagic"

export default function AddOrEditRevisionGuard() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: addRevision, isPending: isAddRevisionLoading } = addRevisionMutation()
    const { mutateAsync: updateRevision, isPending: isUpdatingLoading } = updateRevisionMutation()
    const currentRevision = getRevisionByIdQueryFn(editingItem?.id, [{ field: "date", sort: "desc" }])

    const closeModal = useCallback(() => {
        if (isMobile) {
            dispatch(setModalOpen(AppModals.RevisionDetails))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [dispatch, isMobile])

    const onSave = useCallback(
        async (data: AddRevisionRequest) => {
            if (currentRevision != null && modalOpen === AppModals.EditRevision) {
                const operations = jsonpatch.compare(
                    { ...currentRevision, files: [], id: 1, date: currentRevision.date.toISODate() },
                    { ...data, files: [], id: 1, date: fromIsoToDateTime(data.date).toISODate() },
                )
                if (!data.id) return
                await updateRevision({
                    propertyId: data.propertyId,
                    revisionId: data.id,
                    operations,
                })
                if (isMobile) {
                    dispatch(setModalOpen(AppModals.RevisionDetails))
                } else {
                    closeModal()
                }
            } else {
                await addRevision({
                    data: {
                        ...data,
                        date: fromIsoToDateTime(data.date).toISODate()!,
                    },
                })
                closeModal()
            }
        },
        [updateRevision, closeModal, currentRevision, modalOpen, dispatch],
    )

    return (
        <>
            <CustomDialog
                title={translate(modalOpen === AppModals.EditRevision ? "edit_revision" : "add_revision")}
                open={!isMobile && (modalOpen === AppModals.AddRevision || modalOpen === AppModals.EditRevision)}
                onClose={closeModal}
                maxWidth={"sm"}
                titleAlign={"center"}
                isFullScreen={false}
            >
                <AddOrEditRevisionForm
                    onClose={closeModal}
                    onSave={onSave}
                    isLoading={isAddRevisionLoading || isUpdatingLoading}
                    defaultValues={currentRevision}
                />
            </CustomDialog>

            <DetailsSheet
                isLoading={false}
                title={translate(modalOpen === AppModals.EditRevision ? "edit_revision" : "add_revision")}
                isOpen={isMobile && (modalOpen === AppModals.AddRevision || modalOpen === AppModals.EditRevision)}
                onClose={closeModal}
                headerColor={theme.palette.primary.main}
            >
                <AddOrEditRevisionForm
                    onClose={closeModal}
                    onSave={onSave}
                    isLoading={isAddRevisionLoading || isUpdatingLoading}
                    defaultValues={currentRevision}
                />
            </DetailsSheet>
        </>
    )
}
