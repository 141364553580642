import { createBrowserRouter, Navigate, Outlet, useLocation } from "react-router-dom"
import Root from "./Root"
import ContextGuard from "../guards/context/ContextGuard"
import ManagerNotificationGuard from "../guards/ManagerNotificationGuard"
import SubscriptionGuard from "../guards/SubscriptionGuard"
import OnboardedGuard from "../guards/OnboardedGuard"
import NoPropertyGuard from "../guards/no-property/NoPropertyGuard"
import OwnerGuard from "../guards/OwnerGuard"
import RentingPeriodDetails from "../sections/propertyDetails/Tenants/rentingPeriodDetails/RentingPeriodDetails"
import TenantGuard from "../guards/TenantGuard"
import Files from "../sections/files/Files"
import RoleBasedGuard from "../guards/RoleBasedGuard"
import { RoleName, UserFlags } from "../queries/userData"
import FeaturesGuard from "../guards/FeaturesGuard"
import WhitelabelGuard from "../guards/WhitelabelGuard"
import WhitelabelUserGuard from "../guards/WhitelabelUserGuard"
import NewAdWizard from "../sections/propertyDetails/marketing/addNewAd/NewAdWizard"
import React, { ElementType, lazy, Suspense } from "react"
import { ErrorPage, LoadingScreen, Page404 } from "rentzz"
import AuthGuard from "../guards/AuthGuard"
import SectionsPage from "../sections/nomenclature/sections/SectionsPage"
import Tasks from "../pages/tasks/Tasks"
import PropertySections from "../sections/files/PropertySections"
import SectionFiles from "../sections/files/SectionFiles"
import Revision from "../sections/propertyDetails/revision/Revision"
import SettingsPage from "../sections/settings/SettingsPage"
import GroupSection from "../sections/groups/groupSection/GroupSection"
import TenantRevision from "../sections/tenantContext/revision/TenantRevision"
import NotificationsPage from "../sections/documents-templates/notifications/NotificationsPage"
import AddOrEditNotificationPage from "../sections/documents-templates/notifications/AddOrEditNotificationPage"
import GeneralDocumentsPage from "../sections/documents-templates/generalDocuments/GeneralDocumentsPage"
import GeneralDocumentConfiguration from "../sections/documents-templates/generalDocuments/GeneralDocumentConfiguration"
import AcceptanceGuard from "../guards/acceptance/AcceptanceGuard"
import AddOrEditGeneralDocumentPage from "../sections/documents-templates/generalDocuments/AddOrEditGeneralDocumentPage"
import WhiteLabelAdminGuard from "../guards/WhiteLabelAdminGuard"

// eslint-disable-next-line react/display-name
const Loadable = (Component: ElementType) => (props: unknown) => {
    const { pathname } = useLocation()

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}>
            <Component
                /* eslint-disable-next-line  */
                {...(props as any)}
            />
        </Suspense>
    )
}
const Dashboard = Loadable(lazy(() => import("../sections/dashboard/Dashboard")))
const Properties = Loadable(lazy(() => import("../pages/properties/Properties")))
const Expenses = Loadable(lazy(() => import("../sections/expenses/ExpensesPage")))
const Income = Loadable(lazy(() => import("../sections/income/IncomePage")))
const Tenants = Loadable(lazy(() => import("../sections/propertyDetails/Tenants/Tenants")))
const Details = Loadable(lazy(() => import("../pages/PropertyDetails")))
const Payments = Loadable(lazy(() => import("../sections/tenantContext/payment/Payments")))
const ContractsPage = Loadable(lazy(() => import("../pages/contracts")))
const NewContract = Loadable(lazy(() => import("../sections/contracts/newContract")))
const Marketing = Loadable(lazy(() => import("../pages/Marketing")))
const Reports = Loadable(lazy(() => import("../pages/Reports")))
const Calendar = Loadable(lazy(() => import("../pages/Calendar")))
const Labels = Loadable(lazy(() => import("../pages/LabelsNomenclature")))
const Providers = Loadable(lazy(() => import("../pages/ProvidersNomenclature")))
const Insurance = Loadable(lazy(() => import("../sections/insurance/Insurance")))
const Groups = Loadable(lazy(() => import("../sections/groups/Groups")))
const Meters = Loadable(lazy(() => import("../sections/propertyDetails/Meters/PropertyMetersList")))
const Notes = Loadable(lazy(() => import("../sections/propertyDetails/General/details/notes/Notes")))
const InvoicingConfigurationPage = Loadable(lazy(() => import("../sections/nomenclature/invoicing-configuration/InvoicingConfigurationPage")))
const C168AddressConfiguration = Loadable(lazy(() => import("../sections/nomenclature/c168/C168NomenclaturePage")))
const RegisterSignIn = Loadable(lazy(() => import("../pages/RegisterSignIn")))
const DownloadRentingPeriodDataPage = Loadable(lazy(() => import("../pages/DownloadRentingPeriodDataPage")))
const Tickets = Loadable(lazy(() => import("../routes/Tickets")))
const PromoCodePage = Loadable(lazy(() => import("../sections/user/promoCode/PromotionalCodePage")))
const BankAccounts = Loadable(lazy(() => import("../sections/nomenclature/bankAccounts/BankAccounts")))

const router = createBrowserRouter([
    {
        path: "",
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            { element: <Navigate to={"/dashboard"} />, index: true },
            {
                element: (
                    <ContextGuard>
                        <ManagerNotificationGuard>
                            <SubscriptionGuard>
                                <OnboardedGuard>
                                    <NoPropertyGuard>
                                        <Dashboard />
                                    </NoPropertyGuard>
                                </OnboardedGuard>
                            </SubscriptionGuard>
                        </ManagerNotificationGuard>
                    </ContextGuard>
                ),
                path: "/dashboard",
                handle: {
                    crumbs: ["dashboard"],
                },
            },
            {
                path: "/support",
                element: <Tickets />,
            },
            {
                path: "/tasks",
                children: [
                    {
                        index: true,
                        element: (
                            <ContextGuard>
                                <ManagerNotificationGuard>
                                    <SubscriptionGuard>
                                        <OnboardedGuard>
                                            <NoPropertyGuard>
                                                <FeaturesGuard featureId={UserFlags.TaskItems}>
                                                    <Tasks />
                                                </FeaturesGuard>
                                            </NoPropertyGuard>
                                        </OnboardedGuard>
                                    </SubscriptionGuard>
                                </ManagerNotificationGuard>
                            </ContextGuard>
                        ),
                        handle: {
                            crumbs: ["tasks"],
                        },
                    },
                ],
            },
            {
                path: "/properties",
                children: [
                    {
                        index: true,
                        element: (
                            <ContextGuard>
                                <ManagerNotificationGuard>
                                    <SubscriptionGuard>
                                        <OnboardedGuard>
                                            <OwnerGuard>
                                                <Properties />
                                            </OwnerGuard>
                                        </OnboardedGuard>
                                    </SubscriptionGuard>
                                </ManagerNotificationGuard>
                            </ContextGuard>
                        ),
                        handle: {
                            crumbs: ["properties"],
                        },
                    },
                    {
                        path: ":currentPropertyId",
                        element: <Navigate to='general' replace />,
                        handle: {
                            crumbs: ["properties", ":currentPropertyId"],
                        },
                    },
                    {
                        path: ":currentPropertyId/:page",
                        children: [
                            {
                                index: true,
                                element: (
                                    <ContextGuard>
                                        <ManagerNotificationGuard>
                                            <SubscriptionGuard>
                                                <OnboardedGuard>
                                                    <OwnerGuard>
                                                        <Details />
                                                    </OwnerGuard>
                                                </OnboardedGuard>
                                            </SubscriptionGuard>
                                        </ManagerNotificationGuard>
                                    </ContextGuard>
                                ),
                                handle: {
                                    crumbs: ["properties", ":currentPropertyId"],
                                },
                            },
                            {
                                path: ":section",
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <ContextGuard>
                                                <ManagerNotificationGuard>
                                                    <SubscriptionGuard>
                                                        <OnboardedGuard>
                                                            <NoPropertyGuard>
                                                                <SectionFiles />
                                                            </NoPropertyGuard>
                                                        </OnboardedGuard>
                                                    </SubscriptionGuard>
                                                </ManagerNotificationGuard>
                                            </ContextGuard>
                                        ),
                                        handle: {
                                            crumbs: ["properties", ":currentPropertyId", "propertyDocuments", ":section"],
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: ":currentPropertyId/tenants/:currentRentingPeriodId/:page",
                        element: (
                            <ContextGuard>
                                <ManagerNotificationGuard>
                                    <SubscriptionGuard>
                                        <OnboardedGuard>
                                            <OwnerGuard>
                                                <RentingPeriodDetails />
                                            </OwnerGuard>
                                        </OnboardedGuard>
                                    </SubscriptionGuard>
                                </ManagerNotificationGuard>
                            </ContextGuard>
                        ),
                        handle: {
                            crumbs: ["properties", ":currentPropertyId", ":currentRentingPeriodId"],
                        },
                    },
                    {
                        path: ":currentPropertyId/tenants/:currentRentingPeriodId",
                        element: <Navigate to='general' replace />,
                    },
                ],
            },
            {
                element: (
                    <ContextGuard>
                        <ManagerNotificationGuard>
                            <SubscriptionGuard>
                                <OnboardedGuard>
                                    <Expenses />
                                </OnboardedGuard>
                            </SubscriptionGuard>
                        </ManagerNotificationGuard>
                    </ContextGuard>
                ),
                path: "/expenses",
                handle: {
                    crumbs: ["expenses.expenses"],
                },
            },
            {
                element: (
                    <ContextGuard>
                        <ManagerNotificationGuard>
                            <SubscriptionGuard>
                                <OnboardedGuard>
                                    <TenantGuard>
                                        <FeaturesGuard featureId={UserFlags.HideTenantPaymentPage}>
                                            <Payments />
                                        </FeaturesGuard>
                                    </TenantGuard>
                                </OnboardedGuard>
                            </SubscriptionGuard>
                        </ManagerNotificationGuard>
                    </ContextGuard>
                ),
                path: "/payments",
                handle: {
                    crumbs: ["payments"],
                },
            },
            {
                element: (
                    <ContextGuard>
                        <ManagerNotificationGuard>
                            <SubscriptionGuard>
                                <OnboardedGuard>
                                    <TenantGuard>
                                        <FeaturesGuard featureId={UserFlags.HideTenantRevisions}>
                                            <TenantRevision />
                                        </FeaturesGuard>
                                    </TenantGuard>
                                </OnboardedGuard>
                            </SubscriptionGuard>
                        </ManagerNotificationGuard>
                    </ContextGuard>
                ),
                path: "/maintenance",
                handle: {
                    crumbs: ["revisions"],
                },
            },
            {
                path: "/documents",
                children: [
                    {
                        index: true,
                        element: (
                            <ContextGuard>
                                <ManagerNotificationGuard>
                                    <SubscriptionGuard>
                                        <OnboardedGuard>
                                            <NoPropertyGuard>
                                                <Files />
                                            </NoPropertyGuard>
                                        </OnboardedGuard>
                                    </SubscriptionGuard>
                                </ManagerNotificationGuard>
                            </ContextGuard>
                        ),
                        handle: {
                            crumbs: ["documents"],
                        },
                    },
                    {
                        path: ":currentFilesPropertyId",
                        children: [
                            {
                                index: true,
                                element: (
                                    <ContextGuard>
                                        <ManagerNotificationGuard>
                                            <SubscriptionGuard>
                                                <OnboardedGuard>
                                                    <NoPropertyGuard>
                                                        <PropertySections />
                                                    </NoPropertyGuard>
                                                </OnboardedGuard>
                                            </SubscriptionGuard>
                                        </ManagerNotificationGuard>
                                    </ContextGuard>
                                ),
                                handle: {
                                    crumbs: ["documents", ":currentFilesPropertyId"],
                                },
                            },
                            {
                                path: ":section",
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <ContextGuard>
                                                <ManagerNotificationGuard>
                                                    <SubscriptionGuard>
                                                        <OnboardedGuard>
                                                            <NoPropertyGuard>
                                                                <SectionFiles />
                                                            </NoPropertyGuard>
                                                        </OnboardedGuard>
                                                    </SubscriptionGuard>
                                                </ManagerNotificationGuard>
                                            </ContextGuard>
                                        ),
                                        handle: {
                                            crumbs: ["documents", ":currentFilesPropertyId", ":section"],
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                element: (
                    <ContextGuard>
                        <ManagerNotificationGuard>
                            <SubscriptionGuard>
                                <OnboardedGuard>
                                    <TenantGuard>
                                        <Notes />
                                    </TenantGuard>
                                </OnboardedGuard>
                            </SubscriptionGuard>
                        </ManagerNotificationGuard>
                    </ContextGuard>
                ),
                path: "/notes",
                handle: {
                    crumbs: ["notes"],
                },
            },
            {
                element: (
                    <ContextGuard>
                        <ManagerNotificationGuard>
                            <SubscriptionGuard>
                                <OnboardedGuard>
                                    <OwnerGuard>
                                        <Income />
                                    </OwnerGuard>
                                </OnboardedGuard>
                            </SubscriptionGuard>
                        </ManagerNotificationGuard>
                    </ContextGuard>
                ),
                path: "/income",
                handle: {
                    crumbs: ["income"],
                },
            },
            {
                path: "/tenants",
                children: [
                    {
                        index: true,
                        element: (
                            <ContextGuard>
                                <ManagerNotificationGuard>
                                    <SubscriptionGuard>
                                        <OnboardedGuard>
                                            <OwnerGuard>
                                                <Tenants />
                                            </OwnerGuard>
                                        </OnboardedGuard>
                                    </SubscriptionGuard>
                                </ManagerNotificationGuard>
                            </ContextGuard>
                        ),
                        handle: {
                            crumbs: ["tenants"],
                        },
                    },
                    {
                        path: ":currentRentingPeriodId/:page",
                        children: [
                            {
                                index: true,
                                element: (
                                    <ContextGuard>
                                        <ManagerNotificationGuard>
                                            <SubscriptionGuard>
                                                <OnboardedGuard>
                                                    <OwnerGuard>
                                                        <RentingPeriodDetails />
                                                    </OwnerGuard>
                                                </OnboardedGuard>
                                            </SubscriptionGuard>
                                        </ManagerNotificationGuard>
                                    </ContextGuard>
                                ),
                                handle: {
                                    crumbs: ["tenants", ":currentRentingPeriodId"],
                                },
                            },
                            {
                                path: ":section",
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <ContextGuard>
                                                <ManagerNotificationGuard>
                                                    <SubscriptionGuard>
                                                        <OnboardedGuard>
                                                            <OwnerGuard>
                                                                <SectionFiles />
                                                            </OwnerGuard>
                                                        </OnboardedGuard>
                                                    </SubscriptionGuard>
                                                </ManagerNotificationGuard>
                                            </ContextGuard>
                                        ),
                                        handle: {
                                            crumbs: ["tenants", ":currentRentingPeriodId", "rentingPeriodDocuments", ":section"],
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: ":currentRentingPeriodId",
                        children: [
                            {
                                index: true,
                                element: <Navigate to='general' replace />,
                            },
                        ],
                    },
                ],
            },
            {
                element: (
                    <ContextGuard>
                        <ManagerNotificationGuard>
                            <SubscriptionGuard>
                                <OnboardedGuard>
                                    <OwnerGuard>
                                        <Insurance />
                                    </OwnerGuard>
                                </OnboardedGuard>
                            </SubscriptionGuard>
                        </ManagerNotificationGuard>
                    </ContextGuard>
                ),
                path: "/insurance",
                handle: {
                    crumbs: ["insurance"],
                },
            },

            {
                path: "/groups",
                children: [
                    {
                        index: true,
                        element: (
                            <ContextGuard>
                                <ManagerNotificationGuard>
                                    <SubscriptionGuard>
                                        <OnboardedGuard>
                                            <OwnerGuard>
                                                <Groups />
                                            </OwnerGuard>
                                        </OnboardedGuard>
                                    </SubscriptionGuard>
                                </ManagerNotificationGuard>
                            </ContextGuard>
                        ),
                        handle: {
                            crumbs: ["groups"],
                        },
                    },
                    {
                        path: ":currentGroupId",
                        element: <Navigate to='general' replace />,
                        handle: {
                            crumbs: ["groups", ":currentGroupId"],
                        },
                    },
                    {
                        path: ":currentGroupId/:page",
                        children: [
                            {
                                index: true,
                                element: (
                                    <ContextGuard>
                                        <ManagerNotificationGuard>
                                            <SubscriptionGuard>
                                                <OnboardedGuard>
                                                    <OwnerGuard>
                                                        <GroupSection />
                                                    </OwnerGuard>
                                                </OnboardedGuard>
                                            </SubscriptionGuard>
                                        </ManagerNotificationGuard>
                                    </ContextGuard>
                                ),
                                handle: {
                                    crumbs: ["groups", ":currentGroupId"],
                                },
                            },
                        ],
                    },
                ],
            },
            {
                element: (
                    <ContextGuard>
                        <ManagerNotificationGuard>
                            <SubscriptionGuard>
                                <OnboardedGuard>
                                    <OwnerGuard>
                                        <Revision />
                                    </OwnerGuard>
                                </OnboardedGuard>
                            </SubscriptionGuard>
                        </ManagerNotificationGuard>
                    </ContextGuard>
                ),
                path: "/revisions",
                handle: {
                    crumbs: ["revisions"],
                },
            },
            {
                element: (
                    <ContextGuard>
                        <ManagerNotificationGuard>
                            <SubscriptionGuard>
                                <OnboardedGuard>
                                    <OwnerGuard>
                                        <Reports />
                                    </OwnerGuard>
                                </OnboardedGuard>
                            </SubscriptionGuard>
                        </ManagerNotificationGuard>
                    </ContextGuard>
                ),
                path: "/reports",
                handle: {
                    crumbs: ["report"],
                },
            },
            {
                element: (
                    <ContextGuard>
                        <ManagerNotificationGuard>
                            <SubscriptionGuard>
                                <OnboardedGuard>
                                    <TenantGuard>
                                        <Meters />
                                    </TenantGuard>
                                </OnboardedGuard>
                            </SubscriptionGuard>
                        </ManagerNotificationGuard>
                    </ContextGuard>
                ),
                path: "/meters",
            },
            {
                element: (
                    <ContextGuard>
                        <ManagerNotificationGuard>
                            <SubscriptionGuard>
                                <OnboardedGuard>
                                    <RoleBasedGuard hasContent roles={[RoleName.BRONZE, RoleName.SILVER, RoleName.GOLD, RoleName.FREE]}>
                                        <FeaturesGuard featureId={UserFlags.PromoCode}>
                                            <OwnerGuard>
                                                <WhitelabelGuard>
                                                    <PromoCodePage />
                                                </WhitelabelGuard>
                                            </OwnerGuard>
                                        </FeaturesGuard>
                                    </RoleBasedGuard>
                                </OnboardedGuard>
                            </SubscriptionGuard>
                        </ManagerNotificationGuard>
                    </ContextGuard>
                ),
                path: "/promo-code",
                handle: {
                    crumbs: ["promo-code"],
                },
            },

            {
                path: "/document-templates",
                children: [
                    { index: true, element: <Navigate to={"contracts-templates"} /> },
                    {
                        path: "contract-templates",
                        children: [
                            {
                                index: true,
                                element: (
                                    <ContextGuard>
                                        <ManagerNotificationGuard>
                                            <SubscriptionGuard>
                                                <OnboardedGuard>
                                                    <RoleBasedGuard
                                                        hasContent
                                                        roles={[RoleName.BRONZE, RoleName.SILVER, RoleName.GOLD, RoleName.FREE]}
                                                    >
                                                        <WhitelabelUserGuard>
                                                            <OwnerGuard>
                                                                <ContractsPage />
                                                            </OwnerGuard>
                                                        </WhitelabelUserGuard>
                                                    </RoleBasedGuard>
                                                </OnboardedGuard>
                                            </SubscriptionGuard>
                                        </ManagerNotificationGuard>
                                    </ContextGuard>
                                ),
                                handle: {
                                    crumbs: ["template"],
                                },
                            },
                            {
                                path: "new",
                                element: (
                                    <ContextGuard>
                                        <ManagerNotificationGuard>
                                            <SubscriptionGuard>
                                                <OnboardedGuard>
                                                    <RoleBasedGuard hasContent roles={[RoleName.BRONZE, RoleName.SILVER, RoleName.GOLD]}>
                                                        <WhitelabelUserGuard>
                                                            <OwnerGuard>
                                                                <NewContract />
                                                            </OwnerGuard>
                                                        </WhitelabelUserGuard>
                                                    </RoleBasedGuard>
                                                </OnboardedGuard>
                                            </SubscriptionGuard>
                                        </ManagerNotificationGuard>
                                    </ContextGuard>
                                ),
                                handle: {
                                    crumbs: ["template", "new_template"],
                                },
                            },
                            {
                                path: ":contractId",
                                element: (
                                    <ContextGuard>
                                        <ManagerNotificationGuard>
                                            <SubscriptionGuard>
                                                <OnboardedGuard>
                                                    <RoleBasedGuard
                                                        hasContent
                                                        roles={[RoleName.BRONZE, RoleName.SILVER, RoleName.GOLD, RoleName.FREE]}
                                                    >
                                                        <WhitelabelUserGuard>
                                                            <OwnerGuard>
                                                                <NewContract />
                                                            </OwnerGuard>
                                                        </WhitelabelUserGuard>
                                                    </RoleBasedGuard>
                                                </OnboardedGuard>
                                            </SubscriptionGuard>
                                        </ManagerNotificationGuard>
                                    </ContextGuard>
                                ),
                                handle: {
                                    crumbs: ["template", ":contractId"],
                                },
                            },
                        ],
                    },

                    {
                        path: "notification-templates",
                        children: [
                            {
                                index: true,
                                element: (
                                    <ContextGuard>
                                        <ManagerNotificationGuard>
                                            <SubscriptionGuard>
                                                <OnboardedGuard>
                                                    <RoleBasedGuard
                                                        hasContent
                                                        roles={[RoleName.BRONZE, RoleName.SILVER, RoleName.GOLD, RoleName.FREE]}
                                                    >
                                                        <WhitelabelUserGuard>
                                                            <FeaturesGuard featureId={UserFlags.ShowPaymentNotifications}>
                                                                <OwnerGuard>
                                                                    <NotificationsPage />
                                                                </OwnerGuard>
                                                            </FeaturesGuard>
                                                        </WhitelabelUserGuard>
                                                    </RoleBasedGuard>
                                                </OnboardedGuard>
                                            </SubscriptionGuard>
                                        </ManagerNotificationGuard>
                                    </ContextGuard>
                                ),
                                handle: {
                                    crumbs: ["notifications_templates"],
                                },
                            },
                            {
                                path: "new",
                                element: (
                                    <ContextGuard>
                                        <ManagerNotificationGuard>
                                            <SubscriptionGuard>
                                                <OnboardedGuard>
                                                    <RoleBasedGuard hasContent roles={[RoleName.BRONZE, RoleName.SILVER, RoleName.GOLD]}>
                                                        <WhitelabelUserGuard>
                                                            <FeaturesGuard featureId={UserFlags.ShowPaymentNotifications}>
                                                                <OwnerGuard>
                                                                    <AddOrEditNotificationPage />
                                                                </OwnerGuard>
                                                            </FeaturesGuard>
                                                        </WhitelabelUserGuard>
                                                    </RoleBasedGuard>
                                                </OnboardedGuard>
                                            </SubscriptionGuard>
                                        </ManagerNotificationGuard>
                                    </ContextGuard>
                                ),
                                handle: {
                                    crumbs: ["notifications_templates", "new_template_notification"],
                                },
                            },
                            {
                                path: ":notificationId",
                                element: (
                                    <ContextGuard>
                                        <ManagerNotificationGuard>
                                            <SubscriptionGuard>
                                                <OnboardedGuard>
                                                    <RoleBasedGuard
                                                        hasContent
                                                        roles={[RoleName.BRONZE, RoleName.SILVER, RoleName.GOLD, RoleName.FREE]}
                                                    >
                                                        <WhitelabelUserGuard>
                                                            <FeaturesGuard featureId={UserFlags.ShowPaymentNotifications}>
                                                                <OwnerGuard>
                                                                    <AddOrEditNotificationPage />
                                                                </OwnerGuard>
                                                            </FeaturesGuard>
                                                        </WhitelabelUserGuard>
                                                    </RoleBasedGuard>
                                                </OnboardedGuard>
                                            </SubscriptionGuard>
                                        </ManagerNotificationGuard>
                                    </ContextGuard>
                                ),
                                handle: {
                                    crumbs: ["notifications_templates", ":notificationId"],
                                },
                            },
                        ],
                    },

                    {
                        path: "general-documents",
                        children: [
                            {
                                index: true,
                                element: (
                                    <ContextGuard>
                                        <ManagerNotificationGuard>
                                            <SubscriptionGuard>
                                                <OnboardedGuard>
                                                    <RoleBasedGuard
                                                        hasContent
                                                        roles={[RoleName.BRONZE, RoleName.SILVER, RoleName.GOLD, RoleName.FREE]}
                                                    >
                                                        <OwnerGuard>
                                                            <GeneralDocumentsPage />
                                                        </OwnerGuard>
                                                    </RoleBasedGuard>
                                                </OnboardedGuard>
                                            </SubscriptionGuard>
                                        </ManagerNotificationGuard>
                                    </ContextGuard>
                                ),
                                handle: {
                                    crumbs: ["general_documents"],
                                },
                            },
                            {
                                path: ":generalDocumentId/configure",
                                element: (
                                    <ContextGuard>
                                        <ManagerNotificationGuard>
                                            <SubscriptionGuard>
                                                <OnboardedGuard>
                                                    <RoleBasedGuard hasContent roles={[RoleName.GOLD]}>
                                                        <OwnerGuard>
                                                            <GeneralDocumentConfiguration />
                                                        </OwnerGuard>
                                                    </RoleBasedGuard>
                                                </OnboardedGuard>
                                            </SubscriptionGuard>
                                        </ManagerNotificationGuard>
                                    </ContextGuard>
                                ),
                                handle: {
                                    crumbs: ["general_documents", ":generalDocumentId"],
                                },
                            },
                            {
                                path: "new",
                                element: (
                                    <ContextGuard>
                                        <ManagerNotificationGuard>
                                            <SubscriptionGuard>
                                                <OnboardedGuard>
                                                    <RoleBasedGuard hasContent roles={[RoleName.GOLD]}>
                                                        <OwnerGuard>
                                                            <AddOrEditGeneralDocumentPage />
                                                        </OwnerGuard>
                                                    </RoleBasedGuard>
                                                </OnboardedGuard>
                                            </SubscriptionGuard>
                                        </ManagerNotificationGuard>
                                    </ContextGuard>
                                ),
                                handle: {
                                    crumbs: ["general_documents", "new_general_document"],
                                },
                            },
                            {
                                path: ":generalDocumentId",
                                element: (
                                    <ContextGuard>
                                        <ManagerNotificationGuard>
                                            <SubscriptionGuard>
                                                <OnboardedGuard>
                                                    <RoleBasedGuard
                                                        hasContent
                                                        roles={[RoleName.BRONZE, RoleName.SILVER, RoleName.GOLD, RoleName.FREE]}
                                                    >
                                                        <OwnerGuard>
                                                            <AddOrEditGeneralDocumentPage />
                                                        </OwnerGuard>
                                                    </RoleBasedGuard>
                                                </OnboardedGuard>
                                            </SubscriptionGuard>
                                        </ManagerNotificationGuard>
                                    </ContextGuard>
                                ),
                                handle: {
                                    crumbs: ["general_documents", ":generalDocumentId"],
                                },
                            },
                        ],
                    },
                ],
            },

            {
                path: "/marketing",
                children: [
                    {
                        index: true,
                        element: (
                            <ContextGuard>
                                <ManagerNotificationGuard>
                                    <SubscriptionGuard>
                                        <OnboardedGuard>
                                            <FeaturesGuard featureId={UserFlags.PropertyAd}>
                                                <NoPropertyGuard>
                                                    <OwnerGuard>
                                                        <Marketing />
                                                    </OwnerGuard>
                                                </NoPropertyGuard>
                                            </FeaturesGuard>
                                        </OnboardedGuard>
                                    </SubscriptionGuard>
                                </ManagerNotificationGuard>
                            </ContextGuard>
                        ),
                    },
                    {
                        path: "marketing/new",
                        element: (
                            <ContextGuard>
                                <ManagerNotificationGuard>
                                    <SubscriptionGuard>
                                        <OnboardedGuard>
                                            <FeaturesGuard featureId={UserFlags.PropertyAd}>
                                                <OwnerGuard>
                                                    <NewAdWizard />
                                                </OwnerGuard>
                                            </FeaturesGuard>
                                        </OnboardedGuard>
                                    </SubscriptionGuard>
                                </ManagerNotificationGuard>
                            </ContextGuard>
                        ),
                    },
                ],
            },
            {
                path: "/calendar",
                children: [
                    {
                        index: true,
                        element: (
                            <ContextGuard>
                                <ManagerNotificationGuard>
                                    <SubscriptionGuard>
                                        <OnboardedGuard>
                                            <NoPropertyGuard>
                                                <OwnerGuard>
                                                    <Calendar />
                                                </OwnerGuard>
                                            </NoPropertyGuard>
                                        </OnboardedGuard>
                                    </SubscriptionGuard>
                                </ManagerNotificationGuard>
                            </ContextGuard>
                        ),
                        handle: {
                            crumbs: ["calendar"],
                        },
                    },
                ],
            },
            {
                path: "/nomenclature",
                children: [
                    { index: true, element: <Navigate to={"labels"} /> },
                    {
                        path: "labels",
                        children: [
                            {
                                index: true,
                                element: (
                                    <ContextGuard>
                                        <ManagerNotificationGuard>
                                            <SubscriptionGuard>
                                                <OnboardedGuard>
                                                    <NoPropertyGuard>
                                                        <OwnerGuard>
                                                            <Labels />
                                                        </OwnerGuard>
                                                    </NoPropertyGuard>
                                                </OnboardedGuard>
                                            </SubscriptionGuard>
                                        </ManagerNotificationGuard>
                                    </ContextGuard>
                                ),
                            },
                        ],
                    },
                    {
                        path: "providers",
                        children: [
                            {
                                index: true,
                                element: (
                                    <ContextGuard>
                                        <ManagerNotificationGuard>
                                            <SubscriptionGuard>
                                                <OnboardedGuard>
                                                    <NoPropertyGuard>
                                                        <OwnerGuard>
                                                            <Providers />
                                                        </OwnerGuard>
                                                    </NoPropertyGuard>
                                                </OnboardedGuard>
                                            </SubscriptionGuard>
                                        </ManagerNotificationGuard>
                                    </ContextGuard>
                                ),
                            },
                        ],
                    },
                    {
                        path: "bank-accounts",
                        children: [
                            {
                                index: true,
                                element: (
                                    <ContextGuard>
                                        <ManagerNotificationGuard>
                                            <SubscriptionGuard>
                                                <OnboardedGuard>
                                                    <NoPropertyGuard>
                                                        <RoleBasedGuard hasContent roles={[RoleName.GOLD, RoleName.SILVER, RoleName.BRONZE]}>
                                                            <OwnerGuard>
                                                                <BankAccounts />
                                                            </OwnerGuard>
                                                        </RoleBasedGuard>
                                                    </NoPropertyGuard>
                                                </OnboardedGuard>
                                            </SubscriptionGuard>
                                        </ManagerNotificationGuard>
                                    </ContextGuard>
                                ),
                            },
                        ],
                    },
                    {
                        path: "sections",
                        children: [
                            {
                                index: true,
                                element: (
                                    <ContextGuard>
                                        <ManagerNotificationGuard>
                                            <SubscriptionGuard>
                                                <OnboardedGuard>
                                                    <NoPropertyGuard>
                                                        <FeaturesGuard featureId={UserFlags.TaskItems}>
                                                            <OwnerGuard>
                                                                <SectionsPage />
                                                            </OwnerGuard>
                                                        </FeaturesGuard>
                                                    </NoPropertyGuard>
                                                </OnboardedGuard>
                                            </SubscriptionGuard>
                                        </ManagerNotificationGuard>
                                    </ContextGuard>
                                ),
                            },
                        ],
                    },
                    {
                        path: "invoicing-configuration",
                        children: [
                            {
                                index: true,
                                element: (
                                    <ContextGuard>
                                        <ManagerNotificationGuard>
                                            <SubscriptionGuard>
                                                <OnboardedGuard>
                                                    <NoPropertyGuard>
                                                        <FeaturesGuard featureId={UserFlags.InvoicingConfigurations}>
                                                            <OwnerGuard>
                                                                <InvoicingConfigurationPage />
                                                            </OwnerGuard>
                                                        </FeaturesGuard>
                                                    </NoPropertyGuard>
                                                </OnboardedGuard>
                                            </SubscriptionGuard>
                                        </ManagerNotificationGuard>
                                    </ContextGuard>
                                ),
                            },
                        ],
                    },
                    {
                        path: "C168",
                        children: [
                            {
                                index: true,
                                element: (
                                    <ContextGuard>
                                        <ManagerNotificationGuard>
                                            <SubscriptionGuard>
                                                <OnboardedGuard>
                                                    <NoPropertyGuard>
                                                        <WhiteLabelAdminGuard>
                                                            <OwnerGuard>
                                                                <C168AddressConfiguration />
                                                            </OwnerGuard>
                                                        </WhiteLabelAdminGuard>
                                                    </NoPropertyGuard>
                                                </OnboardedGuard>
                                            </SubscriptionGuard>
                                        </ManagerNotificationGuard>
                                    </ContextGuard>
                                ),
                            },
                        ],
                    },
                ],
            },
            {
                element: (
                    <ContextGuard>
                        <ManagerNotificationGuard>
                            <SubscriptionGuard>
                                <OnboardedGuard>
                                    <SettingsPage />
                                </OnboardedGuard>
                            </SubscriptionGuard>
                        </ManagerNotificationGuard>
                    </ContextGuard>
                ),
                path: "/settings",
            },
        ],
    },
    {
        path: "/login",
        element: <RegisterSignIn />,
    },
    {
        path: "/register",
        element: <RegisterSignIn />,
    },
    {
        path: "/download",
        element: (
            <AuthGuard>
                <DownloadRentingPeriodDataPage />,
            </AuthGuard>
        ),
    },

    {
        path: "*",
        element: <Outlet />,
        children: [
            {
                path: "404",
                element: (
                    <AcceptanceGuard>
                        <Page404 />
                    </AcceptanceGuard>
                ),
            },
            { path: "*", element: <Navigate to='/404' replace /> },
        ],
    },
    { path: "*", element: <Navigate to='/404' replace /> },
])

const addMaximumScaleToMetaViewport = () => {
    const el = document.querySelector("meta[name=viewport]")

    if (el !== null) {
        let content = el.getAttribute("content") ?? ""
        const re = /maximum-scale=[0-9.]+/g

        if (re.test(content)) {
            content = content.replace(re, "maximum-scale=1.0")
        } else {
            content = [content, "maximum-scale=1.0"].join(", ")
        }

        el.setAttribute("content", content)
    }
}

function checkIsIOS() {
    return (
        ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            navigator?.userAgentData?.platform || navigator?.platform || "unknown",
        ) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    )
}

if (checkIsIOS()) {
    addMaximumScaleToMetaViewport()
}

export default router
