import { IconButton } from "@mui/material"
import { GridRenderCellParams } from "@mui/x-data-grid-pro"
import React from "react"
import { useTheme } from "@mui/material/styles"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import { DESKTOP_ICON_SIZE } from "../../utils/helpers"
import { LightTooltip, useLocales } from "rentzz"

function CustomDetailPanelToggle(props: Pick<GridRenderCellParams, "id" | "value"> & { title?: string }) {
    const theme = useTheme()
    const { value: isExpanded, title } = props
    const { translate } = useLocales()

    return (
        <LightTooltip title={translate(title)}>
            <IconButton size='small' tabIndex={-1} aria-label={isExpanded ? "Close" : "Open"}>
                {isExpanded ? (
                    <VisibilityOffIcon sx={{ color: theme.palette.primary.main, fontSize: DESKTOP_ICON_SIZE }} />
                ) : (
                    <RemoveRedEyeIcon sx={{ color: theme.palette.primary.main, fontSize: DESKTOP_ICON_SIZE }} />
                )}
            </IconButton>
        </LightTooltip>
    )
}

export default CustomDetailPanelToggle
