import { Label, useIsMobile, useLocales } from "rentzz"
import { CircularProgress, Tab, Tabs } from "@mui/material"
import { a11yProps } from "../../../pages/PropertyDetails"
import React, { useCallback, useEffect, useState } from "react"
import { useRentingPeriodsReportQueryFn } from "../../../queries/tenants"
import { useTheme } from "@mui/material/styles"
import { rentingPeriodFilterState, RentingPeriodsFilteredOptions } from "../../../utils/atom"
import { useAtom } from "jotai"
import { DateTime } from "luxon"
import { GridLogicOperator } from "@mui/x-data-grid"

export default function HeaderRentingPeriods() {
    const theme = useTheme()
    const { translate } = useLocales()
    const { data: rentingPeriodsReport } = useRentingPeriodsReportQueryFn()
    const [page, setPage] = useState<RentingPeriodsFilteredOptions | undefined>(undefined)
    const [filterModel, setFilterModel] = useAtom(rentingPeriodFilterState)
    const isMobile = useIsMobile()

    const handleChange = useCallback(
        (event: React.SyntheticEvent, newValue: RentingPeriodsFilteredOptions) => {
            switch (newValue) {
                case RentingPeriodsFilteredOptions.All:
                    setFilterModel({ items: [] })
                    break
                case RentingPeriodsFilteredOptions.Accepted:
                    setFilterModel({ items: [{ field: "rentingPeriodStatus", operator: "eq", value: "'Accepted'" }] })
                    break
                case RentingPeriodsFilteredOptions.Finished:
                    setFilterModel({
                        items: [{ field: "rentingPeriodStatus", operator: "eq", value: "'Finished'" }],
                    })
                    break
                case RentingPeriodsFilteredOptions.Pending:
                    setFilterModel({
                        items: [
                            { field: "rentingPeriodStatus", operator: "eq", value: "'Created'" },
                            { field: "rentingPeriodStatus", operator: "eq", value: "'NotificationSent'" },
                            { field: "rentingPeriodStatus", operator: "eq", value: "'ExtendNotificationSent'" },
                        ],
                        logicOperator: GridLogicOperator.Or,
                    })
                    break
                case RentingPeriodsFilteredOptions.Declined:
                    setFilterModel({ items: [{ field: "rentingPeriodStatus", operator: "eq", value: "'Declined'" }] })
                    break

                case RentingPeriodsFilteredOptions.EndingSoon:
                    setFilterModel({
                        items: [
                            {
                                field: "moveOutDate",
                                operator: "<",
                                value: DateTime.now().plus({ month: 1 }).toISO({ includeOffset: false }) + "Z",
                                id: 0,
                            },
                            { field: "rentingPeriodStatus", operator: "eq", value: "'Accepted'" },
                        ],
                        logicOperator: GridLogicOperator.And,
                    })
                    break
            }
        },
        [setFilterModel],
    )
    useEffect(() => {
        if (filterModel.items?.length === 0) {
            setPage(RentingPeriodsFilteredOptions.All)
            return
        }

        const itemType = filterModel.items.find((i) => i.field === "rentingPeriodStatus")
        const itemDate = filterModel.items.find((i) => i.field === "moveOutDate" && i.id === 0)
        if (itemType?.value === "'Accepted'" && itemDate?.value < DateTime.now().plus({ month: 1 }).toISO({ includeOffset: false }) + "Z")
            return setPage(RentingPeriodsFilteredOptions.EndingSoon)
        if (itemType?.value === "'Accepted'") setPage(RentingPeriodsFilteredOptions.Accepted)
        if (itemType?.value === "'Created'" || itemType?.value === "'NotificationSent'" || itemType?.value === "'ExtendNotificationSent'")
            setPage(RentingPeriodsFilteredOptions.Pending)
        if (itemType?.value === "'Finished'") setPage(RentingPeriodsFilteredOptions.Finished)
        if (itemType?.value === "'Declined'") setPage(RentingPeriodsFilteredOptions.Declined)
    }, [filterModel])

    if (rentingPeriodsReport == null) return <CircularProgress size='1rem' />

    return (
        <Tabs
            value={page ?? RentingPeriodsFilteredOptions.Accepted}
            onChange={handleChange}
            sx={{
                px: isMobile ? 0 : 2,
            }}
            allowScrollButtonsMobile
            scrollButtons='auto'
            variant='scrollable'
        >
            <Tab
                value={RentingPeriodsFilteredOptions.Accepted}
                label={translate("rentingPeriodStatus.4")}
                icon={
                    <Label color={"primary"} sx={{ mr: 1 }}>
                        {rentingPeriodsReport[RentingPeriodsFilteredOptions.Accepted].count}
                    </Label>
                }
                style={{
                    textTransform: "none",
                }}
                {...a11yProps(0)}
            />
            <Tab
                value={RentingPeriodsFilteredOptions.Pending}
                label={translate("pending")}
                icon={
                    <Label color={"info"} sx={{ mr: 1 }}>
                        {rentingPeriodsReport[RentingPeriodsFilteredOptions.Pending].count}
                    </Label>
                }
                style={{
                    textTransform: "none",
                }}
                {...a11yProps(1)}
            />
            <Tab
                value={RentingPeriodsFilteredOptions.Finished}
                label={translate("rentingPeriodStatus.5")}
                icon={
                    <Label color={"default"} sx={{ mr: 1 }}>
                        {rentingPeriodsReport[RentingPeriodsFilteredOptions.Finished].count}
                    </Label>
                }
                style={{
                    textTransform: "none",
                }}
                {...a11yProps(2)}
            />
            <Tab
                value={RentingPeriodsFilteredOptions.Declined}
                label={translate("rentingPeriodStatus.3")}
                icon={
                    <Label color={"error"} sx={{ mr: 1 }}>
                        {rentingPeriodsReport[RentingPeriodsFilteredOptions.Declined].count}
                    </Label>
                }
                style={{
                    textTransform: "none",
                }}
                {...a11yProps(3)}
            />
            <Tab
                icon={
                    <Label sx={{ mr: 1, color: theme.palette.common.white, backgroundColor: theme.palette.common.black }}>
                        {rentingPeriodsReport[RentingPeriodsFilteredOptions.All].count}
                    </Label>
                }
                value={RentingPeriodsFilteredOptions.All}
                label={translate("all")}
                style={{
                    textTransform: "none",
                }}
                {...a11yProps(4)}
            />
            <Tab
                icon={
                    <Label sx={{ mr: 1, color: theme.palette.common.white, backgroundColor: theme.palette.warning.main }}>
                        {rentingPeriodsReport[RentingPeriodsFilteredOptions.EndingSoon].count}
                    </Label>
                }
                value={RentingPeriodsFilteredOptions.EndingSoon}
                label={translate("endingSoonRentingPeriod")}
                style={{
                    textTransform: "none",
                }}
                {...a11yProps(4)}
            />
        </Tabs>
    )
}
