import { Label, LoadingIcon, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useDispatch } from "../../../redux/store"
import React from "react"
import { useTenantRevisionsQuery } from "../../../queries/revision"
import { Box, Typography } from "@mui/material"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import CustomMobileCard from "../../../components/CustomMobileCard"
import { RevisionStatus } from "../../../redux/slices/AddProperty"
import { DateTime } from "luxon"
import NoDataPage from "../../../components/NoDataPage"

export default function MobileTenantRevisionsList() {
    const { translate } = useLocales()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { data: revisions } = useTenantRevisionsQuery()

    if (revisions == null) return <LoadingIcon />

    if (revisions?.length === 0) return <NoDataPage icon={"mdi:wrench-clock"} noDataText={"no_revisions"} />

    return (
        <>
            <Box gap={1} display={"flex"} flexDirection={"column"} style={{ height: "inherit", paddingBottom: 55 }}>
                {revisions && revisions.length > 0 && (
                    <Typography variant={"subtitle1"} textAlign={"center"} fontWeight={"bold"} sx={{ color: theme.palette.grey.A700 }}>
                        {translate("revisions")}
                    </Typography>
                )}
                {revisions.map((revision) => (
                    <CustomMobileCard
                        key={revision.id}
                        item={revision}
                        onClick={() => {
                            dispatch(setModalOpen(AppModals.RevisionDetails))
                            dispatch(setEditingItem({ id: revision.id }))
                        }}
                        icon={"mdi:wrench"}
                        iconColor={theme.palette.primary.main}
                        title={revision.name}
                        rightFirstItem={
                            <Label color={revision.status === RevisionStatus.Done ? "primary" : "warning"} style={{ textTransform: "none" }}>
                                {translate(revision.status === RevisionStatus.Done ? "completed" : "pending")}
                            </Label>
                        }
                        rightSecondItem={revision.tenantChosenDate?.toLocaleString(DateTime.DATE_SHORT)}
                    />
                ))}
            </Box>
        </>
    )
}
