import { FormProvider, RHFDatePicker, RHFDateRangePicker, RHFDayPicker, RHFTextField, RHFUnitInput, useIsMobile, useLocales } from "rentzz"
import { Box, Button, Typography } from "@mui/material"
import { setModalOpen } from "../../../../../../redux/slices/App"
import { LoadingButton } from "@mui/lab"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "../../../../../../redux/store"
import { useCurrencyQueryFn } from "../../../../../../queries/currency"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { RentingPeriodDetailsSchema } from "../../../../../../validations/rentingPeriod"
import { useRentingPeriodDetails } from "../../../../../../queries/tenants"
import { useFeatureValue } from "@growthbook/growthbook-react"
import { UserFlags } from "../../../../../../queries/userData"

export interface RentingPeriodRequest {
    value: number
    currencyId: number
    moveInDate: string
    moveOutDate: string
    paymentPeriod: number[]
    bankAccount?: string
    bankName?: string
    removeTenantAccessAt?: string | null
}
interface Props {
    onSubmit: (data: RentingPeriodRequest) => Promise<void>
    isLoading: boolean
}
export default function EditRentingPeriodDetailsForm({ onSubmit, isLoading }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const { data: currencies } = useCurrencyQueryFn()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const autoRemoveAccessDays = useFeatureValue<number>(UserFlags.AutoRemoveTenantAccessDays.toString(), 0)

    const methods = useForm<RentingPeriodRequest>({
        resolver: yupResolver(RentingPeriodDetailsSchema),
        mode: "onChange",
    })

    const { handleSubmit, reset } = methods

    useEffect(() => {
        if (!rentingPeriodDetails) return
        reset({
            ...rentingPeriodDetails,
            value: rentingPeriodDetails?.value,
            currencyId: rentingPeriodDetails?.currencyId,
            moveInDate: rentingPeriodDetails.moveInDate.toISO() ?? undefined,
            moveOutDate: rentingPeriodDetails.moveOutDate.toISO() ?? undefined,
            paymentPeriod: rentingPeriodDetails?.paymentPeriod,
            bankAccount: rentingPeriodDetails?.bankAccount,
            bankName: rentingPeriodDetails?.bankName,
            removeTenantAccessAt: rentingPeriodDetails?.removeTenantAccessAt?.toISO() ?? undefined,
        })
    }, [rentingPeriodDetails, autoRemoveAccessDays])

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display={"flex"} gap={1} paddingTop={2} flexDirection={"column"}>
                <RHFDateRangePicker fromName={"moveInDate"} toName={"moveOutDate"} isStartDisabled />
                <RHFDatePicker name={"removeTenantAccessAt"} label={translate("tenant_remove_access_at")} />
                <RHFUnitInput
                    unitName={"currencyId"}
                    unitType={"currency"}
                    name='value'
                    type='number'
                    size='small'
                    data={currencies ?? []}
                    label={translate("tenant-data.price")}
                    required
                />

                <Box display={"flex"} flexDirection={"column"} gap={2} paddingTop={1}>
                    <RHFTextField name='bankAccount' placeholder={translate("bank_account")} label={translate("bank_account")} size='small' />
                    <RHFTextField name='bankName' placeholder={translate("bank_name")} label={translate("bank_name")} size='small' />
                </Box>
                <Typography alignSelf={"center"} marginTop={1} fontSize={14} fontWeight={"bold"}>
                    {translate("pick_a_payment_period")} *
                </Typography>

                <Box display={"flex"} justifyContent={"center"}>
                    <RHFDayPicker name={"paymentPeriod"} defaultValue={rentingPeriodDetails ? rentingPeriodDetails.paymentPeriod : []} />
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingX: 0,
                        paddingTop: 2,
                        gap: 2,
                        flexDirection: isMobile ? "column-reverse" : "row",
                    }}
                >
                    <Button color={"primary"} onClick={() => dispatch(setModalOpen(undefined))} disabled={isLoading}>
                        {translate("cancel")}
                    </Button>
                    <LoadingButton color={"primary"} type='submit' variant='contained' loading={isLoading} disabled={isLoading}>
                        {translate("submit")}
                    </LoadingButton>
                </Box>
            </Box>
        </FormProvider>
    )
}
