import { Iconify, Label, LoadingIcon, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import React, { useEffect, useMemo, useRef } from "react"
import { PermissionType, usePermissions } from "../../../hooks/usePermissions"
import { Box, CircularProgress, Fab } from "@mui/material"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import CustomAddCard from "../../../components/CustomAddCard"
import { useInfiniteRevisionsQueryFn } from "../../../queries/revision"
import CustomMobileCard from "../../../components/CustomMobileCard"
import { DateTime } from "luxon"
import { RevisionStatus } from "../../../redux/slices/AddProperty"
import { RoleName, useUserDataQueryFn } from "../../../queries/userData"

export default function MobileRevisionsList() {
    const { translate } = useLocales()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { data: user } = useUserDataQueryFn()
    const ref = useRef<HTMLDivElement>(null)
    const { data: revisions, fetchNextPage, hasNextPage } = useInfiniteRevisionsQueryFn([{ field: "date", sort: "desc" }])
    const { modalOpen } = useSelector((state) => state.appState)
    const { canWrite } = usePermissions(PermissionType.Revisions)

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting && hasNextPage) {
                    fetchNextPage()
                }
            }),
        [hasNextPage],
    )

    useEffect(() => {
        if (ref?.current) {
            observer.observe(ref.current)
        }
        return () => {
            observer.disconnect()
        }
    }, [ref, observer])

    if (revisions == null) return <LoadingIcon />

    return (
        <>
            <Box gap={1} display={"flex"} flexDirection={"column"} style={{ height: "inherit", paddingBottom: 55 }}>
                {revisions.pages.some((p) => p.items.length > 0) && modalOpen == null && canWrite && user?.roles.roleName !== RoleName.FREE && (
                    <Fab
                        onClick={() => dispatch(setModalOpen(AppModals.AddRevision))}
                        sx={{ position: "absolute", bottom: 10, right: 10 }}
                        color='primary'
                        aria-label='add'
                    >
                        <Iconify icon={"mdi:plus"} width={25} height={25} />
                    </Fab>
                )}
                {revisions.pages.map((group, i) => (
                    <React.Fragment key={i}>
                        {group.items.map((revision) => (
                            <CustomMobileCard
                                key={revision.id}
                                item={revision}
                                onClick={() => {
                                    dispatch(setModalOpen(AppModals.RevisionDetails))
                                    dispatch(setEditingItem({ id: revision.id }))
                                }}
                                icon={"mdi:wrench"}
                                iconColor={theme.palette.primary.main}
                                title={revision.name}
                                rightFirstItem={
                                    <Label color={revision.status === RevisionStatus.Done ? "primary" : "warning"} style={{ textTransform: "none" }}>
                                        {translate(revision.status === RevisionStatus.Done ? "completed" : "pending")}
                                    </Label>
                                }
                                rightSecondItem={revision.date.toLocaleString(DateTime.DATE_SHORT)}
                            />
                        ))}
                    </React.Fragment>
                ))}
                {hasNextPage && (
                    <div ref={ref} style={{ justifyContent: "center", width: "100%", display: "flex", paddingTop: 10 }}>
                        <CircularProgress />
                    </div>
                )}
                {revisions.pages.some((p) => p.items.length === 0) && canWrite && (
                    <CustomAddCard
                        onCardClick={() => dispatch(setModalOpen(AppModals.AddRevision))}
                        icon={"mdi:wrench-clock"}
                        text={"add_revision"}
                        isAddDisabled={user?.roles.roleName === RoleName.FREE}
                        tooltipTitle={"permission_denied_action"}
                    />
                )}
            </Box>
        </>
    )
}
