import { emptyStringToNull } from "../dialogs/add-edit-property/utils"
import { number, object, string } from "yup"

export const PropertyDetailsSchema = (isBucharest: boolean) =>
    object().shape({
        county: string().required("county_required"),
        city: string().required(isBucharest ? "sector_required" : "city_required"),
        street: string().optional(),
        streetNumber: string().optional(),
        buildingNumber: string().optional(),
        stair: string().optional(),
        floor: number()
            .optional()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .min(0, "at_least_ground_level")
            .max(20, "at_most_20_level"),
        apartment: string().optional(),
        rooms: number()
            .optional()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .min(1, "rooms_at_least_1")
            .max(10, "rooms_at_most_10")
            .transform(emptyStringToNull),
        year: number()
            .optional()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .min(1930, "built_1930")
            .max(new Date().getFullYear(), "built_this_year")
            .transform(emptyStringToNull),
        size: number()
            .optional()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .min(10, "at_least_10mp")
            .max(300, "at_most_300mp")
            .integer("size_integer_required")
            .transform(emptyStringToNull),
    })

export const NewInvitationSchema = () =>
    object().shape({
        email: string().email("must_be_email").required("mail_required"),
        propertyId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required(),
        managerType: number().required(),
    })
